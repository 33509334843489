
.bloc-slider{
  margin: $content-spacing 0; padding: 0 0;
  width: 100%; max-width: 100% !important; font-size: 16px;

  border-left: 0px none !important;
  border-right: 0px none !important;

  @media screen and ( max-width: $screen-xs ) {
    margin: $mobile-spacing 0;
  }

  @extend %clearfix;

  &:before {
    position: absolute; z-index: 1; top: 0; left: 0; display: block; content: "";
    background-color: $brun; width: 50%; height: 190px;

    @media screen and ( max-width: $screen-xs ) {
      width: 80px;
    }

  }

  > .inner-container {
    position: relative; z-index: 2;
    width: 100%; max-width: $max-width;
    margin: 0 auto; padding: 0 $content-spacing;
    background-color: #fff;
    text-align: right;

    @media screen and ( max-width: $screen-xs ) {
      background-color: transparent;
    }

    @extend %clearfix;
    box-sizing: border-box;

    &:before {
      position: absolute; z-index: 1; top: 0; left: 0; display: block; content: "";
      background-color: $brun; width: calc(100% - 460px); height: 190px;

      @media screen and ( max-width: $screen-xs ) {
        display: none;
      }
    }

    > * {
      position: relative; z-index: 2;
    }

    .slider{
      transform: translate3d(0,0,0);
      position: relative; z-index: 2;

      .item {
        position: relative;
        display: block; text-align: left;
        text-decoration: none; min-height: 520px;
        overflow: hidden;
        transform: translate3d(0,0,0);
        -webkit-transform:translate3d(0,0,0);
        -webkit-backface-visibility: hidden;

        @media screen and ( max-width: $screen-sm ) {
          min-height: 425px;
        }

        figure {
          position: absolute; z-index: 1; top: 0; left: 0; bottom: 0; right: 0;
          display: block; margin: 0px 0px;


          &:before{
            content: ''; display: block;
            position: absolute; z-index: 2; bottom: 0; left: 0; top: 0; right: 0; opacity: 1;
            background: url('../img/backend/shadow-left.png') no-repeat left top;
            background-size: 100% 100% !important;

            @media screen and ( max-width: $screen-xs ) {
              background: rgba(#000000,0.4) !important; height: 100% !important;
              background-size: 100% 100% !important;
            }
          }

          figcaption {
            position: absolute; z-index: 3; left: 45%; bottom: 0; right: 60px;
            display: block; background: none; width: auto;
            -webkit-transform:translate3d(0,0,0);
            -webkit-backface-visibility: hidden;

            @media screen and ( max-width: 750px ) {
              display: none;
            }
          }
        }

        .container {
          position: relative; z-index: 3; text-align: left;
          margin: 0 0; width: 100%;
          padding: $content-spacing $content-spacing $content-spacing*2 $content-spacing;
          box-sizing: border-box; max-width: 50%;
          -webkit-transform:translate3d(0,0,0);
          -webkit-backface-visibility: hidden;

          @media screen and ( max-width: $screen-md ) {
            max-width: 75%;
          }

          @media screen and ( max-width: $screen-xs ) {
            max-width: 100%; padding: $content-spacing $mobile-spacing ( $content-spacing + $mobile-spacing ) $mobile-spacing ;
          }

          .subtitle {
            color: #fff;
            font-family: ntt, sans-serif;
            font-weight: bold;
            @include font(17px,18px);
            margin-bottom: 0px !important;
          }

          p { color: #fff; }

          h1, h2, h3, h4, h5, h6,
          .h1, .h2, .h3, .h4, .h5, .h6 {
            color: #fff; margin: 15px 0 5px 0;
          }

          .btn {
            margin-bottom: 0px !important;
            @media screen and ( max-width: $screen-xs ) {
              margin-top: 10px !important;
              margin-bottom: 0px !important;
            }
          }
        }


        &.left-text.top-text {
          .container { text-align: left; }
        }

        &.center-text.top-text {
          .container { text-align: center; margin: 0px auto; }
          figure {
            &:before{
              background: #000000 ;
              opacity: 0.4;
            }
          }
        }

        &.right-text.top-text {
          .container { text-align: right; float: right; }
          figure { &:before{ background: url('../img/backend/shadow-right.png') no-repeat left top; } }
        }

        &.left-text.middle-text {
          @extend %flex-valign-left-middle;
          .container { text-align: left; }
        }

        &.center-text.middle-text {
          @extend %flex-valign;
          .container { text-align: center; margin: 0px auto;  }
          figure {
            &:before{
              background: #000000 ;
              opacity: 0.4;
            }
          }
        }

        &.right-text.middle-text {
          @extend %flex-valign-right;
          .container { text-align: right; float: right; }
          figure { &:before{ background: url('../img/backend/shadow-right.png') no-repeat left top; } }
        }

        &.left-text.bottom-text {
          @extend %flex-valign-bottom-left;
          .container { text-align: left; }
        }

        &.center-text.bottom-text {
          @extend %flex-bottom-valign;
          .container { text-align: center; margin: 0px auto;  }
          figure {
            &:before{
              background: #000000 ;
              opacity: 0.4;
            }
          }
        }

        &.right-text.bottom-text {
          @extend %flex-valign-bottom-right;
          .container { text-align: right; float: right; }
          figure { &:before{ background: url('../img/backend/shadow-right.png') no-repeat left top; } }
        }
      }

    }
  }

  &:nth-child(even){

    &:before { right: 0; left: auto; }

    > .inner-container {
      &:before { left: auto; right: 0; }
    }
  }

  .custom-controler {
    position: absolute; z-index: 1; top: 0; left: 0; right: 0;
    box-sizing: border-box;

    .owl-data { display: none; }
    .owl-dots { display: none; }
  }

  .custom-controler-dots {
    position: relative; z-index: 9;
    box-sizing: border-box;

    .owl-prev, .owl-next { display: none; }

    .owl-nav {
      position: absolute; z-index: 1;
      top: 0px; right: 0px;

      @media screen and ( max-width: 750px ) {
        position: relative; z-index: 1; margin-top: -40px;
      }
    }

    .owl-data {
      font-family: ntt, sans-serif; margin-top: -10px;
      font-size: 15px; box-sizing: border-box;
      color: #fff; width: 60px; display: inline-block;
      padding: 15px 10px; text-align: center;
    }

    .owl-dots {
      position: relative; z-index: 2;
      margin-top: -40px; display: block;
      width: 45%; height: 80px; overflow: hidden;
      @extend %clearfix;

      @media screen and ( max-width: 750px ) {
        display: none !important;
      }

      .dots-inner {
        height: 80px;
        @extend %clearfix;

        transition-property: margin;
        transition-duration: .2s;
      }

      .owl-dot{
        cursor: pointer;
        background-size: cover;
        background-position: center center;
        overflow: hidden; float: left;
        width: 33%; height: 80px;

        span {
          display: block; width: 100%; height: 100%;

          transition-property: background;
          transition-duration: .2s;

          background-color: rgba(0,0,0,0.5);
        }

        &:hover, &.active{
          > span {
            background-color: rgba(0,0,0,0.1);
          }
        }
      }
    }

  }

}

html.isie .bloc-slider{

  > .inner-container .slider .item {

    &.left-text.middle-text {
      display: table !important; table-layout: fixed !important;
      text-align: left !important;
      height: 100%; width: 100%;
      > .container {
        float: none; clear: both;
        display: table-cell !important;
        height: 100%; vertical-align: middle; text-align: left;
        > * {
          max-width: 50%; text-align: left;
          @media screen and ( max-width: $screen-md ) { max-width: 75%; }
          @media screen and ( max-width: $screen-xs ) { max-width: 100%; }
        }
      }
      //@extend %flex-valign-left-middle;
    }

    &.center-text.middle-text {
      display: table !important; table-layout: fixed !important;
      text-align: center !important;
      height: 100%; width: 100%;
      > .container {
        float: none; clear: both;
        display: table-cell !important;
        height: 100%; vertical-align: middle; text-align: center;
        > * {
          max-width: 50%; text-align: center; margin-left: auto; margin-right: auto;
          @media screen and ( max-width: $screen-md ) { max-width: 75%; }
          @media screen and ( max-width: $screen-xs ) { max-width: 100%; }
        }
      }
      //@extend %flex-valign;
    }

    &.right-text.middle-text {
      display: table !important; table-layout: fixed !important;
      text-align: right !important;
      height: 100%; width: 100%;
      > .container {
        float: none; clear: both;
        display: table-cell !important;
        height: 100%; vertical-align: middle; text-align: right;
        padding-left: 50%;
        @media screen and ( max-width: $screen-md ) { padding-left: 25%; }
        @media screen and ( max-width: $screen-xs ) { padding-left: 0px; }
        > * { max-width: 100%; text-align: right; }
      }
      //@extend %flex-valign-right;
    }

    &.left-text.bottom-text {
      display: table !important; table-layout: fixed !important;
      text-align: left !important;
      height: 100%; width: 100%;
      > .container {
        float: none; clear: both;
        display: table-cell !important;
        height: 100%; vertical-align: bottom; text-align: left;
        > * {
          max-width: 50%; text-align: left;
          @media screen and ( max-width: $screen-md ) { max-width: 75%; }
          @media screen and ( max-width: $screen-xs ) { max-width: 100%; }
        }
      }
      //@extend %flex-valign-bottom-left;
    }

    &.center-text.bottom-text {
      display: table !important; table-layout: fixed !important;
      text-align: center !important;
      height: 100%; width: 100%;
      > .container {
        float: none; clear: both;
        display: table-cell !important;
        height: 100%; vertical-align: bottom; text-align: center;
        > * {
          max-width: 50%; text-align: center; margin-left: auto; margin-right: auto;
          @media screen and ( max-width: $screen-md ) { max-width: 75%; }
          @media screen and ( max-width: $screen-xs ) { max-width: 100%; }
        }
      }
      //@extend %flex-bottom-valign;
    }

    &.right-text.bottom-text {
      display: table !important; table-layout: fixed !important;
      text-align: right !important;
      height: 100%; width: 100%;
      > .container {
        float: none; clear: both;
        display: table-cell !important;
        height: 100%; vertical-align: bottom; text-align: right;
        padding-left: 50%;
        @media screen and ( max-width: $screen-md ) { padding-left: 25%; }
        @media screen and ( max-width: $screen-xs ) { padding-left: 0px; }
        > * { max-width: 100%; text-align: right; }
      }
      //@extend %flex-valign-bottom-right;
    }

  }

}