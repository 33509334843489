#main {
  position: relative; z-index: 4;
  box-sizing: border-box; overflow: hidden;
  @extend %clearfix;
  min-height: 500px;
  margin: 0 0; padding: 0 0;


  // -- Carte Google map -- //

  .google-map-little {
    position: relative;
    display: none;
    margin: 0px;
    background-color: #7dcdcd;

    .google_map_btn {
      position: absolute; display: inline-block;
      left: 50% !important; bottom: 15px !important; right: auto !important; top: auto !important;
      background-color: rgba( #000, 0.5 );
      font-family: nttd, sans-serif;
      @include font(13px,14px);
      color: #fff; text-decoration: none !important;
      border: 0px none !important;
      border-radius: 20px;
      padding: 10px 15px 7px 15px; margin: 0 0;
      transform: translateX(-50%);

      transition-property: background;
      transition-duration: .2s;

      &:hover {
        background-color: rgba( #000, 0.7 );
      }
    }

  }

}