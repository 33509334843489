
/* Clearfix
----------------------------- */
.clearfix:after { visibility: hidden; display: table; font-size: 0; content: " "; clear: both; height: 0; }
%clearfix:after { visibility: hidden; display: table; font-size: 0; content: " "; clear: both; height: 0; }


/* Flexbox Vertical align
----------------------------- */

%flex-full-height {

  /* IE 10 */
  display: -ms-flexbox; -ms-flex-align: stretch;

  /* Safari */
  display: -webkit-flex; -webkit-align-items: stretch;

  /* Default */
  display: flex; align-items: stretch;

}


%flex-full-row {

  /* IE 10 */
  display: -ms-flexbox; -ms-flex-direction: row; -ms-flex-align: stretch;

  /* Safari */
  display: -webkit-flex; -webkit-flex-direction: row; -webkit-align-items: stretch;

  /* Default */
  display: flex; flex-direction: row; align-items: stretch;

}

%flex-full-row-inverse {

  /* IE 10 */
  display: -ms-flexbox; -ms-flex-direction: row-reverse; -ms-flex-align: stretch;

  /* Safari */
  display: -webkit-flex; -webkit-flex-direction: row-reverse; -webkit-align-items: stretch;

  /* Default */
  display: flex; flex-direction: row-reverse; align-items: stretch;

}

%flex-valign {

  /* IE 10 */
  display: -ms-flexbox; -ms-justify-content: center; -ms-flex-align: center;

  /* Safari */
  display: -webkit-flex; -webkit-justify-content: center; -webkit-align-items: center;

  /* Default */
  display: flex; justify-content: center; align-items: center;


}

%flex-bottom-valign {

  /* Default */
  display: flex; justify-content: center; align-items: flex-end;

  /* Safari */
  display: -webkit-flex; -webkit-justify-content: center; -webkit-align-items: flex-end;

  /* IE 10 */
  display: -ms-flexbox; -ms-justify-content: center; -ms-flex-align: flex-end;

}

%flex-valign-left {

  /* Default */
  display: flex; justify-content: center; align-items: bottom;

  /* Safari */
  display: -webkit-flex; -webkit-justify-content: center; -webkit-align-items: bottom;

  /* IE 10 */
  display: -ms-flexbox; -ms-justify-content: center; -ms-flex-align: bottom;

}

%flex-valign-left-middle {

  /* Default */
  display: flex; justify-content: left; align-items: center;

  /* Safari */
  display: -webkit-flex; -webkit-justify-content: left; -webkit-align-items: center;

  /* IE 10 */
  display: -ms-flexbox; -ms-justify-content: left; -ms-flex-align: center;

}

%flex-valign-bottom-left {

  /* Default */
  display: flex !important; justify-content: left; align-items: flex-end;

  /* Safari */
  display: -webkit-flex !important; -webkit-justify-content: left; -webkit-align-items: flex-end;

  /* IE 10 */
  display: -ms-flexbox !important; -ms-justify-content: left; -ms-flex-align: flex-end;

}

%flex-valign-right {

  /* Default */
  display: flex !important; justify-content: left; align-items: center; flex-direction: row-reverse;

  /* Safari */
  display: -webkit-flex !important; -webkit-justify-content: left; -webkit-align-items: center; -webkit-flex-direction: row-reverse;

  /* IE 10 */
  display: -ms-flexbox !important; -ms-justify-content: left; -ms-flex-align: center; -ms-flex-direction: row-reverse;

}

%flex-valign-bottom-right {

  /* Default */
  display: flex !important; justify-content: left; align-items: flex-end; flex-direction: row-reverse;

  /* Safari */
  display: -webkit-flex !important; -webkit-justify-content: left; -webkit-align-items: flex-end; -webkit-flex-direction: row-reverse;

  /* IE 10 */
  display: -ms-flexbox !important; -ms-justify-content: left; -ms-flex-align: flex-end; -ms-flex-direction: row-reverse;

}

%flex-valign-left-col {

  /* Default */
  display: flex; flex-direction: column; justify-content: center; align-items: left;

  /* Safari */
  display: -webkit-flex; -webkit-flex-direction: column; -webkit-justify-content: center; -webkit-align-items: left;

  /* IE 10 */
  display: -ms-flexbox; -ms-flex-direction: column; -ms-justify-content: center; -ms-flex-align: left;

}
