#header {

  ul.menu.menu-primary {
    position: relative; z-index: 2;
    margin: 0 0; padding: 23px 0; text-align: left;
    text-align: left; width: calc(100% - 200px);
    float: left;

    transition-property: margin;
    transition-duration: 0.2s;

    border-bottom: 1px solid rgba(255,255,255,0.3);

    [class^="icon-"], [class*=" icon-"] {
      display: block;
      position: relative;
      margin: 0px 0px; padding: 7px 15px 7px 15px;
      color: $brun; font-weight: normal;
      &:hover { color: #fff; }
      &:before{
        position: absolute; font-size: 18px;
        top: 45%; left: 50%; font-family: 'icomoon' !important;
        transform: translateY(-50%) translateX(-50%);
      }
    }

    @extend %clearfix;
    @extend %flex-full-height;

    .sub-menu {
      min-width: 200px;
    }

    > li {
      position: relative; z-index: 1;
      font-family: ntt, sans-serif !important; font-weight: bold;
      @include font(14px,16px);
      color: #ffffff; text-transform: uppercase;
      padding: 0px; margin: 0px 0px;@extend %flex-valign;

      &.home {
        width: 50px;
        outline: none !important;
        a {
          outline: none !important;
          height: 100%; min-height: 34px; padding: 0px 15px 0px 15px;
          background-color: transparent !important;
          &:before {
            color: $brun;
            transition-property: color;
            transition-duration: 0.2s;
          }
        }
        span { display: none; }
        &:hover {
          a {
            &:before { color: #fff; }
          }
        }
      }

      > a, > span {
        text-align: center; width: 100%; height: 100%;
        padding: 10px 13px 9px 13px;
        letter-spacing: -0.5px;

        font-family: ntt, sans-serif !important; font-weight: bold;
        @include font(16px,18px);

        @extend %flex-valign;

        color: #fff;
        box-sizing: border-box;

        transition-property: color, background;
        transition-duration: 0.2s;

        &:hover {
          background-color: $brun;
        }
      }

      &.current-menu-item, &.current-menu-ancestor {
        > a, > span {
          background-color: $brun;
          color: #fff;
        }
      }

      &.has-sub-menu, &.menu-item-has-children {
        &:hover {
          z-index: 2;
          > a, > span {
            background-color: #fff;
            color: #000 !important;
          }
          > ul { opacity: 1; visibility: visible; }
        }
      }

      ul {
        position: absolute; z-index: 0;
        top: 100%; left: -20px; right: -20px;
        background-color: #ffffff;  padding: 0;
        overflow: hidden;
        box-sizing: border-box;

        transition-property: opacity !important;
        transition-duration: 0.2s;

        opacity: 0;
        visibility: hidden;

        @extend %clearfix;

        -webkit-box-shadow: 0px 20px 25px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 12px 25px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 12px 20px 0px rgba(0,0,0,0.3);

        li {
          position: relative; z-index: 2;
          padding: 0px 0px; display: block;
          text-transform: none; color: $gris;
          font-family: ntt, sans-serif; font-weight: normal;
          border-bottom: 1px solid #ececec;

          > a, > span {
            font-weight: normal; color: $gris;
            padding: 10px 33px 9px 33px; display: block;
            font-family: ntt, sans-serif;
            @include font(19px,22px);

            transition-property: color, background;
            transition-duration: 0.2s;

            &:hover {
              background-color: #ececec;
            }

            @media screen and ( max-width: $screen-md ) {
              padding: 10px 13px;
            }

          }

          ul { display: none; }

          &:last-child { border: 0px none; }
          &:after { display: none; }
        }

      }

    }

  }

}

#header {

  &.is-sticky, &.force-sticky {

    ul.menu.menu-primary {
      border-bottom: 1px solid $brun;
    }

    ul.menu.menu-primary > li > a, ul.menu.menu-primary > li > span {
      color: #000;
      &:hover { color: #fff; }
      &.has-sub-menu, &.menu-item-has-children {
        &:hover {
          color: #fff;
          background-color: $brun;
        }
      }
    }

    ul.menu.menu-primary > li.home a,  ul.menu.menu-primary > li.home span {
      color: $brun;
      &:hover {
        color: #000 !important;
        &:before { color: #000 !important; }
      }
    }

    ul.menu.menu-primary > li  {
      &.has-sub-menu, &.menu-item-has-children {
        &:hover {
          > a, > span {
            color: #000 !important;
            background-color: #ffffff;
          }
        }
      }
      &.current-menu-item, &.current-menu-ancestor {
        > a, > span {
          background-color: $brun;
          color: #fff;
        }
      }
    }

  }

}

#overlay-mobile-nav {

  ul.menu.menu-primary {
    position: relative;
    z-index: 2; display: block;
    margin: 0 20px; box-sizing: border-box;
    padding: 0px 0;
    text-align: center;

    @extend %clearfix;

    > li {
      position: relative;  z-index: 1;
      font-family: ntt, sans-serif !important;
      font-weight: bold; @include font(16px, 18px);
      color: #ffffff;  text-transform: uppercase;
      padding: 0px; margin: 0px 0px;
      display: block;

      .icon-home {
        span {
          font-family: ntt, sans-serif !important;
          font-weight: bold; @include font(16px, 16px);
          color: #ffffff;  text-transform: uppercase;
          margin-left: 10px; padding: 2px 0 0 0; line-height: 16px;
          display: inline-block; vertical-align: top;
        }

        &:before{ color: $brun; }

        &:hover span {
          color: $brun;
        }

      }

      > a, > span {
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        padding: 15px 30px;
        color: #ffffff;

        &:hover {
          color: $brun;
        }

      }

      &.menu-item-has-children {
        > a, > span {
          &:after {
            position: absolute; top: 15px; right: 0px;

            transition-property: all;
            transition-duration: 0.2s;

            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            display: block;
            vertical-align: top;
            content: "\e902";
            color: $brun;

            font-size: 14px; line-height: 14px;
          }
        }
      }

      .sub-menu {
        display: none; opacity: 0;
        position: relative; font-size: 15px;
        z-index: 2; margin: 0 auto; padding: 10px 0;
        text-align: center; width: 100%; max-width: 400px;
        border-bottom: 1px solid rgba(255,255,255,0.3);
        background-color: rgba(255,255,255,0.1);

        transition-property: all;
        transition-duration: 0.2s;

        > li {
          position: relative;  z-index: 1;
          font-family: ntt, sans-serif !important;
          font-weight: normal; @include font(16px, 18px);
          color: #ffffff;  text-transform: none;
          padding: 0px; margin: 0px 0px;
          display: block;

          > a, > span {
            position: relative;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            padding: 10px 30px;
            color: #cacaca;

            &:hover {
              color: $brun;
            }

          }

        }

      }

      &.opened-menu {
        .sub-menu {
          display: block;
          opacity: 1;
        }

        &.menu-item-has-children {
          > a, > span {

            &:after {
              -moz-transform: rotate(-180deg); /* FF3.5/3.6 */
              -o-transform: rotate(-180deg); /* Opera 10.5 */
              -webkit-transform: rotate(-180deg); /* Saf3.1+ */
              transform: rotate(-180deg); /* Newer browsers (incl IE9) */
            }

          }
        }

      }

    }
  }

}