.bloc-slider-homepage {
  position: relative;
  margin: 0 0; padding: 0 0;
  width: 100%; max-width: 100%; font-size: 16px;
  background-color: #000;

  .owl-stage-outer, .owl-stage, .owl-item, .item, figure, figure > img {
    min-height: 600px !important;

    @media screen and ( max-width: $screen-xs ) {
      min-height: auto !important;
    }
  }

  .item {
    position: relative;
    display: block;
    text-decoration: none;

    @extend %clearfix;
    overflow: hidden;
    -webkit-transform:translate3d(0,0,0);
    -webkit-backface-visibility: hidden;

    > figure {
      position: relative; z-index: 1;
      margin: 0px; padding: 0px;

      figcaption { display: none; }

      @media screen and ( max-width: $screen-xs ) {
        position: absolute; z-index: 1;
        top: 0; left: 0; right: 0; bottom: 0;

        img { height: 100% !important; }
      }
    }

    &:before{
      content: ''; display: block;
      position: absolute; z-index: 2; bottom: 0; left: 0;
      width: 100%; height: 50%; opacity: 1;
      background: url('../img/backend/shadow.png') repeat-x left bottom -5px;
      background-size: 100% 100% !important;

      -webkit-transform:translate3d(0,0,0);
      -webkit-backface-visibility: hidden;

      @media screen and ( max-width: $screen-xs ) {
        background: rgba(#000000,0.3); height: 100%;
        background-size: 100% 100% !important;
      }


    }

    figcaption { display: none; }

    .container {
      position: absolute; z-index: 3; text-align: left;
      bottom: 115px; left: 0; right: 130px;
      box-sizing: border-box; max-width: 800px;
      padding: $content-spacing 0 $content-spacing $content-spacing;

      -webkit-transform:translate3d(0,0,0);
      -webkit-backface-visibility: hidden;

      @media screen and ( max-width: $screen-xs ) {
        position: relative; z-index: 3; bottom: auto; left: auto; right: auto;
        padding: ($content-spacing*5) $mobile-spacing $mobile-spacing + 50px $mobile-spacing;
      }

      .subtitle {
        color: #fff;
        font-family: ntt, sans-serif;
        font-weight: bold;
        @include font(17px,18px);
        margin-bottom: 0px !important;
      }

       p { color: #fff; }

       h1, h2, h3, h4, h5, h6,
       .h1, .h2, .h3, .h4, .h5, .h6 {
         color: #fff;
       }

       .btn {
         margin-bottom: 0px !important;
         @media screen and ( max-width: $screen-xs ) {
           margin-top: 10px !important;
           margin-bottom: 0px !important;
         }
       }
    }

  }

  .owl-carousel {
    position: relative; z-index: 2;
  }

  .custom-controler {
    position: absolute; z-index: 4;
    bottom: 0; left: 0;
    width: 100%; height: 110px;

    @media screen and ( max-width: $screen-xs ) {
      position: relative !important;
      height: auto !important;
      bottom: auto !important;
    }

    .owl-nav {
      position: absolute; bottom: 110px + $content-spacing; right: 20px;
      width: 110px;

      .owl-data {
        font-family: ntt, sans-serif;
        font-size: 15px; box-sizing: border-box;
        color: #fff; width: 40px; display: inline-block;
        padding: 10px 0px; text-align: center;
      }

      @media screen and ( max-width: $screen-xs ) {
        position: absolute !important; bottom: 20px !important; right: auto !important;
        left: 20px !important;  width: 110px;
      }

      .owl-prev {
        position: relative;
        display: block;
        width: 35px !important;
        height: auto !important;
        box-sizing: border-box;
        background-color: transparent !important;
        margin: 0; padding: 15px 10px;

        &:before {
          position: relative !important;
          top: auto !important; left: auto !important;
          margin-top: 0px !important;
        }
      }

      .owl-next {
        position: relative;
        display: block;
        width: 35px !important;
        height: auto !important;
        box-sizing: border-box;
        background-color: transparent !important;
        margin: 0; padding: 15px 10px;

        &:before {
          position: relative !important;
          top: auto !important; left: auto !important;
          margin-top: 0px !important;
        }
      }

    }

    .owl-dots {
      width: 100%; height: 100px;
      @extend %clearfix;
      @extend %flex-full-row;

      @media screen and ( max-width: $screen-xs ) {
        display: none !important;
        height: auto !important;
      }

      .owl-dot{
        cursor: pointer;
        flex-grow: 1;
        background-size: cover;
        background-position: center center;
        overflow: hidden; height: 100px; width: 100%;

        > span {
          position: relative;
          margin: 0px; padding: 10px $content-spacing 27px $content-spacing;
          width: 101%; height: 100px;
          box-sizing: border-box;
          display: table; table-layout: fixed;

          @media screen and ( max-width: $screen-lg ) {
            padding: 10px $mobile-spacing 20px $mobile-spacing;
          }

          @media screen and ( max-width: $screen-xs ) {
            padding: 30px $mobile-spacing 28px $mobile-spacing;
          }

          transition-property: background;
          transition-duration: .2s;

          background-color: rgba(0,0,0,0.5);

          > span {
            position: relative;
            margin: 0px; padding: 0px;
            display: table-cell;
            vertical-align: bottom;
            width: 101%; height: 100%;

            font-family: ntt, sans-serif;
            font-weight: bold;
            text-transform: uppercase;

            @include font( 17px, 17px  );
            color: #fff;

            @media screen and ( max-width: $screen-md ) { @include font( 16px, 16px ); }
            @media screen and ( max-width: $screen-xs ) { @include font( 15px, 15px ); }

          }

        }

        &:hover, &.active{
          > span {
            background-color: rgba(0,0,0,0.1);
          }
        }
      }
    }

    .time-bar {
      display: block; position: relative;
      width: 100%; height: 10px;
      background-color: #000;
      transform: translate3d(0,0,0);

     > span {
        content: ''; display: block;
        width: 0px; height: 10px;
        background-color: $brun;

       transition-property: width;
       transition-duration: .5s;
       transition-timing-function: linear;

       &.transition {
         transition-duration: 5s !important;
       }

      }
    }

  }

}

html.isie .bloc-slider-homepage {

  .custom-controler .owl-dots {
    display: table !important; table-layout: fixed !important;
    text-align: left !important; height: 100px; width: 100%;

    @media screen and ( max-width: $screen-xs ) {
      display: none !important;
      height: auto !important;
    }

    .owl-dot {
      float: none; clear: both;
      display: table-cell !important; width: 50%;
      height: 100%; vertical-align: bottom; text-align: left;
    }

    //@extend %flex-full-row;

  }
}