.bloc-iframe {
  * {
    box-sizing: border-box;
  }

  iframe {
    width: 100%;
    padding: 0 40px;

    @media screen and ( max-width: $screen-xs ) {
      padding: 0 $mobile-spacing;
    }
  }
}