.bloc-publiciter {
  text-align: center;
  margin: $content-spacing 0 !important; padding: 0 0;
  border-left: $content-spacing solid transparent !important;
  border-right: $content-spacing solid transparent !important;

  @media screen and ( max-width: $screen-xs ) {
    margin: $mobile-spacing 0 !important;
    border-left: $mobile-spacing solid transparent !important;
    border-right: $mobile-spacing solid transparent !important;
  }

  .inner-container {
    width: 100%; max-width: ($max-width - ($content-spacing*2)) !important; font-size: 16px;
    background-color: #585858;  margin: 0 auto; padding: 0;
    box-sizing: border-box;

    figure {
      margin: 0 auto; padding: 0;

      img{

      }

      &.publicite_desktop {
        display: block;
        @media screen and ( max-width: $screen-sm ) { display: none; }
      }

      &.publicite_mobile {
        display: none;
        @media screen and ( max-width: $screen-sm ) { display: block; }
      }

    }
  }

}