
ul.menu {
  position: relative;
  margin: 10px auto; padding: 0; text-align: center;
  list-style: none; font-size: 16px;
  box-sizing: border-box;

  @extend %clearfix;

  li {
    position: relative;
    display: inline-block; vertical-align: top;
    margin: 0; padding: 0px;
    box-sizing: border-box;

    font-family: ntt, sans-serif;
    font-weight: bold;
    @include font(14px,16px);
    color: #ffffff; text-transform: uppercase;

    &:before{
      display: none;
    }

    strong, b {
      font-weight: bold;
      em, i { font-style: italic; }
    }

    em, i { font-style: italic; }

    a, span {
      display: inline-block; vertical-align: middle; padding: 5px 5px;
      color: #fff; text-decoration: none;
      border: 0px none;

      transition-property: color;
      transition-duration: 0.3s;

    }

    ul {
      margin: 0; padding: 0;
    }


  }

}