/* Header
----------------------------- */

.single-header {
	position: relative;
	z-index: 1;
	margin: 0px 0px $content-spacing 0px;
	background-color: #efefef;
	text-align: center;
	padding: 1px 0 0 0;
	min-height: 400px;

	@media screen and (max-width: $screen-xs) {
		margin: 0px 0px $mobile-spacing 0px;
	}

	@extend %clearfix;
	@extend %flex-valign;
	align-items: flex-end;

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		color: #fff;
		max-width: 700px;
		text-align: center;
		margin: 15px auto 10px auto;
		@media screen and (max-width: $screen-xs) {
			margin: 10px auto 10px auto;
		}
	}

	.subtitle {
		font-family: ntt, sans-serif !important;
		font-weight: bold;
		@include font(17px, 17px);
		color: #fff;
		@media screen and (max-width: $screen-xs) {
			@include font(16px, 16px);
		}
	}

	time {
		color: #fff;
		&:after {
			background-color: $brun;
		}
	}

	p {
		color: #fff;
		max-width: 600px;
	}

	> figure {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: block;
		margin: 0px 0px;
		img {
			position: relative;
			z-index: 1;
		}
		figcaption {
			display: none;
		}
	}

	.cover-video{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		overflow: hidden;
		@media screen and (max-width: 600px){
			display: none;
		}
		video{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		+figure{
			display: none;
			@media screen and (max-width: 600px){
				display: block;
			}
		}

		&:after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: black;
			opacity: .25;
		}
	}

	figure{

	}

	.container {
		position: relative;
		z-index: 5;
		text-align: left;
		margin: 0 auto 0 0;
		width: 50%;
		max-width: $max-width;
		padding: 40px;
		box-sizing: border-box;
		@media screen and (max-width: 800px){
			width: 100%;
		}

		h1{
			text-align: left;
			max-width: none;
		}
		.network-list{
			text-align: left;
		}
		.breadcrumb{
			text-align: left !important;
		}

		.menu-share {
			border: 0px none;
			text-align: center;
			margin: 35px auto 0px auto;
		}

		.breadcrumb {
			padding: 0px 0px;
			text-align: center;
			margin: 0px auto 10px auto;
		}

		.icon-scroll {
			position: relative;
			color: #fff;
			display: inline-block;
			font-size: 25px;
			line-height: 25px;
			height: 80px;
			width: 21px;
			margin: 20px auto 0 auto;

			.icon-ball {
				position: absolute;
				left: 8px;
				bottom: 20px;
				content: '';
				width: 6px;
				height: 6px;
				border-radius: 10px;
				opacity: 1;
				background: #fff;

				transition-property: all;
				animation: ballAnim 1s linear infinite;
			}

			&:before {
				display: block;
				position: absolute;
				bottom: 0;
				left: 0px;
				width: 21px;
				box-sizing: border-box;
				content: '';
				height: 32px;
				border: 2px solid #fff;
				border-radius: 35px;
			}

			&:after {
				display: block;
				position: absolute;
				top: 0;
				left: 10px;
				content: '';
				width: 1px;
				height: 35px;
				background: #fff;
			}

			@media screen and (max-height: $screen-xs) {
				height: 75px;
				margin: 25px auto 0 auto;

				&:after {
					height: 40px;
				}
			}
		}

		@media screen and (max-width: $screen-xs) {
			padding: $mobile-spacing $mobile-spacing;
		}

	}

	.zoombox-list {
		position: absolute;
		z-index: 9;
		top: auto;
		left: auto;
		bottom: -40px;
		height: 80px;
		right: $content-spacing*3;
		width: 60%;
		overflow: hidden;

		@media screen and (max-width: $screen-xs) {
			right: $mobile-spacing;
			width: calc(100% - 40px);
			height: 60px;
		}

		> a {
			position: relative;
			width: 33%;
			height: 80px;
			display: none;
			float: left;
			vertical-align: top;

			@media screen and (max-width: $screen-xs) {
				height: 60px;
			}

			&:after {
				position: absolute;
				z-index: 9;
				top: 50%;
				left: 50%;
				height: 40px;
				width: 40px;
				font-size: 20px;
				line-height: 20px;
				display: none;
				margin: -10px 0 0 -10px;
				box-sizing: border-box;
				border-radius: 20px;

				/* use !important to prevent issues with browser extensions that change fonts */
				font-family: 'icomoon' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;

				/* Better Font Rendering =========== */
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				transition-property: color, border;
				transition-duration: 0.3s;

				content: "\ea0a";
				color: #fff;
			}

			figure {
				margin: 0 0;
				img {
					opacity: 1;
				}

				&:before {
					opacity: 0 !important;
				}
			}

			&:nth-child(-n+3) {
				display: inline-block;
			}

			&:nth-child(1) {
				img {
					opacity: 1;
				}
			}

			&.moreImg {

				&:after {
					display: block;
				}

				&:hover {
					&:after {
						color: $brun;
					}
				}

			}

		}

	}

	&.fixScroll {
		height: 100vh;
		min-height: 550px;

		@media screen and (max-width: $screen-xs) {
			min-height: 450px;
			height: 600px !important;
		}

		.container {
			padding-top: 200px;

			@media screen and (max-height: $screen-xs) {
				padding-top: 150px;
			}

		}
	}

	&.toBottom {
		text-align: left !important;
		margin-bottom: $content-spacing*2 !important;

		@extend %flex-valign-bottom-left;

		@media screen and (max-width: $screen-xs) {
			margin-bottom: $content-spacing + $mobile-spacing !important;
			> .container {
				padding: 20px 20px ($content-spacing + $mobile-spacing) 20px;
			}
		}

		@media screen and (max-width: $screen-xs) {
			min-height: auto;
		}

		h1, .breadcrumb, .subtitle {
			text-align: left !important;
			margin-left: 0px !important;
		}

		.menu-share {
			text-align: left;
			margin-left: 0px;
			li {
				text-align: center !important;
			}
		}

		.social-share{
			ul{
				text-align: left;
				margin-top: 20px;
			}
		}

		figure {
			&:before {
				content: '';
				display: block;
				position: absolute;
				z-index: 2;
				bottom: 0;
				left: 0;
				top: 0;
				right: 0;
				opacity: 0.8;
				background: url('../img/backend/shadow-left.png') no-repeat left top;
				background-size: 100% 100% !important;

				@media screen and (max-width: $screen-xs) {
					opacity: 0.7;
					background-size: 100% 100% !important;
				}
			}
		}

	}

	&.salleType {
		.container {
			padding: $content-spacing ($content-spacing*3) ($content-spacing + 20px) $content-spacing;
		}
		@media screen and (max-width: $screen-sm) {
			.container {
				padding: $content-spacing $content-spacing ($content-spacing + 20px) $content-spacing;
			}
		}
		@media screen and (max-width: $screen-xs) {
			.container {
				padding: $content-spacing*4 $content-spacing ($content-spacing + 20px) $content-spacing;
			}
		}

		figure:before {
			opacity: 0.5 !important;
		}
	}

	&.fixLeft {
		text-align: left !important;
		background-color: #ffffff;
		margin: 0px auto $content-spacing auto;
		width: 100%;
		max-width: $max-width;
		display: block !important;

		> figure {
			float: left;
			position: absolute;
			z-index: 1;
			width: 515px;
		}

		.container {
			text-align: left;
			float: right;
			margin: 0 40px 0 0;
			width: calc(100% - 515px - 80px);
			max-width: 100%;
			padding: ($content-spacing + 70px) 0 $content-spacing 0;
			color: #000;
			box-sizing: border-box;
			border-bottom: 1px solid #dddddd;

			h1 {
				color: #000;
				text-align: left;
				margin: 10px auto 10px auto;
				@include font(42px, 18px);

				@media screen and (max-width: $screen-md) {
					@include font(32px, 18px);
				}
				@media screen and (max-width: $screen-xs) {
					@include font(22px, 18px);
				}
			}

			h1, h2, h3, h4, h5, h6,
			.h1, .h2, .h3, .h4, .h5, .h6 {
				color: #000;
				text-align: left;
				margin-left: 0px !important;
				margin-right: 0px !important;
			}

			.author {
				margin: 20px 0 10px 0;
				display: block;
				color: $brun-f;
				font-family: nttd, sans-serif;
				@include font(22px, 16px);
			}

			.category {
				position: absolute;
				top: 120px;
				left: -100px;
				width: 60px !important;
				font-family: ntt, sans-serif;
				padding: 19px 20px;
				color: $brun;
				@include font(16px, 16px);
				box-sizing: border-box;
				text-align: center;
				text-transform: uppercase;
				font-weight: bold;
				background-color: #fff;

				em {
					display: block;
					text-align: center;
					font-style: normal;
					padding: 1px 0;
				}
			}

			.form_date {
				display: block;
				text-transform: uppercase;

				.time {
					float: left;
					max-width: calc(100% - 200px);
				}

				.current_event {
					float: right;
					width: 150px;

					position: relative;
					display: inline-block;
					font-family: ntt, sans-serif;
					font-size: 13px !important;
					line-height: 11px !important;
					font-weight: bold;
					box-sizing: border-box;
					text-align: center;
					min-height: 36px;

					text-decoration: none;
					user-select: none;
					border: 1px solid $brun;

					margin: 10px 0px;
					padding: 13px 20px 10px 20px;

					vertical-align: middle;
					word-break: break-word;
					white-space: normal;
					text-transform: uppercase;

					-webkit-appearance: none;
					-moz-appearance: none;
					touch-action: manipulation;

					transition-property: all;
					transition-duration: 0.3s;

					color: $brun;
					background-color: #fff;

				}

			}

			p, ul, li {
				color: #000;
				max-width: 100%;
			}

			ul, ol {
				margin: 5px 0 5px 0;
			}

			ul li {
				&:before {
					color: #000;
				}
			}

			.breadcrumb {
				margin: 10px auto 40px auto;
				color: #000;
				text-align: left;

				.icon-littleArrow {
					&:before {
						color: #000;
					}
				}

				a, .breadcrumb_last {
					color: #000;
					&:hover {
						border-bottom: 1px solid $brun;
					}
				}
			}

			.menu.menu-share {
				margin: 22px 0 0px 0;
				text-align: left;

				li:nth-child(2) {
					border-radius: 0px !important;
					background-color: transparent !important;
				}

				.icon-facebook {
					background-color: #000000;
					color: #fff !important;
				}

				.icon-addCalendar {
					background-color: transparent !important;
					color: #000000 !important;
					font-size: 24px !important;
					span {
						display: none;
					}

					&:hover {
						color: $brun-f !important;
					}

				}

				&.share-single-event{
					li{
						&:hover, &:focus{
							background: none;
						}
						a{
							color: #000000;
							padding: 0;
							&:hover, &:focus{
								color: $brun-f;
								span{
									color: $brun-f;
								}
							}
							span{
								color: #000000;
								width: 100%;
								height: 100%;
								display: block;
								font-size: 30px;
								padding: 0;
								@include addTransition();
							}
						}
					}
				}
			}

			.social-share{
				ul{
					text-align: left;
					margin-top: 20px;
				}
			}

		}

		@media screen and (max-width: $screen-lg) {

			> figure {
				width: 40%;
			}

			.container {
				width: calc(100% - 40% - 80px);
			}

		}

		@media screen and (max-width: $screen-sm) {
			> figure {
				width: 100%;
				float: none;
				clear: both;
				position: relative;
				z-index: 1;
				height: 350px;
			}

			.container {
				width: 100% !important;
				float: none;
				clear: both;
				padding: $content-spacing $content-spacing;

				.category {
					position: absolute;
					top: -40px;
					left: 40px;
					width: auto !important;
					height: 40px !important;
					@include font(14px, 14px);
					padding: 17px 15px 12px 15px;

					em {
						display: inline-block;
						text-align: center;
						vertical-align: top;
						font-style: normal;
						padding: 0px 1px;
					}
				}

			}
		}

		@media screen and (max-width: $screen-xs) {
			.container {
				padding: $mobile-spacing $mobile-spacing;

				.category {
					position: absolute;
					left: 20px;
				}

				.form_date {
					display: block;

					.time {
						display: inline-block;
						vertical-align: top;
						float: left;
						clear: both;
						max-width: 100%;
					}

					.current_event {
						display: inline-block;
						vertical-align: top;
						float: left;
						clear: both;
					}

				}
			}
		}

	}

}