.bloc-image {
  width: 100% !important; max-width: 100% !important;

  border-left: 0px none !important;
  border-right: 0px none !important;

  max-height: 800px;
  min-height: 525px;

  @media screen and ( max-width: $screen-xs ) {
    &:before{ display: none !important; }
    min-height: auto !important;
  }

  figure {
    position: absolute ; z-index: 1;  display: block; margin: 0px 0px;

    &.object-fit {
      position: absolute; top: 0; left: 0; right: 0; bottom: 0;
    }

    img { z-index: 2; }

    &:before{
      content: ''; display: block;
      position: absolute; z-index: 2; bottom: 0; left: 0; top: 0; right: 0; opacity: 1;
      background: url('../img/backend/shadow-left.png') no-repeat left top;
      background-size: 100% 100% !important;

      @media screen and ( max-width: $screen-xs ) {
        background: rgba(#000000,0.4) !important; height: 100% !important;
        background-size: 100% 100% !important;
      }
    }

    figcaption {
      position: absolute; top: 0; right: 0; left: auto; bottom: auto; z-index: 3;
      background: none; height: auto; width: auto;
      padding: 10px 20px 10px 40px;

      transform: rotate(-90deg) translateY(-100%);
      transform-origin: 100% 0%;

      @media screen and ( max-width: $screen-xs ) {

        display: none;

      }

    }
  }

  .container {
    position: relative; z-index: 3; text-align: left;
    margin: 0 0; width: 100%;
    padding: $content-spacing $content-spacing*2 $content-spacing $content-spacing;
    box-sizing: border-box; max-width: 60%;

    @media screen and ( max-width: $screen-md ) {
      max-width: 75%;
    }

    @media screen and ( max-width: $screen-xs ) {
      max-width: 100%; padding: 140px $mobile-spacing $mobile-spacing $mobile-spacing;
    }

    .subtitle {
      color: #fff;
      font-family: ntt, sans-serif;
      font-weight: bold;
      @include font(17px,18px);
      margin-bottom: 0px !important;
    }

    p { color: #fff; }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      color: #fff; margin: 15px 0 5px 0;
    }

    .btn {
      margin-bottom: 0px !important;
      @media screen and ( max-width: $screen-xs ) {
        margin-top: 10px !important;
        margin-bottom: 0px !important;
      }
    }
  }


  > .inner {

    @media screen and ( max-width: $screen-xs ) {
     position: relative;
    }

    &.left-text.top-text {
      .container { text-align: left; }
    }

    &.center-text.top-text {
      .container { text-align: center; margin: 0px auto; }
      figure {
        &:before{
          background: #000000 ;
          opacity: 0.4;
        }
      }
    }

    &.right-text.top-text {
      .container { text-align: right; float: right; }
      figure { &:before{ background: url('../img/backend/shadow-right.png') no-repeat left top; } }
    }

    &.left-text.middle-text {
      @extend %flex-valign-left;
      .container { text-align: left; }
    }

    &.center-text.middle-text {
      @extend %flex-valign;
      .container { text-align: center; margin: 0px auto;  }
      figure {
        &:before{
          background: #000000 ;
          opacity: 0.4;
        }
      }
    }

    &.right-text.middle-text {
      @extend %flex-valign-right;
      .container { text-align: right; float: right; }
      figure { &:before{ background: url('../img/backend/shadow-right.png') no-repeat left top; } }
    }

    &.left-text.bottom-text {
      @extend %flex-valign-bottom-left;
      .container { text-align: left; }
    }

    &.center-text.bottom-text {
      @extend %flex-bottom-valign;
      .container { text-align: center; margin: 0px auto;  }
      figure {
        &:before{
          background: #000000 ;
          opacity: 0.4;
        }
      }
    }

    &.right-text.bottom-text {
      @extend %flex-valign-bottom-right;
      .container { text-align: right; float: right; }
      figure { &:before{ background: url('../img/backend/shadow-right.png') no-repeat left top; } }
    }

  }


}