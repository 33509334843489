
.bloc-slider-listing-post {
  margin: 0 0; padding: 0 0 40px 0;
  width: 100%; max-width: 100%; font-size: 16px;

  @extend %clearfix;

  &:before {
    position: absolute; z-index: 1; top: 0; left: 0; display: block; content: "";
    background-color: $brun; width: 50%; height: 190px;

    @media screen and ( max-width: $screen-xs ) {
      width: 80px;
    }

  }

  &.owl-not-loader {
    .inner-container {
      @media screen and ( max-width: $screen-xs ) {
        padding: 0 $mobile-spacing;
      }
    }
  }

  figure {  margin: 0 0; }

  h2, h3,
  .h2, .h3 {
    font-family: ntt, sans-serif;
    @include font(42px, 16px);
    color: $brun !important; text-align: left;
    text-transform: uppercase;
    background-color: #fff; width: 460px;
    display: inline-block; box-sizing: border-box;
    padding: 0px 0 30px 50px;  margin: 0px;

    @media screen and ( max-width: $screen-xs ) {
      width: calc(100% - 80px); padding: 0px 0 30px 00px;
    }

    @media screen and ( max-width: $screen-md ) { @include font(37px, 16px); }
    @media screen and ( max-width: $screen-xs ) { @include font(27px, 16px); }
  }

  .inner-container {
    position: relative; z-index: 2;
    width: 100%; max-width: $max-width;
    margin: 0 auto; padding: 0 $content-spacing;
    background-color: #fff;
    text-align: right;

    @media screen and ( max-width: $screen-xs ) {
      background-color: transparent;
    }

    @extend %clearfix;
    box-sizing: border-box;

    &:before {
      position: absolute; z-index: 1; top: 0; left: 0; display: block; content: "";
      background-color: $brun; width: calc(100% - 460px); height: 190px;

      @media screen and ( max-width: $screen-xs ) {
        display: none;
      }
    }

    > * {
      position: relative; z-index: 2;
    }

    .slider-listing-post {
      transform: translate3d(0,0,0);
    }
  }

   .custom-controler {
     position: absolute; z-index: 1; top: 0; left: 0; right: 0;
     box-sizing: border-box;

     .owl-dots { display: none; }
   }


    &.rooms {
      width: 100%; max-width: 100% !important;
      padding: 0 0 40px 0;

      &:before { display: none; }

      .before {
        position: absolute; z-index: 1; top: 0; left: 0; display: block; content: "";
        background-color: $brun; width: 50%; height: 190px;

        @media screen and ( max-width: $screen-xs ) {
          width: 80px;
        }

      }

      .slider-listing-room {
        margin-bottom: $content-spacing;;
        @media screen and ( max-width: $screen-xs ) {
          margin-bottom: $mobile-spacing;
        }
      }

      .owl-item .item {
        padding: $content-spacing $content-spacing $mobile-spacing $content-spacing;
        box-sizing: border-box;
        @media screen and ( max-width: $screen-xs ) {
          padding: $mobile-spacing $mobile-spacing $mobile-spacing $mobile-spacing;
        }
      }

      .custom-controler {
        position: relative;
        display: block; margin: 0 0 !important;
        background-color: #fff;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;

        position: absolute; z-index: 9;
        top: auto; bottom: 0; left: 0; right: 0;
        box-sizing: border-box; width: 50%;

        @media screen and ( max-width: $screen-sm ) {
          width: 100%;
        }

        .owl-dots {
          background-color: #fff;
        }

        .owl-nav {
          font-family: ntt, sans-serif;
          @include font( 13px, 16px ); font-weight: normal;
          color: $gris;

          @extend %clearfix;

          .owl-prev {
            position: relative; z-index: 2;
            float: left;
            margin-left: 40px;
            display: block; width: 25%;
            height: 40px; max-height: 40px;
            background-color: #fff;
            text-align: left;
            line-height: 40px;
            padding: 0 0 0 15px;

            box-sizing: border-box;

            > span {
              display: inline-block;
              vertical-align: middle;
              line-height: 14px;
            }

            &:before {
              margin-top: 0px !important;
              left: -40px; top: 0;
              background-color: #000; line-height: 40px;
              height: 40px; max-height: 40px; width: 40px;
            }
          }

          .owl-next {
            position: relative; z-index: 2;
            float: right;
            margin-right: 40px;
            display: block; width: 25%;
            height: 40px; max-height: 40px;
            background-color: #fff;
            text-align: right;
            line-height: 40px;
            padding: 0 15px 0 0;

            > span {
              display: inline-block;
              vertical-align: middle;
              line-height: 14px;
            }

            &:before {
              margin-top: 0px !important;
              right: -40px; left: auto; top: 0;
              background-color: #000; line-height: 40px;
              height: 40px; max-height: 40px; width: 40px;
            }
          }

          .owl-dots {
            position: absolute; z-index: 1; top: 0; left: 0; padding: 10px calc( 25% + 60px );
            display: block; width: 100%;
            box-sizing: border-box; text-align: center;

            .owl-dot {
              display: inline-block; vertical-align: top;
              font-family: ntt, sans-serif;
              font-size: 18px; line-height: 18px;
              padding: 0 3px;

              &.active {
                font-weight: bold; font-size: 15px; line-height: 17px;
                color: $brun-f;
              }
            }

          }

        }

      }

      border-left: 0px none !important;
      border-right: 0px none !important;

      .inner-container {
        margin: 0 auto 0 auto; padding: 0 0;
        width: 100%; max-width: $max-width !important; font-size: 16px;

        >  h2, > h3,
        >  .h2, > .h3 {
          width: 50%; text-align: left;

          @media screen and ( max-width: $screen-xs ) {
            width: calc(100% - 80px); padding: 0px 0px 30px 40px;
          }

        }

        @extend %clearfix;
        box-sizing: border-box;

        .half {
          width: 50%; background-color: #fff;
          box-sizing: border-box; float: left;

          border-right: 1px solid #cccccc;
          text-align: left;
          overflow: hidden;

          @media screen and ( max-width: $screen-sm ) {
            border: 0px none !important;
            width: 100%;
          }

          &.half-figure {
            border-left: 1px solid #cccccc;
            border-right: 0px none;
            margin-left: -1px;
            padding: $content-spacing $content-spacing 0 $content-spacing;

            @media screen and ( max-width: $screen-xs ) {
              padding: $mobile-spacing $mobile-spacing 0 $content-spacing;
            }

            figure {
              margin: 0px 0px 20px 0px;
              width: 100%; height: auto;
              background-color: #ffffff;

              img {
                position: absolute; top: 0; left: 0;
                width: 100%; height: auto; z-index: 2;

                transform: translate3d(0,0,0);
              }

              .room_hover {
                opacity: 0; z-index: 3;

                transition-property: opacity;
                transition-duration: .3s;

                &.show{ opacity: 1; }
              }

            }

            @media screen and ( max-width: $screen-sm ) {
              display: none;
            }

          }

        }

        .fieldset {
          position: relative; display: block;
          font-family: ntt, sans-serif;
          @include font( 14px, 16px ); font-weight: bold;
          color: $brun-f; text-transform: uppercase;
          width: 100%; text-align: left;
          margin: 10px 0 10px 0;

          > span {
            position: relative; z-index: 2; display: inline-block;
            background-color: #fff; padding: 5px 10px;
          }

          &:after {
            position: absolute; z-index: 1; top: 50%; left: 0; right: 0; display: block; content: "";
            background-color: $brun-f; width: 100%; height: 1px;
          }

        }

        &:before { width: 50%; }
      }

    }

  }

.bloc-slider-listing-room {
  margin: $content-spacing 0 !important; padding: 0 0;
  width: 100%; max-width: 100%; font-size: 16px;

  @extend %clearfix;

  figure {  margin: 0 0; }

  h2, h3,
  .h2, .h3 {
    font-family: ntt, sans-serif;
    @include font(42px, 16px);
    color: $brun !important;
    text-transform: uppercase;
    background-color: #fff; text-align: center;
    display: block; box-sizing: border-box;
    padding: 0px;  margin: 0px $content-spacing;

    @media screen and ( max-width: $screen-md ) { @include font(37px, 16px); }
    @media screen and ( max-width: $screen-xs ) { @include font(27px, 16px); }
  }

  &.owl-not-loader {
    .inner-container {
      @media screen and ( max-width: $screen-xs ) {
        padding: 0 $mobile-spacing;
      }
    }
  }


  .inner-container {
    position: relative; z-index: 2;
    width: 100%; max-width: $max-width;
    margin: 0 auto; padding: 0 $content-spacing;
    background-color: #fff;
    text-align: right;

    @media screen and ( max-width: $screen-xs ) {
      background-color: transparent;
    }

    @extend %clearfix;
    box-sizing: border-box;

    > * {
      position: relative; z-index: 2;
    }

    .slider-listing-rooms {
      margin-top: $content-spacing;
      transform: translate3d(0,0,0);

      .owl-item {
        position: relative; z-index: 1;

        .rooms-listing.decalmap {
          .infobull .pop-up-box {
            left: auto !important; right: 10px;
            &:after {
              left: auto !important; right: 10px;
              margin-left: -4px;
            }


          }
        }
      }

      @for $i from 1 through 100 {
        .owl-item:nth-child(#{$i}) {
          z-index: 100 - $i;
        }
      }

    }

    // -- Owl carousel -- //

    .custom-controler {
      position: absolute; z-index: 1; top: 0; left: 0; right: 0;
      box-sizing: border-box;

      .owl-nav {
        .owl-prev, .owl-next {
          max-height: 100% !important;
        }
      }
    }

  }

}

.bloc-slider-listing-cms {
  margin: $content-spacing 0 !important; padding: 1px 0 0 0;
  width: 100%; max-width: 100% !important; font-size: 16px;

  @extend %clearfix;

  figure {  margin: 0 0; }

  h2, h3,
  .h2, .h3 {
    font-family: ntt, sans-serif;
    @include font(42px, 16px);
    color: #000 !important;
    text-transform: uppercase;
    background-color: #fff; text-align: left;
    display: block; box-sizing: border-box;
    padding: 0px; margin: 35px 0 15px 0;

    @media screen and ( max-width: $screen-md ) { @include font(37px, 16px); }
    @media screen and ( max-width: $screen-xs ) { @include font(27px, 16px); }
  }

  &.owl-not-loader {
    .inner-container {
      @media screen and ( max-width: $screen-xs ) {
        padding: 0 $mobile-spacing;
      }
    }
  }

  .inner-container {
    position: relative; z-index: 2;
    width: 100%; max-width: $max-width;
    margin: 0 auto; padding: 0 $content-spacing;
    background-color: #fff;
    text-align: right;
    @extend %clearfix;

    @media screen and ( max-width: $screen-xs ) {
      background-color: transparent;
    }

    @extend %clearfix;
    box-sizing: border-box;

    > * {
      position: relative; z-index: 2;
    }

    .slider-listing-cms {
      margin-top: $content-spacing;
      transform: translate3d(0,0,0);

      .owl-item {
        position: relative; z-index: 1;

        .rooms-listing.decalmap {
          .infobull .pop-up-box {
            left: auto !important; right: 10px;
            &:after {
              left: auto !important; right: 10px;
              margin-left: -4px;
            }


          }
        }
      }

      @for $i from 1 through 100 {
        .owl-item:nth-child(#{$i}) {
          z-index: 100 - $i;
        }
      }

    }

    // -- Owl carousel -- //

    .custom-controler {
      position: absolute; z-index: 1; top: 0; left: 0; right: 0;
      box-sizing: border-box;
    }

  }

}