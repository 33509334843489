
ul.partnersList {
  margin: 20px auto; padding: 0px $content-spacing; text-align: center;
  display: block; list-style: none; box-sizing: border-box;
  width: 100%; max-width: $max-width;
  font-size: 16px;

  @media screen and ( max-width: $screen-xs ) {
    padding: 0px $mobile-spacing;
  }

  li {
    display: inline-block; width: auto; height: 80px;
    margin: 0px 10px;

    &:before{
      display: none;
    }

    a {
      border: 0px none;
      text-decoration: none;
    }

    figure {
      height: 80px; width: auto;
      margin: 0 !important; padding: 0;

      img{
        height: 100%; width: auto;
        margin: 0; padding: 0;
      }

      figcaption { display: none; }

    }

  }

}