.social-share{
	position: relative;
	ul{
		font-size: 0;
		text-align: center;
		padding: 0;
		margin: 35px auto 0 auto;
		li{
			display: inline-block;
			vertical-align: middle;
			padding: 0;
			margin: 0 5px;
			&:before{
				content: none;
			}
			a{
				display: block;
				font-size: 30px;
				border: none;
				color: #FFFFFF;
				@include addTransition();
				&:hover, &:focus{
					color: $brun;
					text-decoration: none;
				}
				span{
					display: block;
				}
			}
		}
	}
}