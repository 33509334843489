
#fixMobileMenu {
  position: relative; z-index: 2; display: none;
  margin: 0 0; padding: 0 0; text-align: left;
  text-align: left; width: calc(100% - 200px);
  float: left;

  transition-property: margin;
  transition-duration: 0.2s;

  border-bottom: 1px solid rgba(255,255,255,0.3);

  @extend %clearfix;

  @media screen and ( max-width: $screen-md ) {
    display: block;
  }

}

.is-sticky, .force-sticky {

  #fixMobileMenu {
    border-bottom: 1px solid $brun;
  }

}