
ul.menu.menu-access {
	position: relative;
	z-index: 2;
	margin: 0 0;
	padding: 23px 0 23px 0;
	float: right;
	text-align: right;
	width: 200px;
	font-size: 0;

	transition-property: margin;
	transition-duration: 0.2s;

	@media screen and (max-width: $screen-md) {
		padding: 15px 0 0 0;
	}

	[class^="icon-"], [class*=" icon-"] {
		display: block;
		position: relative;
		border: 0px none;
		cursor: pointer;
		font-family: ntt, sans-serif !important;
		font-weight: bold;
		@include font(16px, 18px);
		color: #fff;
		margin: 0px 0px;
		padding: 9px 10px 7px 10px;
		&:before {
			font-family: 'icomoon' !important;
			color: $brun;
		}
		&:hover {
			&:before {
				color: #fff;
			}
		}
	}

	.icon-search {
		border: 0px none !important;

		&:hover {
			background-color: transparent !important;
			&:before {
				color: #fff !important;
			}
		}

		&:before {
			font-size: 17px;
			transition-property: color;
			transition-duration: 0.2s;
		}
	}

	> li {
		font-size: 14px;
		line-height: 1.25em;
		position: relative;
		z-index: 1;
		font-family: ntt, sans-serif !important;
		font-weight: bold;
		color: #ffffff;
		text-transform: uppercase;
		padding: 0px;
		margin: 0px 3px 0px 3px;

		display: inline-block;
		vertical-align: top;

		@extend %flex-full-row;

		&.search-open{
			margin-left: 0;
		}

		> a, > span {
			text-align: center;
			width: 100%;
			padding: 10px 10px 8px 10px;

			font-family: ntt, sans-serif !important;
			font-weight: bold;
			@include font(16px, 18px);

			@extend %flex-valign;

			color: #fff;
			box-sizing: border-box;

			transition-property: border, color, background;
			transition-duration: 0.2s;
			border: 1px solid $brun;

			&:hover {
				background-color: #fff;
				color: #000;
				border: 1px solid #fff;
			}

		}

		&.current-menu-item, &.current-menu-ancestor {
			> a {
				background-color: $brun;
				color: #fff;
			}
		}

		&.has-sub-menu, &.menu-item-has-children, &.lang-switcher {
			&:hover {
				z-index: 2;
				> a {
					background-color: #fff;
					color: #000 !important;
				}
				> ul {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		&.lang-switcher {

			.current-lang {
				cursor: pointer;

				&:after {
					position: relative;
					color: #fff;

					/* use !important to prevent issues with browser extensions that change fonts */
					font-family: 'icomoon' !important;
					speak: none;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;

					/* Better Font Rendering =========== */
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;

					transition-property: color;
					transition-duration: 0.2s;

					content: "\e902";
					font-size: 11px;
					margin: 0 0 0 7px;
				}
			}

			ul.menu-lang {
				position: absolute;
				z-index: 0;
				top: 100%;
				left: 0px;
				right: 0px;
				background-color: #ffffff;
				overflow: hidden;
				box-sizing: border-box;
				border-top: 1px solid #ddd;

				transition-property: opacity, height, top !important;
				transition-duration: 0.3s;

				opacity: 0;
				visibility: hidden;

				@extend %clearfix;

				-webkit-box-shadow: 0px 12px 25px 0px rgba(0, 0, 0, 0.2);
				-moz-box-shadow: 0px 12px 25px 0px rgba(0, 0, 0, 0.2);
				box-shadow: 0px 12px 25px 0px rgba(0, 0, 0, 0.2);

				li {
					position: relative;
					z-index: 2;
					height: auto;
					text-align: left;
					padding: 0;
					display: block;
					border-bottom: 1px solid #ddd;

					&:after {
						display: none;
					}

					> a, > span {
						font-weight: normal;
						color: $gris;
						padding: 10px 10px 8px 10px;
						display: block;
						font-family: ntt, sans-serif;
						@include font(18px, 22px);

						transition-property: color, background;
						transition-duration: 0.2s;

						&:hover {
							background-color: #ececec;
						}

					}

					&:last-child {
						border: 0px none;
					}

				}

			}

			&:hover {
				.current-lang {
					&:after {
						color: #000;
					}
				}
				> ul {
					opacity: 1;
					visibility: visible;
				}
				> a, span {
					border: 1px solid #fff;
					background-color: #fff;
					color: #000;
				}
			}

		}

	}

}

.is-sticky, .force-sticky {

	ul.menu.menu-access > li > a, ul.menu.menu-access > li > span {
		color: #000;
		&:hover {
			color: #fff;
			background-color: $brun;
			border: 1px solid $brun;
		}
	}

	.lang-switcher {
		&:hover {
			.current-lang {
				background-color: $brun !important;
				border: 1px solid $brun !important;
				color: #fff !important;
			}
		}
	}

	ul.menu.menu-access .icon-search {
		&:hover {
			&:before {
				color: #000 !important;
			}
		}
	}

	ul.menu.menu-access > li.lang-switcher .current-lang:after {
		color: #000;
		&:hover {
			color: #fff !important;
		}
	}

	ul.menu.menu-access > li.lang-switcher {
		color: #000;
		&:hover {
			.current-lang:after {
				color: #fff !important;
			}
		}
	}

}