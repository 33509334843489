
.post {

	&.single-event {
		font-size: 16px;
		overflow: hidden;

		> .container {
			position: relative;
			z-index: 2;
			margin: $content-spacing auto $content-spacing auto;
			border-left: $content-spacing solid transparent;
			border-right: $content-spacing solid transparent;
			width: 100%;
			max-width: $max-width;
			box-sizing: border-box;

			@media screen and (max-width: $screen-xs) {
				margin: $mobile-spacing auto $mobile-spacing auto;
				border-left: $mobile-spacing solid transparent;
				border-right: $mobile-spacing solid transparent;
			}

			@extend %clearfix;

			.container-article {
				position: relative;
				z-index: 3;
				width: calc(100% - 350px - 20px);
				float: left;
				box-sizing: border-box;
				border-bottom: 1px solid #eeeeee;;

				@media screen and (max-width: $screen-sm) {
					width: 100%;
				}

				> .bloc {
					border: 0px none !important;
					//margin-bottom: 0px !important;

					&.bloc-video .btn-ytbe .h2 {
						@include font(30px, 18px);

						@media screen and (max-width: $screen-md) {
							@include font(25px, 18px);
						}
						@media screen and (max-width: $screen-xs) {
							@include font(20px, 18px);
						}
					}
				}

			}

			.container-sidebar {
				position: relative;
				z-index: 5;
				width: calc(350px - 20px);
				float: right;
				text-align: center;
				box-sizing: border-box;

				@media screen and (max-width: $screen-sm) {
					margin-top: 0;
					width: 100%;
					padding-top: 0px;
				}

				.sticky-inner {
					text-align: left;
					border-top: 4px solid #000000;
					border-bottom: 4px solid #000000;

					@media screen and (max-width: $screen-sm) {
						top: 0px !important;
						width: 100% !important;
						margin-top: $mobile-spacing;
					}

					.data-info {
						text-align: left;
						background-color: #fff;
						color: #000;
						padding: 35px $content-spacing;
						border: 1px solid #d5d5d5;

						-webkit-box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.3);
						-moz-box-shadow: 0px 12px 25px 0px rgba(0, 0, 0, 0.3);
						box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.3);

						@media screen and (max-width: $screen-xs) {
							padding: 35px $mobile-spacing;
						}

						h1, h2, h3, h4, h5, h6,
						.h1, .h2, .h3, .h4, .h5, .h6 {
							color: #000;
						}

						h2 {
							margin: 0px 0 15px 0;
							text-transform: uppercase;
							@include font(30px, 18px);

							@media screen and (max-width: $screen-md) {
								@include font(25px, 18px);
							}
							@media screen and (max-width: $screen-xs) {
								@include font(20px, 18px);
							}
						}

						h3 {
							text-transform: none;
							@include font(16px, 18px);
						}

						hr {
							border: 0px none;
							margin: 15px 0px !important;
						}

						p {
							padding: 0 0;
							color: #000;
						}

						[class^="icon-"], [class*=" icon-"] {
							display: inline-block;
						}

						.icon-parking {
							font-size: 25px;
							line-height: 25px;
							margin-right: 25px;
							margin-top: 20px;
						}

						.icon-handicap {
							font-size: 25px;
							line-height: 25px;
							margin-right: 0px;
							margin-top: 20px;
						}

					}

				}

			}

		}
	}

	&.event-listing {
		position: relative;
		z-index: 1;
		display: block;
		overflow: hidden;
		font-size: 16px;
		text-align: left;
		margin: 0 0 0 0;
		padding: 0 0;
		max-width: 480px;

		@media screen and (max-width: $screen-xs) {
			max-width: 100%;
		}

		transition-property: background;
		transition-duration: 0.3s;

		> a {
			display: block;
			border: 0px none;
			text-decoration: none;
		}

		figure {
			margin: 0px auto 0px auto !important;
			padding: 0;

			img {
				transition-property: transform;
				transition-duration: 0.4s;

				transform: translate3d(0, 0, 0);
				-webkit-transform: translate3d(0, 0, 0);

			}
		}

		header{
			margin-bottom: 5px;
		}

		h1, h2, h3, h4 {
			color: #000;
			@include font(18px, 18px);
			height: 100% !important;
			display: block;
			text-align: left;
			width: 100%;
			padding: 0;
			margin: 5px 0 10px 0;
			box-sizing: border-box;
		}

		p {
			color: $gris;
		}

		.author {
			margin: 5px 0 10px 0;
			display: block;
			color: $brun-f;
			font-family: nttd, sans-serif;
			@include font(16px, 16px);
		}

		.fig_wrapper {
			position: relative;
			margin: 0px auto 25px auto;

			> a {
				display: block;
				border: 0px none;
				text-decoration: none;
			}

			figure {
				position: relative;
				z-index: 1;
				margin: 0 0 25px 0;
				padding: 0 0;
				overflow: hidden;
				width: 100%;
				height: auto !important;

				transform: translate3d(0, 0, 0);
				-webkit-transform-style: preserve-3d;
				-webkit-transform: translate3d(0, 0, 0);

				img {
					transition-property: transform;
					transition-duration: 0.4s;
					min-height: 250px;

					transform: translate3d(0, 0, 0);
					-webkit-transform-style: preserve-3d;
					-webkit-transform: translate3d(0, 0, 0);

				}

				figcaption {
					display: none;
				}

			}

			/*.form_date {
			  position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 2;
			  display: block; text-transform: uppercase;*/

			-webkit-transform: translate3d(0, 0, 0);
			-webkit-backface-visibility: hidden;

			.time {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				float: none;
				clear: none;
				margin: 0px 0;
				padding: 20px 10px 60px 10px;
				font-family: nttd, sans-serif;
				background-color: rgba(#000, 0.7);
				max-width: 75px;
				width: 75px;
				text-align: center;
				color: $brun-f;
				text-transform: uppercase;
				font-size: 14px;
				line-height: 14px;

				> span {
					display: block;
					color: #fff;
					line-height: 20px;
					margin: 0 0 15px 0;

					> span {
						display: block;
						line-height: 16px;
					}
					strong {
						font-size: 30px;
						line-height: 30px;
					}
					.strong {
						font-size: 20px;
						line-height: 20px;
					}
					small {
						font-size: 13px;
						line-height: 13px;
					}
					.small {
						font-size: 11px;
						line-height: 11px;
					}
					.very-small {
						font-size: 10px;
						line-height: 11px;
					}
					sup {
						font-size: 13px;
						line-height: 13px;
					}
					strong, small {
						display: block;
						color: #fff;
					}
				}
			}

			.icon-addCalendar {
				display: block;
				width: 75px;
				height: 50px;
				text-align: left;
				position: absolute;
				right: 0;
				bottom: 0;
				z-index: 3;
				color: #fff !important;
				text-decoration: none;
				border: 0px none;
				font-size: 25px !important;
				box-sizing: border-box;
				padding: 7px 20px;

				-webkit-transform: translate3d(0, 0, 0);
				-webkit-backface-visibility: hidden;
				span {
					display: none;
				}
			}

			.current_event {
				width: 120px;
				position: absolute;
				z-index: 1;
				top: 0px;
				left: 0px;
				display: inline-block;
				font-family: ntt, sans-serif;
				font-size: 12px !important;
				line-height: 12px !important;
				font-weight: bold;
				box-sizing: border-box;
				text-align: center;
				min-height: 30px;
				transform: translate3d(0, 0, 0);

				text-decoration: none;
				user-select: none;
				border: 0px none;

				margin: 10px 10px;
				padding: 10px 10px 7px 10px;

				vertical-align: middle;
				word-break: break-word;
				white-space: normal;
				text-transform: uppercase;

				-webkit-appearance: none;
				-moz-appearance: none;
				touch-action: manipulation;

				transition-property: all;
				transition-duration: 0.3s;

				color: #fff;
				background-color: $brun;

			}

			//}

		}

	}

}

html.desktop {

	.post {

		&.event-listing {

			&:hover {

				figure img {
					transform: scale(1.05) rotate(0.001deg) translateZ(1px);
				}

				.link {
					&:after {
						right: 12px;
					}
				}

			}

		}

	}

}