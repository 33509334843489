.bloc-accordeon {
  width: 100%; max-width: $max-width;

  .container {
    position: relative; z-index: 2;
    padding: 0 0; box-sizing: border-box;

    .accordeon {
      position: relative; display: block;
      background-color: #ececec;
      color: #7a7a7a; cursor: pointer;
      font-family: ntt, sans-serif;
      @include font(18px, 18px );
      padding: 22px 50px 18px 20px;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid #fff;

      transition-property: color, background;
      transition-duration: .3s;

      [class^="icon-"], [class*=" icon-"] {
        &:before {
          position: absolute; top: 25px; right: 25px;
          font-size: 14px; line-height: 14px;

          transition-property: transform;
          transition-duration: .3s;

        }
      }

      &.opened {
        background-color: $brun;
        color: #ffffff;

        [class^="icon-"], [class*=" icon-"] {
          &:before {
            -webkit-transform: rotate( -180deg );
            -ms-transform: rotate( -180deg );
            transform: rotate( -180deg );
          }
        }

      }

    }

    .panel {
      display: block; overflow: hidden;
      margin: 0 0; height: 0;
      border-top: 0px solid #fff;  border-bottom: 0px solid #fff;

      transition-property: height, border;
      transition-duration: .3s;

      @extend %clearfix;

      figure {
        float: left;
        margin: 0 0; width: 35%; height: auto;
        img { width: 100%; height: auto; }
        figcaption { display: none; }
      }

      .container {
        float: right;  color: #000;
        width: calc( 100% - 35% - 40px );

        p { color: #000; }
      }

      @media screen and ( min-width: $screen-sm ) and ( max-width: $screen-lg ){
        .container {
          width: calc( 100% - 35% - 20px );
        }
      }

      @media screen and ( max-width: $screen-xs ){
        figure { max-width: 350px; margin-bottom: 20px; }
        .container, figure {
          width: 100%;
        }
      }

      &.opened {
        height: auto !important;
        border-top: 20px solid #fff;  border-bottom: 20px solid #fff;
      }

    }

  }

}