
header .btn, header button, #main .bloc .btn, #main .bloc button, footer .btn, footer button,
#stickySidebar button, #stickySidebar .btn, #overlay .overlay_form_wrapper button, #overlay .overlay_form_wrapper .btn {
  position: relative; display: inline-block;
  font-family: ntt, sans-serif;
  font-size: 14px !important; line-height: 17px !important;
  font-weight: bold;
  box-sizing: border-box;
  text-align: left;

  cursor: pointer;

  text-decoration: none;
  user-select: none;
  border: 0px none;

  min-width: 180px; max-width: 300px;

  margin: 10px 0px;
  padding: 18px 70px 16px 20px;

  vertical-align: middle;
  word-break: break-word;
  white-space: normal;
  text-transform: uppercase;

  -webkit-appearance: none;
  -moz-appearance: none;
  touch-action: manipulation;

  transition-property: all;
  transition-duration: 0.3s;

  color: #fff;
  background-color: $brun;

  &:after {
    position: absolute; top: 20px; right: 20px;
    display: block; content: '\e901';
    color: #fff; font-size: 11px; line-height: 11px;

    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

  &:hover {
    background-color: darken($brun,10%);
  }

  &.transparent {
    background-color: transparent;
    border: 1px solid #fff;
    margin: 0px 0px; padding: 15px 50px 13px 20px;

    &:after { top: 17px; content: '\ea1c'; }

    &:hover {
      background-color: rgba(0,0,0,0.3);
      border-color: $brun;
    }

  }

  &.grey {
    background-color: #373737;
    border: 1px solid #373737;
    margin: 0px 0px; padding: 18px 70px 16px 20px;

    &:hover {
      background-color: lighten( #373737, 5% );
      border-color: lighten( #373737, 5% );
    }

  }

  &.full-btn {
    margin: 5px 0px;
    min-width: 100%; max-width: 400px;
  }

  &.arrow-bottom {
    max-width: 400px;
    padding: 18px 50px 16px 20px;
    margin: 10px auto;

    &:after {
      position: absolute;
      top: 17px; right: 20px;
      display: block; content: '\e902';
      color: #fff; font-size: 15px; line-height: 15px;
    }
  }

}

.open-layer {
  cursor: pointer;
}



header button, #main button, footer button{
  outline: none !important;

  -webkit-appearance: none;
  -moz-appearance:    none;

  &:focus {
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
}

.close-layer, .close-layer-menu {
  position: absolute; top: 25px; right: 25px;
  width: 50px; height: 50px; z-index: 8;
  display: block; vertical-align: middle;
  padding: 0; margin: 0; overflow: hidden;
  border: none; outline: none !important;
  background-color: transparent;

  @media screen and ( max-width: $screen-xs ) {
    top: 15px; right: 15px;
  }
}

.close-layer::before, .close-layer-menu::before {
  position: absolute; left: 5px; top: 48%;
  height: 3px; content: '';
  transform-origin: 50% 50%;
}

.close-layer::after, .close-layer-menu::after  {
  position: absolute; left: 5px; top: 48%;
  height: 3px; content: '';

  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;

}

.menu__close::before, .close-layer::before, .close-layer-menu::before {
  -webkit-transform: rotate( 45deg );
  -ms-transform: rotate( 45deg );
  transform: rotate( 45deg );
}

.menu__close::after, .close-layer::after, .close-layer-menu::after{
  -webkit-transform: rotate( -45deg );
  -ms-transform: rotate( -45deg );
  transform: rotate( -45deg );
}

.menu__close::before,
.menu__close::after,
.menu__close > span {
  background: #fff;
  width: 80%; height: 3px;
}

.close-layer::before,
.close-layer::after,
.close-layer > span,
.close-layer-menu::before,
.close-layer-menu::after,
.close-layer-menu > span {
  background: $brun;
  width: 80%; height: 3px;
}

.menu__close > span, .close-layer > span, .close-layer-menu > span  {
  display: none;
}


.menu__open {
  position: relative; width: 35px !important; height: 30px !important; outline: none !important;
  vertical-align: top; cursor: pointer;
  padding: 0; margin: 20px 10px 20px 15px;
  overflow: hidden; border: none;
  background-color: transparent;
  display: block;
}

.menu__open::before,
.menu__open::after,
.menu__open > span {
  background-color: $brun;
  width: 100%; top: 50%
}

.menu__open::before,
.menu__open::after {
  position: absolute; left: 0; top: 50%;
  height: 4px; content: '';
  transform-origin: 50% 50%;
}

.menu__open::before {
  top: 12%;
}

.menu__open > span {
  position: absolute; left: 0;
  height: 4px; overflow: hidden;
  text-indent: 99999px;
}

.menu__open::after {
  top: 88%;
}