/* Title
----------------------------- */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	position: relative;
	display: block;
	margin: 15px 0 15px 0;
	padding: 0px;
	color: #ffffff;

	font-family: ntt, sans-serif;
	font-weight: bold;

	small {
		display: block;
		font-size: 0.8em;
	}
}

h1, .h1 {
	text-transform: uppercase;
	@include font(55px, 16px);

	@media screen and (max-width: $screen-md) {
		@include font(45px, 16px);
	}
	@media screen and (max-width: $screen-xs) {
		@include font(35px, 16px);
	}
}

h2, .h2 {
	text-transform: uppercase;
	@include font(42px, 16px);

	@media screen and (max-width: $screen-md) {
		@include font(32px, 16px);
	}
	@media screen and (max-width: $screen-xs) {
		@include font(22px, 16px);
	}
}

h3, .h3 {
	text-transform: uppercase;
	@include font(30px, 16px);

	@media screen and (max-width: $screen-md) {
		@include font(25px, 16px);
	}
	@media screen and (max-width: $screen-xs) {
		@include font(20px, 16px);
	}
}

h4, .h4 {
	text-transform: uppercase;
	@include font(20px, 16px);
}

h5, .h5 {
	text-transform: uppercase;
	@include font(18px, 16px);
}

h6, .h6 {
	text-transform: uppercase;
	@include font(16px, 16px);
}

.subtitle {
	font-family: ntt, sans-serif;
	font-weight: normal;
	color: $gris;
	@include font(14px, 27px);
	text-transform: uppercase;
	display: block;
	margin-bottom: -7px !important;
}

.date-separator {
	position: relative;
	font-family: ntt, sans-serif;
	font-weight: bold;
	color: $brun;
	font-size: 16px;
	text-transform: uppercase;
	width: 100%;
	display: block;
	box-sizing: border-box;

	margin: $mobile-spacing auto 15px auto;
	padding: 0 0;

	span {
		@include font(42px, 20px);
		position: relative;
		z-index: 2;
		background-color: #fff;
		color: $brun;
		display: inline-block;
		padding: 0 10px 0 0;
	}

	&:after {
		position: absolute;
		top: 50%;
		left: 0;
		z-index: 1;
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		background-color: $brun;
	}
}

/* Paragraphe
----------------------------- */
p, time, .time {
	font-family: ntt, sans-serif;
	font-weight: normal;
	color: $gris;
	@include font(16px, 24px);

	margin: 0;
	padding: 6px 0px;

	&.chapeau {
		text-align: left;
		font-family: nttd, sans-serif;
		font-weight: normal;
		color: $brun-f;
		@include font(16px, 24px);
	}

	a {
		color: $link;
		text-decoration: none;
		border-bottom: 1px solid $link;

		transition-property: color, border;
		transition-duration: 0.3s;

		&:hover {
			color: $linkHover;
			border-bottom: 1px solid $linkHover;
		}
	}

	strong, b {
		font-weight: bold;
		em, i {
			font-style: italic;
		}
	}

	em, i {
		font-style: italic;
	}

}

time, .time {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin: 10px 0;
	padding: 9px 15px 9px 15px;
	background-color: $brun;
	color: #fff;
	font-family: roboto, sans-serif;
	@include font(18px, 16px);
	box-sizing: border-box;
	strong {
		text-transform: uppercase;
	}

	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;

	@media screen and (max-width: $screen-xs) {
		@include font(16px, 16px);
	}

}

.tags {
	display: inline-block;
	vertical-align: top;
	font-family: ntt, sans-serif;
	@include font(14px, 17px);
	color: $gris;
	text-transform: uppercase;
	font-weight: bold;
}

.author {
	display: inline-block;
	vertical-align: top;
	margin: 5px 0 10px 0;
}

.link {
	position: relative;
	font-family: ntt, sans-serif;
	@include font(14px, 17px);
	color: $brun-f;
	text-transform: uppercase;
	font-weight: bold;
	box-sizing: border-box;
	padding: 10px 65px 10px 0;
	display: inline-block;
	text-decoration: none !important;
	cursor: pointer;
	border: 0px none !important;

	&:after {
		position: absolute;
		top: 2px;
		right: 24px;
		display: block;
		content: '\e901';
		color: $brun-f;
		font-size: 26px;

		/* use !important to prevent issues with browser extensions that change fonts */
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		transition-property: right;
		transition-duration: 0.2s;

	}

	&:hover {
		&:after {
			right: 12px;
		}
	}

	&.no-arrow {
		padding: 10px 0px 10px 0;
		&:after {
			display: none !important;
		}
		&:hover {
			text-decoration: underline !important;
		}
	}

}

/* List
----------------------------- */

ul, ol {
	text-align: left;
	font-family: ntt, sans-serif;
	font-weight: normal;
	color: $gris;
	margin: 10px 0 20px 0;
	padding: 0px 0px 0px 40px;

	list-style-position: outside !important;
	list-style-type: none;

	@include font(15px, 24px);

	a {
		color: $linkHover;
		text-decoration: none;
		border-bottom: 1px solid transparent;

		transition-property: color, border;
		transition-duration: 0.3s;

		&:hover {
			color: $linkHover;
			border-bottom: 1px solid $linkHover;
		}
	}

	strong, b {
		font-weight: bold;
		em, i {
			font-style: italic;
		}
	}

	em, i {
		@include font(16px, 24px);
		font-style: italic;
	}

	li {
		position: relative;
		padding: 3px 0;
		box-sizing: border-box;

		&:before {
			position: absolute;
			left: -30px;
			width: 30px;
			top: 7px;
			color: $gris;
			display: block;
			content: "\e906";

			font-size: 11px;

			/* use !important to prevent issues with browser extensions that change fonts */
			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;

			/* Better Font Rendering =========== */
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

		}

	}

	ul {
		@include font(16px, 24px);
		list-style-type: circle;
		ul {
			@include font(16px, 24px);
			list-style-type: square;
		}
	}

	&.pertwo {
		li {
			display: inline-block;
			padding-right: 50px;
			width: calc(50% - 30px);
		}
	}

}

ol {
	counter-reset: li;
	list-style-type: none;

	li {
		position: relative;
		padding: 3px 0;

		&:before {
			position: absolute;
			left: -30px;
			top: 2px;

			font-family: ntt, sans-serif;
			color: $gris-f;
			padding-top: 1px;
			font-size: 13px;
			line-height: 17px;

			content: counter(li);
			counter-increment: li;

			border: 1px solid $brun-f;
			height: 20px;
			width: 20px;
			text-align: center;
			border-radius: 20px;

		}

	}
}

/* Address
----------------------------- */
address, .address {
	font-family: ntt, sans-serif;
	@include font(16px, 25px);
	color: #e6e6e6;
	font-weight: normal;
	font-style: normal;

	.title {
		display: block !important;
		color: #e6e6e6;
		font-family: ntt, sans-serif;
		padding: 5px 0 0 0 !important;
		font-weight: bold;
		text-transform: none;
	}

	.section {
		display: block !important;
		padding: 0 0 !important;
		margin: 12px 0;
		@include font(16px, 25px);
		text-transform: none;
		color: #e6e6e6;
	}

	a {
		text-decoration: none;
		color: #e6e6e6;
		padding: 0px 0 !important;
		&:hover {
			color: $brun !important;
		}
	}

	.underline {
		border-bottom: 1px solid $gris !important;
		line-height: 16px;
	}

}

.address {
	background-color: #000;
	padding: $content-spacing $content-spacing ($content-spacing - 10px) $content-spacing;
	@include font(14px, 24px);

	@media screen and (max-width: $screen-xs) {
		padding: $mobile-spacing $mobile-spacing ($mobile-spacing - 10px) $mobile-spacing;
	}

	.title {
		color: $brun;
		margin: 0 0 0 0;
		@include font(22px, 18px);
		text-transform: uppercase;
	}
}
