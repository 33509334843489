
html * {
  outline:none !important;
  :focus {outline:none !important;}
  ::-moz-focus-inner {border:0 !important;}
}

html.stop-scroll, html.stop-scroll-overlay {
  overflow: hidden !important;

  body {
    overflow: hidden !important;
  }
}

html {
  font-size: 100% !important;
  background-color: #ffffff;
  min-height: 100%; width: 100%;

  body.admin-bar { margin-top: -32px; }

  body {
    font-family: ntt, sans-serif;
    font-weight: normal;
    @include font();
    color: #000000;
  }

}