
ul.menu.menu-share {
  margin: 0px 0 10px 0; padding: 0;
  text-align: left; font-size: 0;

  > li {
    font-size: 16px;
    position: relative; text-align: left; padding: 0px 0px 0px 0px !important;
    display: inline-block !important; margin: 0px 5px 5px 5px;
    border-radius: 30px; width: 30px; height: 30px;
    background-color: #fff; color: $gris-f; overflow: hidden;

    transition-property: background !important;
    transition-duration: 0.2s;

    &:before { display: none !important; }

    &:hover {
      background-color: $brun;
    }

    > [class^="icon-"], > [class*=" icon-"] {
      position: relative; z-index: 1;
      padding: 0px 0px 0px 0px !important;
      color: $gris-f !important; width: 30px !important;
      text-align: center; line-height: 30px !important;
      font-size: 15px !important;
    }

    .icon-facebook {
      transition-property: background !important;
      transition-duration: 0.2s;
    }

    .icon-twitter {
      transition-property: background !important;
      transition-duration: 0.2s;
      padding: 1px 0 0 1px !important;
    }

    .fb-share-button {
      position: absolute; z-index: 2; top: 0; left: 0; display: block; opacity: 0.01; visibility: visible;
    }

    .twitter-share-button-wrapper {
      position: absolute; z-index: 2; top: 0; left: 0; display: block; opacity: 0.01; visibility: visible;
    }

    .icon-addCalendar {
      margin-top: 1px !important;
    }

  }

  > li.btn_link {
    border-radius: 0px; width: auto; height: auto; line-height: 1em; color: $brun-f;
    margin-bottom: 0px; background: transparent !important;

    a {
      color: $brun-f;
      line-height: auto; padding: 2px 0px !important;
      background: transparent !important;
      border-bottom: 1px solid transparent;
      margin: 8px 2px 6px 2px;

      transition-property: border;
      transition-duration: .3s;

      &:hover {
        background: transparent !important;
        border-bottom: 1px solid $brun-f;
      }
    }
  }

}
