/* ------- Clear Units ------- */

@function clear-units($value) {
	@if type-of($value) == "number" {
		@if (unitless($value)) {
			@return $value;
		} @else if unit($value) == "em" {
			@return $value / 1em;
		} @else if unit($value) == "px" {
			@return $value / 16px;
		} @else if unit($value) == "pt" {
			@return $value / 12pt;
		}
	} @else {
		@warn "Not a number value: #{$value}";
		@return $value;
	}
}

@mixin font( $s: $base-font-size, $l: $base-line-height ) {

	$ns: clear-units($s);
	$nl: clear-units($l);

	font-size: #{$ns}em;
	line-height: #{$nl}em;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

// Mixin Transition effect
// ==========================================================================
@mixin addTransition($effect: all, $duration: 0.2s, $easeing:ease) {
	$anim: $effect $duration $easeing;
	transition: $anim;
}
