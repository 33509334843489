.bloc {
  .zoombox figure figcaption{
    display: none;
  }
}


.bloc-gallery, .bloc-gallery-event {
  margin: $content-spacing auto $content-spacing auto; padding: 0 0;
  width: 100%; max-width: $max-width !important; font-size: 16px;
  text-align: right;

  @extend %clearfix;

  @media screen and ( max-width: $screen-xs ) {
    margin: $mobile-spacing auto $mobile-spacing auto;
  }

  &:before {
    position: absolute; z-index: 1; top: 0; left: 0; display: block; content: "";
    background-color: $brun; width: 50%; height: 190px;

    @media screen and ( max-width: $screen-md ) {  width: 25%; }
    @media screen and ( max-width: $screen-xs ) { width: 60px; }

  }

  .message {
    display: none;
    &.show { display: block; }
  }

  .zipPassword-form-wrapper{
    position: relative;
    margin: 10px 0 0 0 !important;
  }

  .ajax_zip_link {
    position: relative; z-index: 2;
    margin: 0 0 !important; min-height: 51px;
    display: inline-block;  vertical-align: middle; width: 100%;

    min-width: 0px !important;
    max-width: 225px !important;

  }

  .zipPassword-form {
    position: absolute;  z-index: 1;
    top: 0; left: 224px; width: 1px;
    margin: 0 0; padding: 0 0;
    height: 100%; overflow: hidden;
    display: inline-block;  vertical-align: middle;

    min-width: 0px !important;

    transition-property: width;
    transition-duration: 0.2s;

    @media screen and ( max-width: $screen-xs ) {
      position: relative;  z-index: 1;
      top: 0; left: auto; height: 51px;
      margin: 10px 0 0 0; padding: 0;  width: 100% !important;
      display: none;
    }


    .input-wrapper {
    position: relative; height: 100%;
    margin: 0 0; padding: 0 0;

      .ajax_relation_loader {
        position: absolute;
        top: 12px !important;
        right: 58px !important;
        margin: 0 !important;
        bottom: auto !important;
        left: auto !important;
        display: none !important;
      }

      input[name=zipPassword] {
        position: relative;
        height: 100%; box-sizing: border-box;
        padding: 0px 90px 0px 20px;
        font-size: 23px;
        line-height: 100%;
        vertical-align: top;
        border: 1px solid $brun !important;

        &:-moz-placeholder { font-size: 18px !important; }
        &::-moz-placeholder { font-size: 18px !important; }
        &:-ms-input-placeholder { font-size: 18px !important; }
        &::-webkit-input-placeholder { font-size: 18px !important; }

      }

      .input-sufix {
        position: absolute; top: 0px; right: 0; z-index: 3;
        font-size: 16px; line-height: 1.5em;
        padding: 13px 10px 10px 10px;
        height: 100%; width: 51px; text-align: center;
        background: $brun-f; color: #fff;
        cursor: pointer;

        transition-property: background;
        transition-duration: 0.3s;

        &:before {
          position: absolute;  left: 50%; top: 50%;
          margin: -13px 0 0 -10px;
        }

        &:hover {
          background: lighten( $brun-f, 5% );
        }

      }

    }

    &.opened {
      width: calc( 100% - 224px );

      @media screen and ( max-width: $screen-xs ) {
         width: 100% !important;
        display: block;
      }

    }

    &.charged {

      .ajax_relation_loader {
        display: block !important;
      }

    }

  }

  h2, h3,
  .h2, .h3 {
    font-family: ntt, sans-serif;
    @include font(42px, 16px);
    color: $brun !important;
    text-transform: uppercase; text-align: left;
    background-color: #fff; width: 50%;
    display: inline-block; box-sizing: border-box;
    padding: 0px $content-spacing 30px $content-spacing;  margin: 0px;

    @media screen and ( max-width: $screen-md ) {  width: 75%; }
    @media screen and ( max-width: $screen-xs ) {
      width: calc(100% - 80px); padding: 0px 0 20px 0px;
    }

    @media screen and ( max-width: $screen-md ) { @include font(35px, 16px); }
    @media screen and ( max-width: $screen-xs ) { @include font(20px, 16px); }
  }

  figure {
    margin: 0px;
    position:  absolute; top: 0; left: 0; bottom: 0; right: 0;

    img{
      opacity: 1;
      transition-property: opacity;
      transition-duration: 1s;
    }

    &:hover { img{ opacity: 0.9 !important; } }

  }

  .item {
    position: relative;
    display: none;
    float: left;

    a {  text-decoration: none !important;  border: 0px none !important;  }

    &:after {
      position: absolute; z-index: 9; top: 50%; left: 50%;
      height: 40px; width: 40px; font-size: 40px; line-height: 40px;
      display: none; margin: -20px 0 0 -20px;
      box-sizing: border-box;
      border-radius: 40px;

      text-decoration: none !important;
      border: 0px none !important;

      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      transition-property: color, border;
      transition-duration: 0.3s;

      content: "\ea0a";
      color: #fff;
    }

    &.moreImg {
      text-decoration: none !important;
      border: 0px none !important;

      figure img {
        opacity: 0.6 !important;
        &:hover { opacity: 0.8 !important; }
      }

      &:after { display: block; }
      &:hover { &:after { color: lighten($brun,20%); } }

    }

    &:nth-child(-n+4) {
      display: block;
    }

    &:nth-child(4n+1) {
      width: 50%;
      &:before{ content: ""; display: block;  padding-top: 100%; }
    }

    &:nth-child(4n+2) {
      width: 25%;
      &:before{ content: ""; display: block;  padding-top: 200%; }
    }

    &:nth-child(4n+3) {
      width: 25%;
      &:before{ content: ""; display: block;  padding-top: 100%; }
    }

    &:nth-child(4n+4) {
      width: 25%;
      &:before{ content: ""; display: block;  padding-top: 100%; }
    }

    @media screen and ( max-width: $screen-xs ){

      &:nth-child(4n+1) {
        width: 100%;
        &:before{ content: ""; display: block;  padding-top: 100%; }
      }

      &:nth-child(4n+2) {
        width: 50%;
        &:before{ content: ""; display: block;  padding-top: 200%; }
      }

      &:nth-child(4n+3) {
        width: 50%;
        &:before{ content: ""; display: block;  padding-top: 100%; }
      }

      &:nth-child(4n+4) {
        width: 50%;
        &:before{ content: ""; display: block;  padding-top: 100%; }
      }

    }

  }

}

.bloc-relations-gallery {
  border: 0px none; position: relative;
  margin: $content-spacing auto; padding: 0 0;
  width: 100%; max-width: $max-width !important; font-size: 16px;
  text-align: left;

  @extend %clearfix;

  @media screen and ( max-width: $screen-xs ) {
    margin: 0 auto $mobile-spacing auto;
  }

  > h2 {
    margin: 30px 0px 5px 0;
    padding-right: 200px;
    text-transform: uppercase;
    @include font( 30px, 18px  );

    @media screen and ( max-width: $screen-md ) { @include font( 25px, 18px ); }
    @media screen and ( max-width: $screen-xs ) { @include font( 20px, 18px ); }

    @media screen and ( min-width: $screen-sm ) and ( max-width: $screen-md ){
      padding-right: 0px;
    }

    @media screen and ( max-width: $screen-xs ){
      padding-right: 0px;
    }

  }

  > .link {
    position: absolute; top: 0; right: 0;
    max-width: 200px !important; margin-bottom: 15px;

    @media screen and ( min-width: $screen-sm ) and ( max-width: $screen-md ){
      position: relative; top: auto; right: auto;
    }

    @media screen and ( max-width: $screen-xs ){
      position: relative; top: auto; right: auto;
    }

  }

  .container {
    margin: 30px 0 0 0;

    @media screen and ( min-width: $screen-sm ) and ( max-width: $screen-md ){
      margin: 0 0;
    }

    @media screen and ( max-width: $screen-xs ){
      margin: 0 0;
    }

    .item {
      position: relative;
      overflow: hidden; float: left;
      &:before{  content: ""; display: block;  padding-top: 75%; }

      figure {
        position: absolute; z-index: 1; top: 0; left: 0; bottom: 0; right: 0;
        display: block; margin: 0px 0px;

        &:before{
          content: ''; display: block;
          position: absolute; z-index: 2; bottom: 0; left: 0; top: 0; right: 0; opacity: 1;
          background: url('../img/backend/shadow-left.png') no-repeat left top;
          background-size: 100% 100% !important; opacity: 0.7;
        }
      }

      h4 {
        position: absolute; z-index: 2; top: auto; left: 20px; bottom: 15px; right: 20px;
        margin: 0 0; padding: 0 0; color: #fff;
        @include font(14px,16px);
      }

      &:nth-child(5n+1) { width: 50%; }
      &:nth-child(5n+2) { width: 50%; }

      &:nth-child(5n+3) { width: 33.33%; }
      &:nth-child(5n+4) { width: 33.33%; }
      &:nth-child(5n+5) { width: 33.33%; }

      @media screen and ( min-width: $screen-sm ) and ( max-width: $screen-md ){
        width: 50% !important;
      }

      @media screen and ( max-width: $screen-xs ){
        width: 50% !important;
      }

      @media screen and ( max-width: 450px){
        width: 100% !important;
      }

    }

  }
}

.bloc-gallery-event {

  &:before {
    display: none;
  }

  .item {

    &:before{ display: none !important; }

    figure {
      position: relative !important; z-index: 1; bottom: auto !important; right: auto !important;
      display: block; margin: 0px 0px;

      &:before{
        display: none !important;
      }
    }

    &:nth-child(-n+5) {
      display: block;
    }

    &:nth-child(5n+1) { width: 50%; }
    &:nth-child(5n+2) { width: 50%; }

    &:nth-child(5n+3) { width: 33.33%; }
    &:nth-child(5n+4) { width: 33.33%; }
    &:nth-child(5n+5) { width: 33.33%; }
  }

}

html.desktop .bloc-gallery .item, html.desktop .bloc-gallery-event .item {
  &:hover {
    figure img {
      opacity: 1;
    }
  }
}