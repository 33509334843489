//
// Alerts
// --------------------------------------------------

#cookies_notif .cookies_notif_inner {
  padding: 15px 15px 15px 15px;
}

#cookies_notif .cookies_notif_inner .pop-in-title {
  padding: 0px 0 10px 0;
}

#cookies_notif .cookies_notif_inner a.cookiebutton {
  margin: 20px 0 0 0;
  padding: 8px 25px 7px 25px;
}

#cookies_notif .cookies_notif_inner p {
  font-size: 0.8em;
  line-height: 20px;
}

// Alerts

@mixin alert-variant($background, $border, $text-color) {
  color: $text-color !important;

  p {
    color: $text-color !important;
  }

  hr {
    border-top-color: darken($border, 5%);
  }
  .alert-link {
    color: darken($text-color, 10%);
  }
}



// Base styles
// -------------------------

%alert {
  margin-bottom: 15px;
  border: 1px solid transparent;
  border-radius: 0px;

  padding: 1em 2em 1em 3.5em !important;
  margin: 0 0 2em !important;
  position: relative;
  list-style: none outside !important;
  width: auto;
  word-wrap: break-word;

  box-sizing: border-box;

  font: normal normal normal 15px/16px ntt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;

  // Headings for larger alerts
  h4 {
    margin-top: 0;
    // Specified for the h4 to prevent conflicts of changing $headings-color
    color: inherit;
  }

  // Provide class for links that match alerts
  .alert-link {
    font-weight: bold;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}


// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

%alert-success {
  @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
  background-color: $alert-success-bg;
  border-top: 8px solid $alert-success-border;
}

%alert-info {
  @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
  background-color: $alert-info-bg;
  border-top: 8px solid $alert-info-border;
}

%alert-warning {
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
  background-color: $alert-warning-bg;
  border-top: 8px solid $alert-warning-border;
}

%alert-danger {
  @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
  background-color: $alert-danger-bg;
  border-top: 8px solid $alert-danger-border;
}