.bloc-cols-text {
  text-align: center;
  margin: -20px auto $content-spacing auto; padding: 0 0;
  border-left: $content-spacing - 10px solid transparent;
  border-right: $content-spacing - 10px solid transparent;

  @media screen and ( max-width: $screen-xs ) {
    margin: -20px auto $mobile-spacing auto;
    border-left: $mobile-spacing - 10px solid transparent;
    border-right: $mobile-spacing - 10px solid transparent;
  }

  .container {
    @extend %clearfix;
    padding-left: 1%; margin: 0px -1%;
    text-align: left; font-size: 0;
    overflow: hidden;

    .passed_title {
      color: #000;
      font-size: 28px;
      margin: 50px 0 0px 0;
    }

    .item {
      font-size: 16px;
      position: relative; z-index: 1;
      width: 33%; vertical-align: top;
      display: inline-block;
      border: 10px solid transparent;
      border-top-width: 20px;
      box-sizing: border-box;
      text-decoration: none;
      text-align: left;

      transition-property: background;
      transition-duration: 0.3s;

      > a { display: block; border: 0px none; text-decoration: none; }

      figure {
        margin: 0px auto 15px auto;

        img{
          transition-property: transform;
          transition-duration: 0.4s;
        }
      }

      h1, h2, h3, h4 {
        color: #000; @include font( 18px, 18px );
        height: 100% !important;
        display: block; text-align: left;
        width: 100%; padding: 0px 0px; margin: 5px 0 7px 0;
        box-sizing: border-box;
      }

      p {
        color: $gris;
      }

    }

    @media screen and ( max-width: $screen-sm ) {
      padding-left: 0px; margin: 0px auto 0px auto;

      .item {
        width: 50%;
      }

    }

    @media screen and ( max-width: $screen-xs ) {
      padding-left: 0px; margin: 0px auto 0px auto;

      .item {
        width: 100%;
      }

    }

  }

  &.pertwo {

    .container {
      padding-left: 0px; margin: 0px auto 0px auto;

      .item {
        width: 50%;
      }

    }

    @media screen and ( max-width: $screen-xs ) {
      .container {
        padding-left: 0px; margin: 0px auto 0px auto;

        .item {
          width: 100%;
        }

      }
    }

    &.deport-left {

      .container {

        .item:nth-child(2n+1) {
          width: 65%;
        }

        .item:nth-child(2n+2) {
          width: 35%;
        }

      }

      @media screen and ( max-width: $screen-xs ) {
        .container {

          .item {
            width: 100% !important;
          }

        }
      }

    }

    &.deport-right {

      .container {

        .item:nth-child(2n+1) {
          width: 35%;
        }

        .item:nth-child(2n+2) {
          width: 65%;
        }

      }

      @media screen and ( max-width: $screen-xs ) {
        .container {

          .item {
            width: 100% !important;
          }

        }
      }

    }

  }

  &.relation {
    max-width: $max-width-little; text-align: left;
    margin: $content-spacing auto; padding: 0 0;

    > h3, > .h3 {
      font-family: ntt, sans-serif;
      @include font(42px, 16px);
      color: #000 !important;
      text-transform: uppercase; text-align: left;
      display: inline-block; box-sizing: border-box;
      padding: 0px 10px 10px 10px;  margin: 0px;

      @media screen and ( max-width: $screen-xs ) {
        padding: 0px 10px 10px 10px;
      }

      @media screen and ( max-width: $screen-md ) { @include font(37px, 16px); }
      @media screen and ( max-width: $screen-xs ) { @include font(27px, 16px); }
    }

    .container {
      .item {
        h1, h2, h3, h4 {
          color: #000;
          @include font(18px, 18px);
          display: block;
          text-align: left;
          width: 100%;
          padding: 0px 0px;
          margin: 5px 0 7px 0;
          box-sizing: border-box;
        }
      }
    }

  }

  &.bloc-cols-only-text {
    border-left: $content-spacing - 15px solid transparent;
    border-right: $content-spacing - 15px solid transparent;

    @media screen and ( max-width: $screen-xs ) {
      border-left: $mobile-spacing - 15px solid transparent;
      border-right: $mobile-spacing - 15px solid transparent;
    }

    .container {
      padding-left: 1%;
      margin: 0px -1%;

      .item {
        border: 15px solid transparent;
      }
    }


    ul, ol {
      margin: 5px 0 5px 0;
      padding: 0px 0px 0px 25px;

      li {
        &:before { left: -23px; }
      }
    }

  }

}


html.desktop .bloc-cols-text .container a.item {
  &:hover {

    figure img {
      transform: scale(1.05) rotate(0.001deg) translateZ(1px);
    }

    .link {
      &:after { right: 12px; }
    }

  }
}