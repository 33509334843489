/*[fmt]0A90-0A0A-2*/
#zoombox
#zoombox iframe,#zoombox img{
	border:none;
}
#zoombox .zoombox_mask{
	background-color:#000;
	position:fixed;
	width:100%;
	z-index:999;
	height:100%;
	top:0;
	left:0;
}
#zoombox .zoombox_container{
	position:absolute;
	z-index:9999;
}
#zoombox .relative{
	position:relative;
}
#zoombox .zoombox_prev{
	position:absolute;
	top:0;
	left:-65px;
	width:65px;
	height:100%;
	cursor:pointer;
	/*+opacity:0;*/
	filter:alpha(opacity=100);
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	-moz-opacity:1;
	opacity:1;
}
#zoombox .zoombox_prev:hover{
	/*+opacity:100%;*/
	filter:alpha(opacity=100);
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	-moz-opacity:1;
	opacity:1;
}
#zoombox .zoombox_next{
	position:absolute;
	top:0;
	right:-65px;
	width:65px;
	height:100%;
	/*+opacity:0;*/
	filter:alpha(opacity=100);
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	-moz-opacity:1;
	opacity:1;
}
#zoombox .zoombox_close,#zoombox .zoombox_next,#zoombox .zoombox_prev{
	cursor:pointer;
}
#zoombox .multimedia .zoombox_next,#zoombox .multimedia .zoombox_prev{
	//display:none;
}
#zoombox .zoombox_next:hover{
	/*+opacity:100%;*/
	filter:alpha(opacity=100);
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	-moz-opacity:1;
	opacity:1;
}
#zoombox .zoombox_gallery{
	position:fixed;
	z-index:99999;
	bottom:-60px;
	left:0;
	right:0;
	text-align:center;
	/*+opacity:85%;*/
	filter:alpha(opacity=85);
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=85);
	-moz-opacity:0.85;
	opacity:0.85;
	cursor:pointer;
	width:auto;
	background-color: transparent;
	//background-color:#050505;
	height:80px;
	overflow-y: auto;
	display:none;
}
#zoombox .zoombox_gallery img{
	height:70px;
	padding: 5px;
	filter:alpha(opacity=40);
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
	-moz-opacity:0.4;
	opacity:0.4;

	transition-property: opacity;
	transition-duration: 0.3s;

	-webkit-transform: translate3d(0,0,0);
	-webkit-backface-visibility: hidden;
}
#zoombox .zoombox_gallery img:hover,#zoombox .zoombox_gallery img.current{
	/*+opacity:100%;*/
	filter:alpha(opacity=100);
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	-moz-opacity:1;
	opacity:1;
}
#zoombox_loader{
	background:url(../img/zoombox/loader.png) no-repeat left top;
	position:absolute;
	height:40px;
	width:40px;
	cursor:pointer;
	top:50%;
	left:50%;
	margin:-20px 0 0 -20px;
	z-index:99998;
}
/** Zoombox Theme **/
.zoombox .zoombox_container{
	background:#000;
	color:#666;
	/*+box-shadow:0px 0px 10px #000000;*/
	-moz-box-shadow:0px 0px 10px #000000;
	-webkit-box-shadow:0px 0px 10px #000000;
	-o-box-shadow:0px 0px 10px #000000;
	box-shadow:0px 0px 10px #000000;
	-moz-box-shadow:0 0 10px #000;
	-webkit-box-shadow:0 0 10px #000;
	-o-box-shadow:0 0 10px #000;
	box-shadow:0 0 10px #000;
}
.zoombox .content{
	background:#000;
}
.zoombox .zoombox_title{
	color:#FFF;
	/*+placement:anchor-top-left -1px -22px;*/
	position:absolute;
	left:-1px;
	top:-22px;
	/*+text-shadow:1px 1px #000000;*/
	-moz-text-shadow:1px 1px #000000;
	-webkit-text-shadow:1px 1px #000000;
	-o-text-shadow:1px 1px #000000;
	text-shadow:1px 1px #000000;
	-moz-text-shadow:1px 1px #000;
	-webkit-text-shadow:1px 1px #000;
	-o-text-shadow:1px 1px #000;
	text-shadow:1px 1px #000;
}

.zoombox .zoombox_close {
	position: absolute; top: 25px; right: 25px;
	width: 50px; height: 50px; z-index: 8;
	display: block; vertical-align: middle;
	padding: 0; margin: 0; overflow: hidden;
	border: none; outline: none !important;
	background-color: transparent;
}
@media screen and (max-width:650px){ .zoombox .zoombox_close{ top: 15px; right: 15px; } }
.zoombox .zoombox_close::before {
	position: absolute; left: 5px; top: 48%;
	content: '';

	background: $brun;
	width: 80%; height: 3px;

	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-o-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;

	-webkit-transform: rotate( 45deg );
	-ms-transform: rotate( 45deg );
	transform: rotate( 45deg );
}
.zoombox .zoombox_close::after{
	position: absolute; left: 5px; top: 48%;
	content: '';

	background: $brun;
	width: 80%; height: 3px;

	-webkit-transform: rotate( -45deg );
	-ms-transform: rotate( -45deg );
	transform: rotate( -45deg );

	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-o-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}


#zoombox.zoombox .zoombox_next{ text-align: center; }
#zoombox.zoombox .zoombox_next:hover:before{ color: #c9b899 !important; margin-left: 5px; }
#zoombox.zoombox .zoombox_next:before{

	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	content: "\e906"; display: block;
	color: #fff; font-size: 18px; line-height: 18px; height: 18px;
	position: absolute; top: 50%; width: 100%;
	margin-top: -8px;

	transition-property: all;
	transition-duration: 0.3s;

}
#zoombox.zoombox .zoombox_prev{
	text-align: center;
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform-origin: 50% 50%;
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-o-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
}
#zoombox.zoombox .zoombox_prev:hover:before{ color: #c9b899 !important; margin-left: 5px; }
#zoombox.zoombox .zoombox_prev:before{

	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	content: "\e906"; display: block;
	color: #fff; font-size: 18px; line-height: 18px; height: 18px;
	position: absolute; top: 50%; width: 100%;
	margin-top: -8px;

	transition-property: all;
	transition-duration: 0.3s;

}


/** Lightbox Theme **/
.lightbox .zoombox_container{
	background:#FFF;
	margin-left:-30px;
	padding:10px 10px 37px;
	color:#666;
}
.lightbox .zoombox_close{
	background:url(../img/zoombox/nav-icon-close.ong) no-repeat left top;
	width:66px;
	height:22px;
	top:auto;
	/*+placement:anchor-bottom-right 12px 6px;*/
	position:absolute;
	right:12px;
	bottom:6px;
}
.lightbox .zoombox_title{
	text-align:left;
}
.lightbox .zoombox_next{
	background:url(../img/zoombox/lightnext.gif) no-repeat right 50px;
}
.lightbox .zoombox_prev{
	background:url(../img/zoombox/lightprev.gif) no-repeat left 50px;
}
#zoombox.lightbox .zoombox_gallery{
	left:10px !important;
	right:10px;
	bottom:36px;
}
/** Pretty Photo Theme **/
.prettyphoto .zoombox_container{
	border:10px solid #0B0A0A;
	/*+border-radius:10px;*/
	-moz-border-radius:10px;
	-webkit-border-radius:10px;
	-khtml-border-radius:10px;
	border-radius:10px 10px 10px 10px;
	background:#FFF;
	padding:10px 10px 37px;
	margin-left:-20px;
	color:#797979;
}
.prettyphoto .zoombox_title{
	text-align:left;
}
.prettyphoto .zoombox_close{
	background:url(../img/zoombox/ppsprite.png);
	width:27px;
	height:24px;
	top:auto;
	/*+placement:anchor-bottom-right 6px 8px;*/
	position:absolute;
	right:6px;
	bottom:8px;
}
.prettyphoto .zoombox_next{
	background:url(../img/zoombox/ppnext.png) no-repeat right center;
}
.prettyphoto .zoombox_prev{
	background:url(../img/zoombox/ppprev.png) no-repeat left center;
}
.prettyphoto .multimedia .zoombox_next,.prettyphoto .multimedia .zoombox_prev{
	//display:none;
}
#zoombox.prettyphoto .zoombox_gallery{
	left:10px !important;
	right:10px;
	bottom:36px;
}
/** Dark Pretty Photo Theme **/
.darkprettyphoto .zoombox_container{
	/*+border-radius:10px;*/
	-moz-border-radius:10px;
	-webkit-border-radius:10px;
	-khtml-border-radius:10px;
	border-radius:10px 10px 10px 10px;
	background:#0C0C0C;
	padding:10px 10px 37px;
	margin-left:-30px;
	color:#828282;
}
.darkprettyphoto .zoombox_title{
	/*[empty]position:;*/
	text-align:left;
}
.darkprettyphoto .zoombox_close{
	background:url(../img/zoombox/ppsprite.png);
	width:27px;
	height:24px;
	top:auto;
	/*+placement:anchor-bottom-right 2px 6px;*/
	position:absolute;
	right:2px;
	bottom:6px;
}
.darkprettyphoto .zoombox_next{
	background:url(../img/zoombox/ppnext.png) no-repeat right center;
}
.darkprettyphoto .zoombox_prev{
	background:url(../img/zoombox/ppprev.png) no-repeat left center;
}
.darkprettyphoto .multimedia .zoombox_next,.darkprettyphoto .multimedia .zoombox_prev{
	display:none;
}
#zoombox.darkprettyphoto .zoombox_gallery{
	left:10px !important;
	right:10px;
	bottom:36px;
}
/** Simple Theme **/
.simple .zoombox_container{
	background:#000;
}
.simple .zoombox_title{
	color:#FFF;
	/*+placement:anchor-top-left -1px -22px;*/
	position:absolute;
	left:-1px;
	top:-22px;
	/*+text-shadow:1px 1px #000000;*/
	-moz-text-shadow:1px 1px #000000;
	-webkit-text-shadow:1px 1px #000000;
	-o-text-shadow:1px 1px #000000;
	text-shadow:1px 1px #000000;
	-moz-text-shadow:1px 1px #000;
	-webkit-text-shadow:1px 1px #000;
	-o-text-shadow:1px 1px #000;
	text-shadow:1px 1px #000;
}
#zoombox.simple .zoombox_next{
	background:url(../img/zoombox/simplenav.png) no-repeat -20px center;
	/*+opacity:100%;*/
	filter:alpha(opacity=100);
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	-moz-opacity:1;
	opacity:1;
	right:-20px;
	width:20px;
}
#zoombox.simple .zoombox_next:hover{
	background-position-x:left;
}
#zoombox.simple .zoombox_prev{
	background:url(../img/zoombox/simplenav.png) no-repeat -40px center;
	/*+opacity:100%;*/
	filter:alpha(opacity=100);
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	-moz-opacity:1;
	opacity:1;
	left:-20px;
	width:20px;
}
#zoombox.simple .zoombox_prev:hover{
	background-position-x:-60px;
}
.simple .zoombox_prev{
	background:url(../img/zoombox/ppprev.png) no-repeat left center;
}
.simple .zoombox_close{
	background:url(../img/zoombox/simpleclose.png) no-repeat;
	width:30px;
	height:30px;
	position:absolute;
	top:0;
	right:-10px;
}

#zoombox.simple .multimedia .zoombox_next,#zoombox.simple .multimedia .zoombox_prev{
	display:block;
}
