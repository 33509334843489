.bloc-video {
  margin: $content-spacing 0; padding: 0 0;
  width: 100%; max-width: 100% !important; font-size: 16px;
  max-height: 800px; background-color: #000;
  overflow: hidden;

  border-left: 0px none !important;
  border-right: 0px none !important;

  @media screen and ( max-width: $screen-xs ) {
    padding: $mobile-spacing 0;
  }

  .embed-container {
    position: absolute;
    top: -1px; left: -1px; right: -1px; bottom: -1px;

    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

  .tac_player{
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
  }

  .mask-video {
    position: absolute;
    top: -1px; left: -1px; right: -1px; bottom: -1px;
    z-index: 20; opacity: 1;

    figure  {
      position: relative;
      height: 100%; width: 100%;
      margin: 0px auto !important;

      img {
        width: 100%; height: 100%;
        opacity: 0.7;

        transition-property: opacity, transform;
        transition-duration: 1s;

      }
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }

    .btn-ytbe {
      position: absolute; z-index: 10; color: #fff;
      top: 50%; left: 50%; text-align: center;
      transform: translate(-50%, -50%);
      text-decoration: none;

      * {
        transform: translate3d(0,0,0);
      }
    }

  }

}