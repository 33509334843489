.bloc-temoignage {
  text-align: center;
  padding: 1px 0 0 0;
  border-left: $content-spacing - 10px solid transparent;
  border-right: $content-spacing - 10px solid transparent;

  @media screen and ( max-width: $screen-xs ) {
    border-left: $mobile-spacing - 10px solid transparent;
    border-right: $mobile-spacing - 10px solid transparent;
  }

  > h2, > h3,
  > .h2, > .h3 {
    color: $brun;
    text-align: center;
    margin: 35px 0 15px 0;
  }

  .container {
    @extend %clearfix;
    padding: 0 0; margin: 0px auto 0px auto;
    text-align: left; font-size: 0;
    overflow: hidden;

    .item {
      font-size: 16px;
      position: relative;
      z-index: 1;
      width: 50%;
      vertical-align: top;
      display: inline-block;
      border: 10px solid #fff;
      border-top-width: 20px;
      border-bottom: 0px none;
      box-sizing: border-box;
      text-decoration: none;
      text-align: center;

      padding: 15px 20px;
      background-color: #ececec;

      h2, h3, h4
      .h2, .h3, .h4 {
        color: #000; display: block;
        text-align: center;
        margin: 20px 0 5px 0;
      }

      .tags {
        display: block;
        font-family: nttd, sans-serif;
        font-weight: normal;
        margin: 10px 0 15px 0;
      }

      p {
        font-size: 15px;
        color: #000000;
      }
    }

    @media screen and ( max-width: $screen-xs ) {
      padding-left: 0px; margin: 0px auto 0px auto;

      .item {
        width: 100%;
      }

    }

  }

}