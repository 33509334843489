
.bloc {
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: $max-width;

	margin: $content-spacing auto;
	padding: 0 0;

	border-left: $content-spacing solid transparent;
	border-right: $content-spacing solid transparent;

	@extend %clearfix;
	box-sizing: border-box;

	@media screen and (max-width: $screen-xs) {

		margin: $mobile-spacing auto;

		border-left: $mobile-spacing solid transparent;
		border-right: $mobile-spacing solid transparent;

	}

	.tab-title {
		margin-top: 30px;
		text-transform: uppercase;
		color: $brun;
	}

	&.bloc-slider-homepage {
		figure {
			margin: 0;
			padding: 0;
		}
	}

	&.center-text {
		text-align: center;
	}

	&.bloc-share {
		text-align: center;
		margin-bottom: -$content-spacing !important;

		@media screen and (max-width: $screen-xs) {
			margin-bottom: -$mobile-spacing !important;
		}


		.social-share{
			.network-list{
				margin: 20px auto;
				li{
					a{
						color: #000000;
						border: none;
						&:hover, &:focus{
							color: $brun-f;
						}
					}
				}
			}
		}


		.menu-share {
			text-align: center;

			.icon-facebook {
				background-color: #000000;
				color: #fff !important;
			}

			.icon-twitter {
				background-color: #000000;
				color: #fff !important;
			}

			li {

				&:hover {
					.icon-facebook, .icon-twitter {
						background-color: $brun-f !important;
					}
				}

			}

		}

	}

	.ajax_relation_loader {
		position: absolute;
		bottom: -23px;
		left: 50%;
		margin: 0 0 0 -12px;
		display: none;
	}

	&.ajax_call {
		margin-bottom: $content-spacing !important;
	}

}

section.bloc {
	position: relative;
	margin: 0px auto;
	max-width: 100%;
	border: 0px none;
}

.bloc-frm {
	margin: 0px auto $content-spacing auto;

	@media screen and (max-width: $screen-xs) {
		margin: 0px auto $mobile-spacing auto;
	}

	&.bloc-m {
		margin: $content-spacing*1.5 auto $content-spacing auto !important;

		@media screen and (max-width: $screen-xs) {
			margin: $mobile-spacing*1.5 auto $mobile-spacing auto !important;
		}
	}

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		color: #000;
		margin: 15px 0 25px 0;
	}

	h3 {
		text-transform: uppercase;
		@include font(25px, 18px);

		@media screen and (max-width: $screen-md) {
			@include font(20px, 18px);
		}
		@media screen and (max-width: $screen-xs) {
			@include font(18px, 18px);
		}
	}

}