// Evo #14876
button.open-video-modale{
	background-color: $brun-f;
	min-width: 160px;
	padding-right: 40px;
	margin-bottom: 20px;
	&:after{
		content: '\ea1c';
		top: 20px;
	}
}
.video-modale{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	pointer-events: none;
	z-index: 500;
	@include addTransition();

	.close{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 5;
		background-color: rgba(0,0,0,.7);
		cursor: auto;

		.close-btn{
			position: absolute;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			top: 0;
			height: 100px;
			left: 50%;
			transform:translate3d(-50%,0,0,);
			font-size: 14px;
			line-height: 14px;
			text-transform: uppercase;
			font-family: ntt, sans-serif;
			color: white;
			font-weight: 700;
			cursor: pointer;
			@include addTransition();
			@media screen and (max-width: 800px){
				height: 40px;
			}

			&:hover{
				color: $c_main;
				.cross{
					&:before,&:after{
						background-color: $c_main;
					}
				}
			}

			.cross{
				position: relative;
				display: inline-block;
				width: 10px;
				height: 12px;
				margin-left: 4px;
				margin-top: -3px;
				&:before,&:after{
					content: '';
					height: 2px;
					width: 14px;
					position: absolute;
					top: 0;
					background-color: white;
					@include addTransition();
				}
				&:before{
					left: 0;
					transform-origin: center left;
					transform:rotate(45deg) translate3d(0,0,0);
				}
				&:after{
					right: 0;
					transform-origin: center right;
					transform:rotate(-45deg) translate3d(0,0,0);
				}
			}
		}
	}

	.embed-container{
		position: absolute;
		top: 100px;
		left: 100px;
		right: 100px;
		bottom: 100px;
		z-index: 10;
		@media screen and (max-width: 800px){
			top: 40px;
			left: 40px;
			bottom: 40px;
			right: 40px;
		}

		iframe{
			width: 100%;
			height: 100%;
		}
	}

	&.active{
		opacity: 1;
		pointer-events: auto;
	}
}

#main.modale-open{
	position: static;
}