$c_rgpd_red: #2E2E2E;
$c_rgpd_darken: #2E2E2E;
$c_dark: black;
$c_white: white;
$f_txt: ntt, sans-serif;

#tarteaucitronIcon {
	display: none !important;
}

#tarteaucitronBackModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000000;
	background: rgba($c_dark, 0.5) !important;
	display: none;
}

#tarteaucitron {
	position: fixed;
	top: 0;
	height: 100vh !important;
	max-height: 100%;
	width: 100% !important;
	background: none;
	margin: 0 auto !important;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	pointer-events: auto;
	@media screen and (max-width: 600px) {
		pointer-events: auto;
	}

	.tarteaucitronH3 {
		font-family: $f_txt;
		font-size: 14px;
		font-weight: 400;
		color: $c_rgpd_darken;
		line-height: 16px;
	}

	#tarteaucitronServices {
		margin: 0 !important;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		height: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		pointer-events: auto;
		@media screen and (max-width: 600px) {
			pointer-events: auto;
		}
		&:after {
			content: '';
			position: relative;
			display: block;
			width: 100%;
			height: 50px;
		}
		#tac-scroller {
			position: relative;
			z-index: 1001;
			margin: 50px auto 0;
			background: $c_white;
			box-shadow: 0 50px 50px rgba($c_dark, 0.1);
			border-radius: 10px;
			overflow: hidden;
			pointer-events: auto;
			*{
				pointer-events: auto;
			}
			@media screen and (max-width: 880px) {
				width: calc(100% - 80px);
				margin: 50px 40px 0;
			}
			@media screen and (max-width: 600px) {
				width: 100%;
				margin: 50px 0 0;
			}
		}

		#tarteaucitronMainLineOffset, .tarteaucitronBorder {
			position: relative;
			box-sizing: border-box;
			background: $c_white;
			width: 100%;
			max-width: 800px;
			margin: 0 auto;
		}
	}
}

#tarteaucitronRoot {
	&.tarteaucitronBeforeVisible {
		#tarteaucitronBackModal {
			display: block;
		}
	}

	#tarteaucitronBack {
		padding: 0;
		background: rgba($c_dark, 0.5);
		z-index: 1000;
	}

	button#tarteaucitronBack {
		background: rgba($c_rgpd_darken, 0.6);
	}

	// BARRE BOTTOM
	#tarteaucitronAlertBig {
		box-sizing: border-box;
		padding: 20px;
		background: $c_white;
		box-shadow: 0 -10px 50px rgba($c_dark, 0.1);
		text-align: right;
		@media screen and (max-width: 1200px) {
			text-align: center;
		}
		&::after {
			display: block;
			content: "";
			clear: both;
		}

		// Si Modale Centrer
		&.tarteaucitronAlertBigTop {
			//z-index: 2147483641;
			top: 50%;
			left: 50%;
			width: 460px;
			transform: translate(-50%, -50%);
			transform: translate3d(-50%, -50%, 0);
			padding: 30px;
			border-radius: 10px;
			@media screen and (max-width: 600px) {
				width: calc(100% - 40px);
				padding: 20px 20px 15px 20px;
			}
			//& ~ #tarteaucitronBack{
			//	display: block;
			//}

			#tarteaucitronTitleModal {
				font-family: $f_txt;
				font-size: 24px;
				font-weight: 700;
				color: $c_rgpd_darken;
				line-height: 28px;
				position: relative;
				display: block;
				text-align: left;
				margin: 0 0 20px 0;
				@media screen and (max-width: 600px) {
					font-size: calculateRem(20px);
					line-height: calculateRem(24px);
					margin: 0 0 15px 0;
				}
			}

			#tarteaucitronDisclaimerAlert {
				padding: 0;
				text-align: left;
				margin-bottom: 25px;
				@media screen and (max-width: 600px) {
					margin-bottom: 20px;
				}
			}

			> button {
				position: relative;
				float: left;
				width: calc(50% - 5px);
				margin: 0 5px 0 0;
				@media screen and (max-width: 600px) {
					width: 100%;
					margin: 5px 0;
				}
				&.tarteaucitronAllow {
					width: 100%;
					margin: 0 0 10px 0;
					@media screen and (max-width: 600px) {
						margin: 5px 0;
					}
				}
				&#tarteaucitronCloseAlert {
					margin: 0 0 0 5px;
					@media screen and (max-width: 600px) {
						margin: 5px 0;
					}
				}
			}
		}

		#tarteaucitronDisclaimerAlert {
			font-family: $f_txt;
			font-size: 14px;
			font-weight: 400;
			color: $c_dark;
			line-height: 16px;
			position: relative;
			display: block;
			float: left;
			text-align: center;
			padding: 12px 0;
			@media screen and (max-width: 1200px) {
				float: none;
				padding: 0;
				margin-bottom: 20px;
			}
			@media screen and (max-width: 600px) {
				font-size: calculateRem(14px);
				line-height: calculateRem(18px);
			}
		}

		> button {
			font-family: $f_txt;
			font-size: 14px !important;
			font-weight: 400;
			color: $c_white;
			line-height: 16px !important;
			-webkit-appearance: none;
			resize: none;
			box-shadow: none;
			border-radius: 0;
			border: none;
			outline: none;
			&::-ms-clear{
				display: none;
			}
			position: relative;
			box-sizing: border-box;
			padding: 11px 10px;
			margin: 0 5px;
			border: 1px solid rgba($c_rgpd_darken, 0.5);
			border-radius: 2px;
			transition: all 0.3s ease;
			&:hover, &:focus {
				body:not(.no-hover) & {
					cursor: pointer;
					background: $c_white;
					color: $c_dark;
				}
			}
			@media screen and (max-width: 600px) {
				display: block;
				text-align: center;
				width: 100%;
				box-sizing: border-box;
				margin: 10px auto;
				&:last-child {
					margin-bottom: 0;
				}
			}
			span {
				&:before {
					content: none;
				}
			}
			&#tarteaucitronCloseAlert {
				color: $c_dark;
				&:hover, &:focus {
					body:not(.no-hover) & {
						background: $c_dark;
						color: $c_white;
					}
				}
			}
			&.tarteaucitronAllow {
				background: $c_main;
				border-color: $c_main;
				min-width: 190px;
				font-weight: 700;
				@media screen and (max-width: 980px) {
					min-width: 120px;
				}
				&:hover, &:focus {
					body:not(.no-hover) & {
						background: $c_white;
						color: $c_main;
					}
				}
			}
			&.tarteaucitronDeny {
				background: $c_rgpd_red;
				border-color: $c_rgpd_red;
				min-width: 190px;
				@media screen and (max-width: 980px) {
					min-width: 120px;
				}
				&:hover, &:focus {
					body:not(.no-hover) & {
						background: $c_white;
						color: $c_rgpd_red;
					}
				}
			}
		}
	}

	// MODALE SERVICES
	#tarteaucitronServices {
		border: none;
		padding: 0;
		.tarteaucitronMainLine {
			border: none;
			background: $c_white;
			padding: 30px 30px 0 30px;
			&:hover, &:focus {
				background: $c_white;
			}

			.tarteaucitronAsk {
				margin: 0;
				@media screen and (max-width: 600px) {
					width: 100%;
				}
				button {
					min-width: 190px;
					&:first-child {
						margin-left: 0;
					}
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.tarteaucitronH1 {
			font-family: $f_txt;
			font-size: 24px;
			font-weight: 700;
			color: $c_rgpd_darken;
			line-height: 28px;
			margin: 0 0 20px 0;
			text-align: left;
			@media screen and (max-width: 600px) {
				margin: 15px 0 10px 0;
				font-size: calculateRem(20px);
				line-height: calculateRem(24px);
			}
		}

		#tarteaucitronInfo {
			font-family: $f_txt;
			font-size: 14px;
			font-weight: 400;
			color: $c_rgpd_darken;
			line-height: 16px;
			border: none;
			background: none;
			text-align: left !important;
			padding: 0 !important;
			margin: 0 0 35px 0 !important;
			@media screen and (max-width: 600px) {
				margin: 0 0 15px 0 !important;
				width: 100%;
				max-width: 100% !important;
			}
		}
		.tarteaucitronName {
			margin: 0;
			@media screen and (max-width: 600px) {
				width: 100% !important;
				float: none;
				margin: 0 !important;
			}
			.tarteaucitronH2 {
				font-family: $f_txt;
				font-size: 18px;
				font-weight: 700;
				color: $c_rgpd_darken;
				line-height: 20px;
				margin: 0 !important;
				padding: 10px 0;
				@media screen and (max-width: 600px) {
					width: 100%;
					max-width: 100%;
					display: block;
					padding: 0;
					margin: 0 !important;
					text-align: left;
				}
			}
		}

		.tarteaucitronHidden {
			background: $c_white;
		}

		.tarteaucitronTitle {
			button {
				font-family: $f_txt;
				font-size: 14px;
				font-weight: 700;
				color: $c_rgpd_darken;
				line-height: 20px;
				background: #E0E0E0;
				padding: 9px 20px;
				border-radius: 2px;
				margin: 0;
				span {
					display: none;
				}
			}
		}

		.tarteaucitronBorder {
			border: none;
			> ul {
				background: #FAFAFA;
				margin: 10px;
				border-radius: 10px;
				> li {
					padding: 20px;
					position: relative;
					background: none;
					&:first-child {
						&:before {
							content: none;
						}
					}
					&:before {
						content: '';
						position: absolute;
						left: 20px;
						right: 20px;
						top: 0;
						width: calc(100% - 40px);
						height: 1px;
						background: rgba($c_rgpd_darken, 0.1);
					}
				}
			}
		}

		li.tarteaucitronLine {
			border: none;
			background: none;
			padding: 10px 0 0 0;
			&:hover {
				background: none;
			}
			.tarteaucitronAsk {
				margin: 0;
				@media screen and (max-width: 600px) {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 10px 0 0 0;
				}
				button {
					visibility: visible !important;
					@media screen and (max-width: 600px) {
						margin: 0 !important;
						margin-bottom: 10px !important;
					}
					& + button {
						@media screen and (max-width: 600px) {
							margin-left: 10px !important;
						}
					}
				}
			}
			.tarteaucitronName {
				margin: 0;
			}
		}
	}

	#tarteaucitronClosePanel {
		font-size: 0;
		-webkit-appearance: none;
		resize: none;
		box-shadow: none;
		border-radius: 0;
		border: none;
		outline: none;
		&::-ms-clear{
			display: none;
		}
		padding: 0;
		top: 0;
		background: none;
		z-index: 1000;
		width: 75px;
		height: 75px;
		transition: all 0.3s ease;
		@media screen and (max-width: 600px) {
			width: 50px;
			height: 50px;
		}
		&:before {
			content: '';
			background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC44NDkiIGhlaWdodD0iMTQuODQ5IiB2aWV3Qm94PSIwIDAgMTQuODQ5IDE0Ljg0OSI+CiAgPGcgaWQ9Ikdyb3VwZV82IiBkYXRhLW5hbWU9Ikdyb3VwZSA2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTk1LjE1MSAtMTM4KSI+CiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzQiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDQiIHdpZHRoPSIxIiBoZWlnaHQ9IjIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDA5LjI5MyAxMzgpIHJvdGF0ZSg0NSkiLz4KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfNSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNSIgd2lkdGg9IjEiIGhlaWdodD0iMjAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwMTAgMTUyLjE0Mikgcm90YXRlKDEzNSkiLz4KICA8L2c+Cjwvc3ZnPgo=");
			background-repeat: no-repeat;
			background-position: center;
			background-size: 15px;
			display: block;
			height: 100%;
		}
		&:hover, &:focus {
			body:not(.no-hover) & {
				cursor: pointer;
			}
		}
	}

	#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow, #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny, .tac_activate .tarteaucitronAllow {
		font-family: $f_txt;
		font-size: 14px;
		font-weight: 400;
		color: $c_dark;
		line-height: 20px;
		-webkit-appearance: none;
		resize: none;
		box-shadow: none;
		border-radius: 0;
		border: none;
		outline: none;
		&::-ms-clear{
			display: none;
		}
		box-sizing: border-box;
		padding: 10px 20px;
		border-radius: 2px;
		border: 1px solid rgba($c_rgpd_darken, 0.5);
		margin: 0 5px;
		opacity: 1;
		background: none;
		transition: all 0.3s ease;
		&:hover, &:focus {
			body:not(.no-hover) & {
				cursor: pointer;
				background: $c_white;
				color: $c_dark;
			}
		}
		@media screen and (max-width: 600px) {
			display: block;
			text-align: center;
			width: 100%;
			box-sizing: border-box;
			margin: 10px auto;
			&:last-child {
				margin-bottom: 0;
			}
		}
		span {
			&:before {
				content: none;
			}
		}
		&.tarteaucitronAllow {
			background: $c_main;
			border-color: $c_main;
			color: $c_white;
			font-weight: 700;
			&:hover, &:focus {
				body:not(.no-hover) & {
					background: $c_white;
					color: $c_main;
				}
			}
		}
		&.tarteaucitronDeny {
			border-color: $c_rgpd_red;
			background: $c_rgpd_red;
			color: $c_white;
			&:hover, &:focus {
				body:not(.no-hover) & {
					background: $c_white;
					color: $c_rgpd_red;
				}
			}
		}

		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
