.bloc-text {

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #000;
  }

  &.center-text {
    text-align: center;
  }

  &.right-text {
    text-align: right;
  }

  h2 {
    text-transform: uppercase;
    @include font( 30px, 18px );

    @media screen and ( max-width: $screen-md ) { @include font( 25px, 18px ); }
    @media screen and ( max-width: $screen-xs ) { @include font( 20px, 18px ); }
  }

  h3 {
    text-transform: uppercase;
    @include font( 25px, 18px  );

    @media screen and ( max-width: $screen-md ) { @include font( 20px, 18px ); }
    @media screen and ( max-width: $screen-xs ) { @include font( 18px, 18px ); }
  }

}