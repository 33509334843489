
ul.menu.menu-footer {
  position: relative;
  margin: 0px -20px; padding: 0px 0px;
  font-size: 0px; text-align: left;

  @extend %clearfix;

  @media screen and ( max-width: $screen-sm ) {
    display: block !important;
  }

  > li {
    font-size: 14px;
    position: relative; z-index: 1;
    padding: 20px 20px; margin: 0;
    text-align: left; width: 25%;
    box-sizing: border-box;

    @media screen and ( max-width: $screen-sm ) {
      width: 50%;
    }

    @media screen and ( max-width: $screen-xs ) {
      width: 100%;
    }

    h4, .h4 {
      position: relative;
      color: $brun;
      border-bottom: 1px solid $gris;
      padding-bottom: 15px;

      &:after {
        position: absolute; bottom: -3px; left: 0;
        display: block; content: "";
        width: 40px; height: 3px;
        background-color: $gris;
      }
    }

    > ul.sub-menu {
      margin-top: 25px;

      li {
        position: relative; z-index: 2;
        padding: 0px 0px 0px 30px; display: block;
        text-transform: none; color: #e6e6e6;
        font-family: ntt, sans-serif; font-weight: normal;

        &:before {
          position: absolute; top: 8px; left: 3px;
          font-size: 11px;

          /* use !important to prevent issues with browser extensions that change fonts */
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          display: block; content: "\e906";
          color: #ffffff;
        }

        > a, > span {
          font-weight: normal; color: #e6e6e6;
          padding: 5px 0px; display: inline-block;
          font-family: ntt, sans-serif;
          @include font(19px,22px);
          cursor: pointer;

          transition-property: color, background;
          transition-duration: 0.2s;

          &:hover { color: $brun; }

        }

        ul { display: none; }

        &:last-child { border: 0px none; }
        &:after { display: none; }
      }

    }

  }

}