form {
	position: relative;
	font-size: 0px;

	.frm_screen_reader {
		display: none;
	}

	.frm_section_heading {
		margin: 0px 20px 25px 20px !important;
		border-top: 1px solid #e3e5e7;
		border-bottom: 1px solid #e3e5e7;
		padding-bottom: 20px !important;

		> .frm_form_field {
			margin: 0px 0px !important;
			padding-bottom: 0px !important;
		}
	}

	.frm_description {
		font-family: ntt, sans-serif;
		font-weight: normal;
		color: $gris;
		margin: 10px 10px;
		@include font(14px, 22px);
		font-size: 14px;
		text-align: right;
	}

	* {
		box-sizing: border-box;
	}

	.frm_hidden {
		display: none;
	}

	.frm_required {
		color: #000;
	}

	.frm_error_style {
		font-family: nttd, sans-serif;
		@include font(16px, 24px);
		font-size: 16px;
		padding-bottom: 20px;
		font-weight: normal;
		color: $state-danger-text;
	}

	.frm_error {
		color: $state-danger-text;
		margin: 5px 20px;
		font-family: nttd, sans-serif;
		@include font(13px, 22px);
		font-size: 13px;
	}

	.frm_blank_field {
		input, textarea, select {
			border: 1px solid lighten($state-danger-text, 30%) !important;
		}
	}

	label {
		display: block;
		color: #000;
		padding-bottom: 3px;
		font-weight: normal;
		font-family: ntt, sans-serif;
		@include font(15px, 22px);
		font-size: 15px;
	}

	.frm_none_container > label {
		display: none;
	}

	.frm_center.frm_captcha {
		text-align: center;

		@media screen and (max-width: $screen-xs) {
			width: 250px !important;
			overflow: hidden !important;
			text-align: left !important;
		}

		.g-recaptcha {
			display: inline-block;
			margin: 0px auto;
			vertical-align: top;

			@media screen and (max-width: $screen-xs) {

				-webkit-transform: scale(0.8);
				-moz-transform: scale(0.8);
				-ms-transform: scale(0.8);
				-o-transform: scale(0.8);
				transform: scale(0.8);

				-webkit-transform-origin: 0 0;
				-moz-transform-origin: 0 0;
				-ms-transform-origin: 0 0;
				-o-transform-origin: 0 0;
				transform-origin: 0 0;

			}
		}
	}

	.frm_form_fields fieldset {
		margin: 0 -20px;
		padding: 0;
		border: 0px none;
	}

	.form-field {
		display: block;
		vertical-align: top;
		padding-bottom: 15px;
		margin: 0 20px;

		font-family: ntt, sans-serif;
		@include font(16px, 22px);
		font-size: 16px !important;
		color: #000;

		float: none;
		clear: both;

	}

	@media screen and (min-width: $screen-xs) {
		.frm_half {
			width: 50%;
			display: inline-block;
			//float: left; clear: none;
			margin: 0;
			padding: 0 20px 20px 20px;
		}

		.frm_third {
			width: 33.33333%;
			display: inline-block;
			//float: left; clear: none;
			margin: 0;
			padding: 0 20px 20px 20px;
		}

		.frm_two_thirds {
			width: 66.66666%;
			display: inline-block;
			//float: left; clear: none;
			margin: 0;
			padding: 0 20px 20px 20px;
		}
	}

	.frm_clear {
		float: none;
		clear: none;
		margin-right: 20px;
	}

	.frm_radio {
		position: relative;
		display: inline-block;
		font-family: miss, sans-serif;
		cursor: pointer;

		label {
			position: relative;
			z-index: 2;
			padding: 5px 30px 5px 25px;
			cursor: pointer;
			@include font(15px, 24px);
			font-size: 15px;

			transition-property: color;
			transition-duration: 0.2s;

			&:before {
				position: absolute;
				z-index: 1;
				top: 6px;
				left: 0;
				width: 13px;
				height: 13px;
				background: #fff;
				border: 1px solid $gris;
				border-radius: 50%;
				display: block;
				content: "";
			}

			&:after {
				position: absolute;
				z-index: 2;
				top: 10px;
				left: 4px;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background-color: darken($brun, 5);
				display: block;
				content: "";
				opacity: 0;

				transition-property: opacity;
				transition-duration: 0.2s;
			}

		}

		input[type="radio"] {
			position: absolute;
			z-index: -1;
			opacity: 0;
		}

		// Show check mark
		&.checked-label {
			label {
				color: $brun-f;
				font-weight: bold;
			}
			label:after {
				opacity: 1;
			}
		}

	}

	.frm_checkbox {
		position: relative;
		display: block;
		font-family: miss, sans-serif;
		@include font(16px, 24px);
		font-size: 16px;
		cursor: pointer;

		label {
			position: relative;
			z-index: 2;
			padding: 5px 30px 5px 25px;
			cursor: pointer;
			@include font(15px, 24px);
			font-size: 15px;
			color: #000;

			transition-property: color;
			transition-duration: 0.2s;

			&:before {
				position: absolute;
				z-index: 1;
				top: 6px;
				left: 0;
				width: 13px;
				height: 13px;
				background: #fff;
				border: 1px solid $gris;
				display: block;
				content: "";
			}

			&:after {
				position: absolute;
				z-index: 2;
				top: 10px;
				left: 4px;
				width: 7px;
				height: 7px;
				background-color: darken($brun, 5);
				display: block;
				content: "";
				opacity: 0;

				transition-property: opacity;
				transition-duration: 0.2s;
			}
		}

		input[type="checkbox"] {
			position: absolute;
			z-index: -1;
			opacity: 0;
		}

		// Show check mark
		&.checked-label {

			label {
				color: $brun-f;
				font-weight: bold;
			}

			label:after {
				opacity: 1;
			}

		}

	}

	input:not([type="checkbox"]):not([type="radio"]), textarea, select {
		display: block;
		width: 100%;
		border: 1px solid #dddddd;

		font-family: ntt, sans-serif;
		@include font(16px, 24px);
		font-size: 16px;
		color: darken($brun-f, 20%);
		padding: 11px 11px 10px 11px;

		-webkit-appearance: none;
		-moz-appearance: none;

		border-radius: 0px;
		opacity: 1 !important;

		&:-moz-placeholder {
			color: $brun-f !important;
			opacity: 1 !important;
		}
		&::-moz-placeholder {
			color: $brun-f !important;
			opacity: 1 !important;
		}
		&:-ms-input-placeholder {
			color: $brun-f !important;
			opacity: 1 !important;
		}
		&::-webkit-input-placeholder {
			color: $brun-f !important;
			opacity: 1 !important;
		}

	}

	input[disabled] {
		opacity: 1 !important;
		color: $brun !important;
	}

	textarea {
		resize: none;
	}

	.frm_submit {
		margin: 0 20px;
		text-align: center;
	}

	input, select, textarea {
		&:focus, &:hover {
			border: 1px solid $brun !important;
		}
	}

	hr {
		margin: 0px 20px 25px 20px;
	}

	.frm_submit {
		position: relative;
		z-index: 1;
		text-align: center;

		button {
			width: 200px !important;
			margin: 25px 0px;

			@media screen and (max-width: $screen-xs) {
				width: 100% !important;
			}

		}

	}

	.frm_form_field.hasDatepicker input {
		background: transparent url('../img/backend/calendar.png') no-repeat right 10px center;
		padding-right: 45px;
	}

}

.search-form {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	background-color: transparent;
	overflow: hidden;
	padding: 0 0;
	max-width: 740px;
	width: 100%;
	height: 60px;
	font-size: 16px;
	box-sizing: border-box;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;

	@media screen and (max-width: $screen-xs) {
		height: 50px;
	}

	input:not([type="checkbox"]):not([type="radio"]), textarea, select {
		background-color: transparent;
		font-family: ntt, sans-serif;
		@include font(25px, 30px);
		color: $brun;
		padding: 15px 45px 10px 0px;
		width: 100%;
		height: 60px;
		box-sizing: border-box;

		@media screen and (max-width: $screen-xs) {
			@include font(18px, 25px);
			height: 50px;
		}

		border: 0px none !important;
		border-bottom: 1px solid #fff !important;

		&:-moz-placeholder {
			color: #fff !important;
			opacity: 1 !important;
		}
		&::-moz-placeholder {
			color: #fff !important;
			opacity: 1 !important;
		}
		&:-ms-input-placeholder {
			color: #fff !important;
			opacity: 1 !important;
		}
		&::-webkit-input-placeholder {
			color: #fff !important;
			opacity: 1 !important;
		}
	}

	input, select, textarea {
		border: 0px none !important;
		border-bottom: 1px solid #fff !important;

		&:focus, &:hover {
			border: 0px none !important;
			border-bottom: 1px solid #fff !important;
		}

	}

	button {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		width: 40px;
		height: 60px;
		text-align: left;
		margin: 0;
		background-color: transparent;
		@include font(25px, 30px);
		color: #fff;
		border: 0px none !important;
		outline: none !important;

		@media screen and (max-width: $screen-xs) {
			@include font(20px, 25px);
			height: 50px;
		}

		-webkit-appearance: none;
		-moz-appearance: none;

		&:before {
			transition-property: color;
			transition-duration: .2s;
		}

		&:hover {
			&:before {
				color: $brun;
			}
		}

		&:focus {
			outline: none !important;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		}

	}

}

#frm_form_8_container {
	position: relative;
	margin: -30px auto 10px auto;
	padding: 0 0;
	max-width: 780px;
	width: 100%;
	//height: 60px;

	//@media screen and (max-width: $screen-xs) {
	//	height: 120px;
	//}
}

#form_newsletter-form {
	margin: 0;
	padding: 0;
	width: 100%;
	//height: 60px;
	font-size: 16px;

	//@media screen and (max-width: $screen-xs) {
	//	height: 120px;
	//}

	.frm_message {
		position: absolute;
		bottom: 100%;
		left: 0;
		right: 0;
		margin: 0 0 20px 0 !important;
		padding: 1em 2em 1em 2em !important;
		@extend %alert;
		@extend %alert-success;
	}

	-webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);

	.frm_error_style {
		display: none;
	}
	fieldset {
		margin: 0 0px;
	}

	.form-field {
		margin: 0 !important;
		padding: 0 !important;
	}

	.frm_primary_label {
		display: none;
	}

	input:not([type="checkbox"]):not([type="radio"]), textarea, select {
		border: 0px none !important;
		background-color: transparent;
		font-family: ntt, sans-serif;
		font-size: 18px;
		line-height: 30px;
		color: darken($brun-f, 30%);
		padding: 15px 40px 15px 40px;
		width: calc(100% - 255px);
		//height: 60px;
		box-sizing: border-box;
		background-color: #ececec;

		&:-moz-placeholder {
			color: $gris-f !important;
			opacity: 1 !important;
		}
		&::-moz-placeholder {
			color: $gris-f !important;
			opacity: 1 !important;
		}
		&:-ms-input-placeholder {
			color: $gris-f !important;
			opacity: 1 !important;
		}
		&::-webkit-input-placeholder {
			color: $gris-f !important;
			opacity: 1 !important;
		}

		@media screen and (max-width: $screen-sm) {
			padding: 15px 20px 15px 20px !important;
		}

		@media screen and (max-width: $screen-xs) {
			width: 100%;
			font-size: 14px;
		}

	}

	input, select, textarea {
		border: 0px none !important;

		&:focus, &:hover {
			border: 0px none !important;
		}

	}

	.frm_opt_container {
		margin-top: 5px;
		@media screen and (max-width: $screen-xs) {
			margin-top: 10px;
			margin-bottom: 15px;
		}
		.frm_checkbox {
			label {
				user-select: none;
				color: $c_white;
				font-weight: normal;
			}

			& + .frm_error {
				position: relative;
				top: -10px;
			}
		}
	}

	.frm_submit {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		width: 255px;
		min-width: 180px;
		height: 60px;
		margin: 0 0 !important;

		@media screen and (max-width: $screen-xs) {
			width: 100% !important;
			max-width: 100% !important;
			position: relative;
		}
	}

	button {
		width: 255px !important;
		min-width: 180px;
		height: 60px;
		text-align: left;
		margin: 0;
		background-color: $brun-f;
		font-family: ntt, sans-serif;
		font-size: 16px !important;
		line-height: 18px !important;
		padding: 21px 70px 18px 20px !important;
		box-sizing: border-box;

		&:hover {
			background-color: darken($brun-f, 10%);
		}

		&:after {
			top: 25px !important;
		}

		@media screen and (max-width: $screen-xs) {
			width: 100% !important;
			max-width: 100% !important;
			position: relative;
		}

	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 50px;
		left: 40px;
		width: calc(100% - 40px - 40px - 255px);
		height: 1px;
		background-color: $brun-f;

		@media screen and (max-width: $screen-sm) {
			left: 20px;
			width: calc(100% - 20px - 20px - 255px);
		}

		@media screen and (max-width: $screen-xs) {
			//bottom: 73px;
			//width: calc(100% - 20px - 20px);
			display: none;
		}

	}

}