
.post {

  &.search-page {
    font-size: 16px;
    overflow: hidden;

    > .bloc {
      width: 100%; max-width: $max-width-little;
    }
  }

  &.single-post {
    font-size: 16px;
    overflow: hidden;

    > .bloc {
      width: 100%; max-width: $max-width-little;
    }

    hr {
      max-width: $max-width-little;
    }

    > .container {
      position: relative; z-index: 2;
      margin: $content-spacing auto;
      border-left: $content-spacing solid transparent;
      border-right: $content-spacing solid transparent;
      width: 100%; max-width: $max-width-little;
      box-sizing: border-box;

      @media screen and ( max-width: $screen-xs ) {
        margin: $mobile-spacing auto;
        border-left: $mobile-spacing solid transparent;
        border-right: $mobile-spacing solid transparent;
      }

      @extend %clearfix;

      .container-article {
        position: relative; z-index: 3;
        width: 65%; float: left;
        box-sizing: border-box;

        @media screen and ( max-width: $screen-md ) {
          width: 55%;
        }

        @media screen and ( max-width: $screen-sm ) {
          width: 100%;
        }

        > .bloc {
          border: 0px none !important;
        }

      }

      .container-sidebar {
        position: relative; z-index: 5;
        width: calc( 35% - 20px ); float: right;
        text-align: center;
        box-sizing: border-box;

        @media screen and ( max-width: $screen-md ) {
          width: calc( 45% - 20px );
        }

        @media screen and ( max-width: $screen-sm ) {
          margin-top: 0; width: 100%;
        }

        @media screen and ( max-width: $screen-xs ) {
          margin-top: 0; width: 100%;
        }


        .sticky-inner {
          text-align: left;

          @media screen and ( max-width: $screen-sm ) {
            top: 0px !important; width: 100% !important;
          }

        }

      }

    }
  }

  &.post-listing {
    position: relative; z-index: 1; display: block;
    overflow: hidden; font-size: 16px; text-align: left;
    margin: 0 0 0 0; padding: 0 0; max-width: 420px;

    @media screen and ( max-width: $screen-xs ) {
      max-width: 100%;
    }

    > * {
      position: relative;
      z-index: 1;
    }

    > a {
      display: block;
      border: 0px none;
      text-decoration: none;
    }

    figure {
      position: relative; z-index: 1;
      margin: 0 0 25px 0 !important; padding: 0 0;
      overflow: hidden; width: 100%; height: auto !important;

      transform: translate3d(0,0,0);
      -webkit-transform: translate3d(0,0,0);
      -webkit-transform-style: preserve-3d;

      img{
        transition-property: transform;
        transition-duration: 0.4s;
        min-height: 250px;

        transform: translate3d(0,0,0);
        -webkit-transform: translate3d(0,0,0);
        -webkit-transform-style: preserve-3d;


      }

      figcaption { display: none; }

    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      color: #000; font-weight: bold;
      font-family: ntt, sans-serif;
      @include font(20px, 18px);
      margin: 3px 0 5px 0;

      transition-property: color;
      transition-duration: 0.2s;
    }

    .tags {
      width: 100%; height: auto;
    }

    p {
      color: $gris;
      font-family: ntt, sans-serif;
      @include font(16px, 24px);
    }

    header {
      position: relative; z-index: 2;
      text-align: left; padding:  0;
    }

    footer {
      position: relative; z-index: 3;
    }

  }

}

html.desktop {

  .post {

    &.post-listing {

      &:hover {

        figure img {
          transform: scale(1.05) rotate(0.001deg) translateZ(1px);
        }

        .link {
          &:after { right: 12px; }
        }

      }

    }

  }

}

html.is-sticky-sidebar {

  #stickySidebar {
    z-index: 4;
  }

  #stickySidebar .sticky-inner {
    position: fixed; top: 0px;
  }

  &.ToBottom {

    #stickySidebar {
      position: absolute; bottom: 0px; right: 0;
    }

    #stickySidebar .sticky-inner {
      position: relative !important; top: auto !important;
    }

  }
}

.template-without-cover {
  margin-top: 20px;

  @media screen and ( max-width: $screen-xs ) {
    margin-top: 20px;
  }
}
