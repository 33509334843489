.bloc-slider-events {
  position: relative;
  margin: 0 0; padding: 0 0;
  width: 100%; max-width: 100%; font-size: 16px;
  background-color: #000; min-height: 200px;

  .owl-stage-outer, .owl-stage, .owl-item, .item, figure, figure > img {
    min-height: 600px !important;
  }

  .item {
    position: relative;
    display: block;
    text-decoration: none;

    @extend %clearfix;
    overflow: hidden;
    transform: translate3d(0,0,0);

    > figure {
      position: relative; z-index: 1;
      margin: 0px; padding: 0px;

      figcaption { display: none; }

      @media screen and ( max-width: $screen-xs ) {
        position: absolute; z-index: 1;
        top: 0; left: 0; right: 0; bottom: 0;

        img { height: 100% !important; }
      }
    }

    &:before{
      content: ''; display: block;
      position: absolute; z-index: 2; bottom: 0; left: 0;
      width: 100%; height: 70%; opacity: 1;
      background: url('../img/backend/shadow.png') repeat-x left bottom -5px;
      background-size: 100% 100% !important;

      -webkit-transform:translate3d(0,0,0);
      -webkit-backface-visibility: hidden;

      @media screen and ( max-width: $screen-xs ) {
        background: rgba(#000000,0.3); height: 100%;
        background-size: 100% 100% !important;
      }
    }

    figcaption { display: none; }

    .container {
      position: absolute; z-index: 3; text-align: left;
      bottom: 80px; left: 0; right: 130px;
      box-sizing: border-box; max-width: 650px;
      padding: $content-spacing 0 $content-spacing $content-spacing;

      -webkit-transform:translate3d(0,0,0);
      -webkit-backface-visibility: hidden;

      @media screen and ( max-width: $screen-xs ) {
        position: relative; z-index: 3; bottom: auto; left: auto; right: auto;
        padding: 120px $mobile-spacing $mobile-spacing + 50px $mobile-spacing;
      }

      .form_date {
        position: relative; z-index: 2;
        float: left; display: inline-block;
        margin: 0 25px 20px 0; text-transform: uppercase;

        @media screen and ( max-width: $screen-xs ) {
          margin: 0 15px 20px 0; display: block;
          float: none; clear: both;
        }

        .time {
          position: relative; z-index: 2;
          float: none; clear: both; margin: 0px 0; padding: 22px 10px 7px 10px;
          background-color: #000000;
          font-family: nttd, sans-serif;
          max-width: 75px; width: 75px; text-align: center;
          color: $brun-f; text-transform: uppercase;
          font-size: 14px; line-height: 14px;

          @extend %clearfix;

          @media screen and ( max-width: $screen-xs ) {
            max-width: 100%; width: 100%;
            background-color: rgba(#000000, 0.6);
          }


          > span {
            display: block; color: #fff;
            line-height: 20px; margin: 0 0 15px 0;

            > span { display: block; line-height: 16px; }
            strong { font-size: 30px; line-height: 30px; }
            .strong { font-size: 20px; line-height: 20px; }
            small { font-size: 13px; line-height: 13px; }
            .small { font-size: 11px; line-height: 11px; }
            .very-small { font-size: 10px; line-height: 11px; }
            sup { font-size: 13px; line-height: 13px; }
            strong, small { display: block; color: #fff; }
          }

          .half {
            width: 100%;

            @media screen and ( max-width: $screen-xs ) {
              width: 50%; float: left;
            }

            > span {
              display: block;
              color: #fff;
              line-height: 20px;
              margin: 0 0 15px 0;

              strong {
                font-size: 30px; line-height: 25px;
              }

              small {
                font-size: 13px; line-height: 13px;
              }

              .small {
                font-size: 11px; line-height: 18px;
              }

              sup { font-size: 13px; line-height: 13px; }

              strong, small {
                display: block;
                color: #fff;
              }
            }

          }

        }

        .icon-addCalendar {
          display: block; width: 75px; height: 50px; text-align: left;
          position: absolute; right: 0; bottom: 0; z-index: 3;
          color: #fff !important; text-decoration: none; border: 0px none;
          font-size: 25px !important; box-sizing: border-box;
          padding: 7px 20px;
          span { display: none; }
        }

      }

      .container-text {
        position: relative;
        padding-top: 40px;

        @media screen and ( max-width: $screen-xs ) {
          padding-top: 10px;
        }

        .current_event {
          width: 120px; position: absolute; top: 0; right: 0;
          display: inline-block;
          font-family: ntt, sans-serif;
          font-size: 12px !important; line-height: 12px !important;
          font-weight: bold;
          box-sizing: border-box;
          text-align: center;
          min-height: 30px;

          text-decoration: none;
          user-select: none;
          border: 0px none;

          margin: 0px 0px 10px 0px;
          padding: 10px 10px 7px 10px;

          vertical-align: middle;
          word-break: break-word;
          white-space: normal;
          text-transform: uppercase;

          -webkit-appearance: none;
          -moz-appearance: none;
          touch-action: manipulation;

          transition-property: all;
          transition-duration: 0.3s;

          transform: translate3d(0,0,0);
          -webkit-transform:translate3d(0,0,0);

          color: $brun;
          background-color: rgba(0,0,0,0.3);
          border: 1px solid $brun;

          @media screen and ( max-width: $screen-xs ) {
            position: relative;
            top: 0; left: 0; right: auto;
          }

        }
      }

      .subtitle {
        margin-top: -25px;
        color: #fff; padding-right: 150px;
        font-family: ntt, sans-serif;
        font-weight: bold;
        @include font(17px,18px);
        margin-bottom: 0px !important;

        @media screen and ( max-width: $screen-xs ) {
          margin-top: 0px;
        }
      }

      .author {
        margin: -5px 0 10px 0;
        display: block; color: #fff;
        font-family: nttd, sans-serif;
        @include font( 22px, 16px );
      }

       p { color: #fff; }

       h1, h2, h3, h4, h5, h6,
       .h1, .h2, .h3, .h4, .h5, .h6 {
         color: #fff;
       }

       .btn {
         margin-bottom: 0px !important;
         @media screen and ( max-width: $screen-xs ) {
           margin-top: 10px !important;
           margin-bottom: 0px !important;
         }
       }
    }

  }

  .owl-carousel {
    position: relative; z-index: 2;
  }

  .breadcrumb {
    position: absolute; bottom: 0; z-index: 3;
    margin: 40px auto; box-sizing: border-box;
    width: calc(100% - 150px);

    @media screen and ( max-width: $screen-xs ) {
      position: relative !important; bottom: 0 !important;
      width: 100% !important; margin: 20px auto 20px auto;
    }
  }

  .custom-controler {
    position: absolute; z-index: 4;
    bottom: 0px; right: 0;
    width: 150px;

    @media screen and ( max-width: $screen-xs ) {
      position: relative !important;
      height: auto !important;
      bottom: auto !important;
    }

    .owl-dots { display: none; }

    .owl-nav {
      position: absolute; bottom: 28px; right: 30px;
      width: 110px;

      .owl-data {
        font-family: ntt, sans-serif;
        font-size: 15px; box-sizing: border-box;
        color: #fff; width: 40px; display: inline-block;
        padding: 10px 0px; text-align: center;
      }

      @media screen and ( max-width: $screen-xs ) {
        position: absolute !important; bottom: 0px !important; right: auto !important;
        left: 0px !important;  width: 110px;
        margin: 10px 20px;
      }

      .owl-prev {
        position: relative;
        display: block;
        width: 35px !important;
        height: auto !important;
        box-sizing: border-box;
        background-color: transparent !important;
        margin: 0; padding: 15px 10px;

        &:before {
          position: relative !important;
          top: auto !important; left: auto !important;
          margin-top: 0px !important;
        }
      }

      .owl-next {
        position: relative;
        display: block;
        width: 35px !important;
        height: auto !important;
        box-sizing: border-box;
        background-color: transparent !important;
        margin: 0; padding: 15px 10px;

        &:before {
          position: relative !important;
          top: auto !important; left: auto !important;
          margin-top: 0px !important;
        }
      }

    }

  }

}