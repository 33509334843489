
#header {
	position: relative;
	z-index: 100;

	#navigation {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: transparent;

		transition-property: background;
		transition-duration: 0.4s;

		box-shadow: inset 0px 60px 95px -50px rgba(0, 0, 0, 1);
		-moz-box-shadow: inset 0px 60px 85px -50px rgba(0, 0, 0, 1);
		-webkit-box-shadow: inset 0px 60px 95px -50px rgba(0, 0, 0, 1);

		> .inner-container {
			position: relative;
			z-index: 1;
			margin: 0px auto 30px auto;
			padding: 0px $content-spacing;
			width: 100%;
			max-width: $max-width;
			box-sizing: border-box;
			@extend %clearfix;

			@media screen and (max-width: $screen-md) {
				margin: 0px auto 25px auto;
			}

			@media screen and (max-width: $screen-lg) {
				padding: 0px $mobile-spacing;
			}

			@media screen and (max-width: $screen-xs) {
				padding: 0px 10px;
			}

			.phone-box {
				position: absolute;
				z-index: 1;
				bottom: -17px;
				right: $content-spacing;
				text-align: left;
				width: 190px;
				text-decoration: none;
				display: block;
				box-sizing: border-box;

				@media screen and (max-width: $screen-lg) {
					right: $mobile-spacing;
				}

				font-family: ntt, sans-serif !important;
				font-weight: bold;
				@include font(16px, 18px);
				color: #fff;
				margin: 0px 0px;
				padding: 8px 10px 8px 10px;
				&:before {
					font-family: 'icomoon' !important;
					color: $brun;
				}

				&.icon-telephone {
					font-size: 18px;
					font-weight: bold;
					text-align: left;
					&:before {
						font-size: 12px;
						margin-right: 10px;
					}
				}

			}

		}

	}

	&.is-sticky, &.force-sticky {

		#navigation {
			background-color: #fff;

			-webkit-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
			-moz-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
			box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.3);

			.phone-box {
				color: $brun-f;
				&:before {
					color: $brun-f;
				}
			}

		}

	}

	&.force-sticky {
		min-height: 111px;
	}

}