#colophon {
	position: relative;
	z-index: 5;
	background-color: $gris-f;
	margin: 30px 0 0 0;
	padding: 1px 0 0 0;

	@extend %clearfix;

	// Grid System

	> .inner-container:nth-child(1) {
		position: relative;
		z-index: 1;
		margin: 0px auto;
		padding: 1px 0 0 0;
		width: 100%;
		max-width: $max-width;
		box-sizing: border-box;
		padding: 0px $content-spacing;

		@extend %clearfix;

		@media screen and (max-width: $screen-xs) {
			padding: 0px $mobile-spacing;
		}

	}

	> .inner-container:nth-child(2) {
		position: relative;
		z-index: 1;
		padding: 1px 0 0 0;
		width: 100%;
		box-sizing: border-box;
		background-color: #fff;

		@extend %clearfix;

	}

}