
ul.infotab {
  margin: 15px 0 40px 0; padding: 0;
  text-align: left; font-size: 0;

  > li {
    font-size: 14px; line-height: 14px;
    position: relative; text-align: left; padding: 18px 20px 15px 20px !important;
    display: inline-block !important; width: 50%;
    background-color: #fff; color: #000;
    border-bottom: 1px solid #ececec;
    box-sizing: border-box;

    transition-property: background;
    transition-duration: .3s;

    &:before { display: none; }

    &:nth-child(4n+1), &:nth-child(4n+2) {
      background-color: #ececec;
    }

    &:nth-child(even) {
      border-left: 1px solid #ffffff;
    }

    a {
      display: block; border: 0px none; text-decoration: none;
      color: #000;
    }

    @media screen and ( min-width: $screen-sm ) and ( max-width: $screen-md ){
      width: 100% !important;
      background-color: #fff !important;
      border-left: 0px none;

      &:nth-child(odd) {
        background-color: #ececec !important;
      }

    }

    @media screen and ( max-width: $screen-xs ){
      width: 100% !important;
      background-color: #fff !important;
      border-left: 0px none;

      &:nth-child(odd) {
        background-color: #ececec !important;
      }
    }

  }

  &.full li {
    position: relative;
    width: 100% !important;
    background-color: #fff !important;
    border-left: 0px none;
    padding: 0px !important;

    a {
      padding: 18px 150px 15px 20px !important;
    }

    .filesize {
      position: absolute; top: 0; right: 0;
      width: 130px; padding: 16px 20px 13px 0px !important;
      box-sizing: border-box; text-align: right;
      color: $brun-f;

      > span {
        display: inline-block;
        vertical-align: top; padding-top: 3px;
      }

      > span[class^="icon-"], > span[class*=" icon-"] {
        position: relative; z-index: 1;
        padding: 0px 0px 0px 0px !important;
        margin-left: 20px; font-size: 16px;
      }
    }

    &:nth-child(odd) {
      background-color: #ececec !important;
    }

    &:hover {
      background-color: #f5f5f5 !important;
    }
  }

}

ul.plus-menu {
  margin: 0px 0 20px 0;
  li:before {
    color: #000;
    top: 8px;
  }

  li {
    display: inline-block;
    color: #000; width: 50%;
    box-sizing: border-box;
    padding-right: 50px;
    font-size: 14px;

    @media screen and ( min-width: $screen-sm ) and ( max-width: $screen-md ){
      width: 100% !important;
    }

    @media screen and ( max-width: $screen-xs ){
      width: 100% !important;
    }

  }
}
