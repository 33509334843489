.bloc-cols {
  text-align: center;
  margin: -20px auto $content-spacing auto; padding: 0 0;
  border-left: $content-spacing - 10px solid transparent;
  border-right: $content-spacing - 10px solid transparent;

  @media screen and ( max-width: $screen-xs ) {
    margin: -20px auto $mobile-spacing auto;
    border-left: $mobile-spacing - 10px solid transparent;
    border-right: $mobile-spacing - 10px solid transparent;
  }

  .container {
    @extend %clearfix;
    padding-left: 1%; margin: 0px -1%;
    text-align: left; font-size: 0;
    overflow: hidden;

    .item {
      font-size: 16px;
      position: relative; z-index: 1;
      width: 33%; vertical-align: top;
      display: inline-block;
      border: 10px solid transparent;
      border-top-width: 20px;
      border-bottom: 0px none;
      box-sizing: border-box;
      text-decoration: none;
      text-align: left;

      transition-property: background;
      transition-duration: 0.3s;

      > a {
        position: relative; z-index: 2;
        display: block;
        border: 0px none;
        text-decoration: none;

        &:hover {
          figure img {
            transform: scale(1.05) rotate(0.001deg) translateZ(1px);
          }
        }
      }

      figure {
        margin: 0px auto;

        img{
          transition-property: transform;
          transition-duration: 0.4s;
        }
      }

      h2, h3, h4 {
        color: $brun-f; @include font( 16px, 18px );
        display: block; text-align: center;
        width: 100%; padding: 0px 15px; margin: 15px 0 15px 0;
        box-sizing: border-box;
      }

      p {
        color: $gris;
      }


    }

    @media screen and ( max-width: $screen-sm ) {
      padding-left: 0px; margin: 0px auto 0px auto;

      .item {
        width: 50%;
      }

    }

    @media screen and ( max-width: 550px ) {
      padding-left: 0px; margin: 0px auto 0px auto;

      .item {
        width: 100%;
      }

    }

  }

}