
.left-text {
  text-align: left;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}


figure {
  position: relative; z-index: 1;
  background-color: #000;
  margin: $mobile-spacing auto;
  box-sizing: border-box;
  overflow: hidden;

  -webkit-transform: translate3d(0,0,0);
  -webkit-backface-visibility: hidden;

  @media screen and ( max-width: $screen-xs ) {
    margin: $mobile-spacing auto;
  }

  &.op-10 img {  opacity: 0.1;   }
  &.op-20 img {  opacity: 0.2;   }
  &.op-25 img {  opacity: 0.25;  }
  &.op-30 img {  opacity: 0.3;   }
  &.op-40 img {  opacity: 0.4;   }
  &.op-50 img {  opacity: 0.5;   }
  &.op-60 img {  opacity: 0.6;   }
  &.op-70 img {  opacity: 0.7;   }
  &.op-75 img {  opacity: 0.75;  }
  &.op-80 img {  opacity: 0.8;   }
  &.op-90 img {  opacity: 0.9;   }

  img {
    vertical-align: top;
    width: 101%; height: auto;
    transition: opacity;
    transition-duration: 0.3s;

  }

  &.object-fit {
    width: 100%; height: 100%; min-height: 100%;
    overflow: hidden;

    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);

    img {
      width: 101%; height: 101%;

      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;';

    }

  }

  &.vignette {
    margin: 0px auto !important;
  }

  figcaption {
    position: absolute; bottom: 0; left: 0;
    font-family: ntt, sans-serif;
    font-size: 14px; color: #fff !important;
    padding: 40px 20px 10px 20px; width: 100%;
    box-sizing: border-box;
    text-align: left !important;

    background: url('../img/backend/shadow.png') repeat-x center bottom -1px;
    background-size: 100% 100%;
  }

  &.right {
   figcaption {
      direction: rtl;
      left: auto; right: 0px;
      text-align: right !important;

       > * {
         direction: ltr;
         text-align: right !important;
       }

    }

  }

}


hr {
  height: 1px; margin: $content-spacing auto;
  border-top: 0px none; border-bottom: 0px none; background-color: #cccccc;
  width: 100%; max-width: $max-width;

  border-left: $content-spacing solid #fff;
  border-right: $content-spacing solid #fff;

  @extend %clearfix;
  box-sizing: border-box;

  @media screen and ( max-width: $screen-xs ) {

    margin: $mobile-spacing auto;

    border-left: $mobile-spacing solid #fff;
    border-right: $mobile-spacing solid #fff;

  }

  &.clear-both {
    float: none; clear: both;
    height: 1px; width: 100%; max-width: 100% !important;
    border: 0px none !important; margin: 0px auto !important;
    background-color: #cccccc;
  }

  &.clear-both-empty {
    float: none; clear: both;
    height: 1px; width: 100%; max-width: 100% !important;
    border: 0px none !important; margin: 0px auto !important;
    background-color: transparent;
    border-left: $content-spacing solid transparent;
    border-right: $content-spacing solid transparent;
  }

  &.full {
    float: none; clear: both;
    height: 1px; width: 100%; max-width: 100% !important;
    border: 0px none !important; margin: 30px auto !important;
    background-color: #eeeeee;
  }

}

  // -- Owl carousel -- //

  .owl-controls { display: none; }

  .custom-controler, .custom-controler-dots {
    display: block; position: relative;
    @extend %clearfix;

    .owl-nav {
      position: relative; z-index: 3;
      display: block; width: 100%;
      margin: 0; padding: 0;

      @extend %clearfix;

      .owl-prev {
        position: relative;
        display: block; width: $content-spacing + 1; height: 200px; max-height: 200px;
        float: left; cursor: pointer; background-color: #000;
        margin: 0; padding: 0;

        -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
        -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
        outline: none !important;

        transition-property: background;
        transition-duration: 0.3s;

        &:before {
          position: absolute; top: 50%; left: 0;
          margin-top: -5px;

          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 11px;
          visibility: visible;
          cursor: pointer;

          width: 100%; display: block;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          content: "\e906"; color: #fff;
          font-size: 11px;

          -moz-transform: rotate(-180deg);  /* FF3.5/3.6 */
          -o-transform: rotate(-180deg);  /* Opera 10.5 */
          -webkit-transform: rotate(-180deg);  /* Saf3.1+ */
          transform: rotate(-180deg);  /* Newer browsers (incl IE9) */

          text-align: center;
          transition-property: color;
          transition-duration: 0.3s;

        }

        &:hover{
          background-color: #333;
        }

      }

      .owl-next {
        position: relative;
        display: block; width: $content-spacing + 1; height: 200px;  max-height: 200px;
        float: right; cursor: pointer; background-color: #000;
        margin: 0; padding: 0;

        -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
        -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
        outline: none !important;

        transition-property: background;
        transition-duration: 0.3s;

        &:before {
          position: absolute; top: 50%; left: 0;
          margin-top: -5px;

          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 11px;
          visibility: visible;
          cursor: pointer;

          width: 100%; display: block;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          content: "\e906"; color: #fff;
          font-size: 11px;

          text-align: center;
          transition-property: color;
          transition-duration: 0.3s;

        }

        &:hover{
          background-color: #333;
        }

      }

    }

  }


// -- Ratio -- //

.ratio_16-9 {
  position: relative;
  > div, > figure, > iframe, > img, > a { position:  absolute; top: 0; left: 0; bottom: 0; right: 0; }
  iframe { width: 100%; height: 100%; }
  &:before{  content: ""; display: block;  padding-top: 56.25%; }
}

.ratio_16-10 {
  position: relative;
  > div, > figure, > iframe, > img, > a { position:  absolute; top: 0; left: 0; bottom: 0; right: 0; }
  iframe { width: 100%; height: 100%; }
  &:before{  content: ""; display: block;  padding-top: 62.45%; }
}

.ratio_4-3 {
  position: relative;
  > div, > figure, > iframe, > img, > a { position:  absolute; top: 0; left: 0; bottom: 0; right: 0; }
  iframe { width: 100%; height: 100%; }
  &:before{  content: ""; display: block;  padding-top: 75%; }
}

.ratio_1-1 {
  position: relative;
  > div, > figure, > iframe, > img, > a { position:  absolute; top: 0; left: 0; bottom: 0; right: 0; }
  iframe { width: 100%; height: 100%; }
  &:before{  content: ""; display: block;  padding-top: 100%; }
}


// -- Overlay -- //

#overlay, #overlay-mobile-nav {
  background-color: rgba(0,0,0,0.8); position:fixed;
  width: 100%; z-index: 99999;
  height: 0px; top:0; left:0;
  opacity: 0; visibility: hidden;
  overflow: hidden; padding: 100px 20px 40px 20px;
  box-sizing: border-box;

  @media screen and ( max-width: $screen-xs ) {
    padding: 75px 10px 20px 10px;
  }

  transition-property: opacity !important;
  transition-duration: 0.3s;

  &.opened {
    opacity: 1; visibility: visible; height: 100%;
  }

  .overlay_form_wrapper {
    display: none;
    width: 100%; max-width: $max-width-little;
    margin: 0 auto; height: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    .frm_forms {
      background-color: #fff;
      padding: $content-spacing $content-spacing;

      @media screen and ( max-width: $screen-xs ) {
        padding: $mobile-spacing $mobile-spacing;
      }
    }
  }

}

body.get-mobile-menu.ytm-open {
  #overlay-mobile-nav {
    opacity: 1; visibility: visible; height: 100%;

    .overlay_menu_wrapper {
      height: 100%; box-sizing: border-box;
      overflow-y: auto;
    }

  }
}

@-webkit-keyframes ballAnim {
  0% { opacity:0; bottom: 25px;}
  25% { opacity:0.3;}
  50% { opacity:1; bottom: 13px;}
  75% { opacity:0.3;}
  100% { opacity:0; bottom: 5px;}
}
@-moz-keyframes ballAnim {
  0% { opacity:0; bottom: 25px;}
  25% { opacity:0.3;}
  50% { opacity:1; bottom: 13px;}
  75% { opacity:0.3;}
  100% { opacity:0; bottom: 5px;}
}
@-o-keyframes ballAnim {
  0% { opacity:0; bottom: 25px;}
  25% { opacity:0.3;}
  50% { opacity:1; bottom: 13px;}
  75% { opacity:0.3;}
  100% { opacity:0; bottom: 5px;}
}
@keyframes ballAnim {
  0% { opacity:0; bottom: 25px;}
  25% { opacity:0.3;}
  50% { opacity:1; bottom: 13px;}
  75% { opacity:0.3;}
  100% { opacity:0; bottom: 5px;}
}