
.post {

  &.single-room {
    font-size: 16px;
    overflow: hidden;

    > .container {
      position: relative; z-index: 2;
      margin: 0px auto;
      border-left: $content-spacing solid transparent;
      border-right: $content-spacing solid transparent;
      width: 100%; max-width: $max-width;
      box-sizing: border-box;

      @media screen and ( max-width: $screen-xs ) {
        margin: 0px auto ;
        border-left: $mobile-spacing solid transparent;
        border-right: $mobile-spacing solid transparent;
      }

      @extend %clearfix;

      .tab-title {
        margin-top: 30px;
        text-transform: uppercase;
        @include font( 20px, 18px  );
        color: $brun;
      }

      .plus-title {
        margin-top: 30px; margin-bottom: 5px;
        text-transform: uppercase;
        @include font( 30px, 18px  );

        @media screen and ( max-width: $screen-md ) { @include font( 25px, 18px ); }
        @media screen and ( max-width: $screen-xs ) { @include font( 20px, 18px ); }
      }

      .large_fix {
        max-width: 550px;
      }

      .data-info {
        color: #fff;
        h4 {
          margin-top: 5px;
          margin-bottom: 0px;
        }
        p { color: #fff; }

        ul {
          padding: 0px 0px;
          margin: 6px 0 15px;
          li {
            color: #fff; padding: 0px 0px;
            &:before { display: none; }
          }
        }

        .per-six {
          position: relative;
          float: left;
          display: inline-block;
          width: 15%;
          margin: 22px 0 10px 0;

          @media screen and ( max-width: $screen-xs ) {
            width: 25%;
          }

          > span {
            display: block;
            text-align: center;
            color: #fff;
            strong { color: #fff; }
          }

          [class^="icon-"], [class*=" icon-"] {
            color: #fff;
            font-size: 27px;
            line-height: 27px;
            height: 30px;
            margin-bottom: 7px;
          }
        }

        .per-three {
          position: relative;
          float: left;
          display: inline-block;
          width: 33.33%;
          margin: 15px 0;

          &.large {
            width: 35%; float: right;
            padding: 0 10px;
            box-sizing: border-box;
            border-left: 1px solid #373737;

            @media screen and ( max-width: $screen-xs ) {
              width: 100%; padding: 10px 10px 0 10px;
              border-left: 0px solid #373737;
              border-top: 1px solid #373737;
              font-weight: bold;
            }

          }

          > span {
            display: block;
            text-align: center;
            color: $brun;
            strong { color: #fff; }
          }

          .text {
            display: inline-block; text-align: center;
            font-size: 14px; line-height: 14px;
            color: #fff;
          }

          [class^="icon-"], [class*=" icon-"] {
            color: $brun;
            font-size: 27px;
            line-height: 27px;
            margin-bottom: 15px;
          }

        }

        .per-two {
          position: relative;
          float: left;
          display: inline-block;
          width: 50%;
          margin: 15px 0;

          > span {
            display: block;
            text-align: center;
            color: #fff;
            strong { color: #fff; }
          }

          .text {
            display: block; text-align: center;
            font-size: 14px; line-height: 14px;
            margin-bottom: 10px;
            color: #fff;
          }

          [class^="icon-"], [class*=" icon-"] {
            color: #fff;
            font-size: 27px;
            line-height: 27px;
            margin-bottom: 15px;
          }

        }


        hr.full {
          background-color: #373737;
          margin: 20px -40px !important;
          width: calc( 100% + 80px );
          max-width: none !important;

          @media screen and ( min-width: $screen-sm ) and ( max-width: $screen-lg ){
            margin: 20px -20px !important;
            width: calc( 100% + 40px );
          }

          @media screen and ( max-width: $screen-xs ) {
            margin: 20px -20px !important;
            width: calc( 100% + 40px );
          }

        }
      }

      .container-article {
        position: relative; z-index: 3;
        width: calc( 65% - 20px ); float: left;
        box-sizing: border-box;

        @media screen and ( max-width: $screen-md ) {
          width: calc( 55% - 20px );
        }

        @media screen and ( max-width: $screen-sm ) {
          width: 100%;
        }

        > .bloc {
          border: 0px none !important;
          margin-bottom: 0px !important;
        }

        .single-header {
          margin: 0px -120px 40px -40px;

          @media screen and ( max-width: $screen-sm ) {
            margin: 0px -40px 40px -40px;
          }

          @media screen and ( max-width: $screen-xs ) {
            margin: 0px -20px 40px -20px;
          }
        }

      }

      .container-sidebar {
        position: relative; z-index: 5; padding-top: $content-spacing;
        width: calc( 35% - 20px ); float: right;
        text-align: center;
        box-sizing: border-box;

        @media screen and ( max-width: $screen-md ) {
          width: calc( 45% - 20px );
        }

        @media screen and ( max-width: $screen-sm ) {
          margin-top: 0; width: 100%; padding-top: 0px;
        }

        @media screen and ( max-width: $screen-xs ) {
          margin-top: 0; width: 100%; padding-top: 0px;
        }


        .sticky-inner {
          text-align: left;

          @media screen and ( max-width: $screen-sm ) {
            top: 0px !important; width: 100% !important;
            margin-top: $mobile-spacing;
          }

          .data-info {
            text-align: left;
            background-color: #000; color: #fff;
            padding: 35px $content-spacing;

            @media screen and ( min-width: $screen-sm ) and ( max-width: $screen-lg ){
              padding: 25px $mobile-spacing 15px $mobile-spacing;
            }

            @media screen and ( max-width: $screen-xs ){
              padding: 25px $mobile-spacing 15px $mobile-spacing;
            }

            .infobull {
              position: relative; z-index: 2;

              -webkit-transform:translate3d(0,0,0);
              -webkit-backface-visibility: hidden;

              > * { display: block; }

              .pop-up-box {
                position: absolute;
                bottom: 100%; left: -29px;
                padding: 10px 10px 8px 10px; width: 100px;
                border-radius: 4px;
                opacity: 0; box-sizing: border-box;
                pointer-events: none;
                text-align: center;
                visibility: hidden;
                color: $brun-f; font-weight: bold;
                background: rgba(#ffffff, 0.95);
                box-shadow: 0 0 14px 0 rgba(0,0,0,0.3), 0 0 4px 0 rgba(0,0,0,0.2);

                transform: translate(0,15px);
                transform: translate3d(0,15px,0);
                transition: all .3s ease;

                &:after {
                  content: '';
                  position: absolute;
                  left: 50%;
                  bottom: -7px; margin-left: -4px;
                  width: 0; height: 0;
                  border-left: 7px solid transparent;
                  border-right: 7px solid transparent;
                  border-top: 7px solid rgba(#ffffff, 0.95);
                }

              }

              &:hover, &:focus {

                .pop-up-box {
                  opacity: 1; visibility: visible;
                  transform: translate(0,-15px);
                  transform: translate3d(0,-15px,0);
                }

              }
            }

          }


        }

      }

    }
  }

  &.rooms-listing {
    position: relative; z-index: 1; display: block;
    font-size: 16px; text-align: left;
    margin: 0 0 0 0; padding: 0 0; max-width: 450px;

    &.decalmap {
      .infobull .pop-up-box {
        left: auto !important; right: 10px; width: 220px; height: 120px;
        &:after {
          left: auto !important; right: 10px;
          margin-left: -4px;
        }

      }
    }

    .calendar {
      position: absolute; z-index: 2; top: 0; right: 0;
      width: 70px; height: 70px; text-decoration: none;
      display: block; color: #fff; font-size: 25px;
      padding: 20px 20px; box-sizing: border-box;
      span { display: none; }
    }

    .infobull {
      position: relative; z-index: 2; margin-top: -85px;
      width: 55px; height: 60px; text-decoration: none;
      display: block; color: #fff; font-size: 20px;
      padding: 20px 20px; box-sizing: border-box;
      cursor: pointer; float: right;

      -webkit-transform:translate3d(0,0,0);
      -webkit-backface-visibility: hidden;

      transition-property: color;
      transition-duration: 0.4s;

      .pop-up-box {
        position: absolute;
        bottom: 0;
        left: -105px;
        padding: 10px;
        width: 250px; height: 140px;
        border-radius: 4px;
        opacity: 0;
        pointer-events: none;
        text-align: center;
        visibility: hidden;
        background: #ffffff;
        box-shadow: 0 0 14px 0 rgba(0,0,0,0.3), 0 0 4px 0 rgba(0,0,0,0.2);

        transform: translate(0,-25px);
        transform: translate3d(0,-25px, 0);
        transition: all .3s ease;

        figure {
          background-color: #fff !important;
        }

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -7px; margin-left: -4px;
          width: 0; height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 7px solid #fff;
        }

      }

      &:hover, &:focus {
        color: $brun;

        .pop-up-box {
          opacity: 1; visibility: visible;
          transform:translate(0,-55px);
          transform:translate3d(0,-55px,0);
        }

      }
    }

    > * {
      position: relative;
      z-index: 1;
    }

    .fixTo {
      transform: translate3d(0,0,0);
      -webkit-transform-style: preserve-3d;
      -webkit-transform: translate3d(0,0,0);

      > figure {
        position: relative; z-index: 1;
        margin: 0 0 25px 0 !important; padding: 0 0;
        overflow: hidden; width: 100%; height: auto !important;

        transform: translate3d(0,0,0);
        -webkit-transform-style: preserve-3d;
        -webkit-transform: translate3d(0,0,0);

        > img{
          transition-property: transform;
          transition-duration: 0.4s;
          min-height: 250px;

          transform: translate3d(0,0,0);
          -webkit-transform-style: preserve-3d;
          -webkit-transform: translate3d(0,0,0);

        }

        figure {
          position: relative; z-index: 1;
          margin: 0 0; padding: 0 0;
          overflow: hidden; width: 100%; height: auto !important;

          img{
            transition-property: transform;
            transition-duration: 0.4s;
          }

          figcaption { display: none; }

        }

        figcaption { display: none; }

      }

    }

    > a {
      display: block;
      border: 0px none;
      text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      color: #000; font-weight: bold;
      font-family: ntt, sans-serif;
      @include font(20px, 20px);
      margin: 2px 0 0px 0;

      transition-property: color;
      transition-duration: 0.2s;
    }

    .tags {
      width: 100%; height: auto;
    }

    .subtitle {
      font-family: ntt, sans-serif;
      font-weight: normal; display: inline-block; vertical-align: top;
      text-transform: none !important;
      margin-bottom: 2px !important;
    }

    p {
      color: $gris;
      font-family: ntt, sans-serif;
      @include font(16px, 24px);
    }

    header {
      position: relative; z-index: 2;
      text-align: left; padding: 0px 0 0px 0;
    }

    footer {
      position: relative; z-index: 3;
    }

    @media screen and ( max-width: $screen-xs ) {
      max-width: 100%;
    }

  }

  &.room-listing {
    position: relative; z-index: 1; display: block;
    font-size: 16px; text-align: left;
    margin: 0 0 20px 0; padding: 0 0;

    display: block;
    border: 0px none;
    text-decoration: none;

    @extend %clearfix;

    a { text-decoration: none;
      border: 0px none;}

    > * {
      position: relative;
      z-index: 1;
    }

    &:before {
      position: absolute; top: -20px; left: -20px; bottom: -20px; right: -20px;
      display: block; content: "";
      background-color: #fff; opacity: 0;

      @media screen and ( max-width: $screen-xs ) {
        opacity: 0 !important;
      }

      transition-property: opacity;
      transition-duration: 0.4s;

      -moz-box-shadow: 0px 0px 53px -20px rgba(0,0,0,1);
      -webkit-box-shadow: 0px 0px 53px -20px rgba(0,0,0,1);
      box-shadow: 0px 0px 53px -20px rgba(0,0,0,1);
    }

    @-moz-document url-prefix() {
      &:before {
        -moz-box-shadow: 0px 0px 40px -25px rgba(0,0,0,1);
        -webkit-box-shadow: 0px 0px 40px -25px rgba(0,0,0,1);
        box-shadow: 0px 0px 40px -25px rgba(0,0,0,1);
      }
    }

    .img-wrapper {
      position: absolute; z-index: 1; top: 0; left: 0;
      margin: 0 0; padding: 0 0; float: left;
      overflow: hidden; width: 43%; height: 100% !important;

      @media screen and ( max-width: $screen-xs ) {
        width: 100%; position: relative; height: 180px !important;
        margin-bottom: 10px;
      }

      figure {
        margin: 0 0; padding: 0 0;
        transform: translate3d(0,0,0);

        .dataimg {
          display: block; position: absolute; z-index: 3;
          bottom: 10px; right: 10px;
          font-family: ntt, sans-serif;
          @include font(13px,15px);
          font-weight: bold; color: #fff;

          .icon-superficie {
            display: inline-block; margin-right: 10px;
            font-size: 16px; vertical-align: middle;
          }
        }

        img {
          position: relative; z-index: 2;
          transform: translate3d(0,0,0);
          transition-property: transform;
          transition-duration: 0.4s;
        }

        figcaption { display: none; }

      }

    }

    .container {
      box-sizing: border-box;
      margin-left: calc( 43% + 20px );

      @media screen and ( max-width: $screen-xs ) {
        margin-left: 0px;
      }

      .subtitle {
        position: relative; display: block;
        font-family: ntt, sans-serif;
        @include font( 14px, 16px ); font-weight: normal;
        color: $gris; text-transform: none;
        width: 100%; text-align: left;
        margin: 0px 0 10px 0;
      }

      .tags {
        display: block;
        color: $gris;
        margin: 10px 0 10px 0;
      }

      h1, h2, h3, h4, h5, h6,
      .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $brun; font-weight: bold;
        font-family: ntt, sans-serif;
        @include font(20px, 20px);
        margin: 0px 0px;
        display: block;
        background-color: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 0px 0px;

        transition-property: color;
        transition-duration: 0.2s;
      }

      p {
        color: $gris;
        font-family: ntt, sans-serif;
        @include font(15.5px, 22px);
      }

      .link {
        padding: 10px 65px 0px 0;

        @media screen and ( max-width: $screen-xs ) {
          &:before { right: 20px !important; }
        }
      }

    }

  }

}

html.desktop {

  .post {

    &.rooms-listing {

      &:hover {

        figure img {
          transform: scale(1.05) rotate(0.001deg) translateZ(1px);
        }

        .link {
          &:after { right: 12px; }
        }

      }

    }


    &.room-listing {

      &:hover {
        z-index: 2;

        &:before {
          opacity: 1;
        }

        .link {
          &:after { right: 12px; }
        }

      }

    }

  }

}