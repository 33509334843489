.bloc-navigation-event {
  position: relative; z-index: 99;
  box-sizing: border-box;

  opacity: 1;
  transition-property: opacity;
  transition-duration: 1s;

  &.noShow {
    opacity: 0;
  }

  @media screen and ( max-width: $screen-xs ) {
    margin: $mobile-spacing 0;
    opacity: 1 !important;
  }

  @extend %clearfix;

  .prev-link, .next-link {
    position: fixed; z-index: 10; display: block; top: 50%; left: 0px;
    text-decoration: none; overflow: hidden;
    width: 70px; height: 200px; text-align: right;
    margin-top: -100px; background-color: $brun;
    box-sizing: border-box;

    @media screen and ( max-width: $screen-lg ) {
      left: -35px;
    }

    -webkit-transform:translate3d(0,0,0);
    -webkit-backface-visibility: hidden;

    transition: background, left, right;
    transition-duration: 0.3s;

    @media screen and ( max-width: 450px ) {
      .icon-littleArrow { width: 30px !important; }
      .text { padding: 20px 15px 18px 15px !important; font-size: 80% !important; width: calc(100% - 31px) !important; }
    }

    &:hover {
      background-color: darken($brun,5%);
      left: 0px;

      .icon-littleArrow { width: 100%; line-height: 63px !important; height: 60px !important; }
      .text { opacity: 1; }
    }

    .text{
      position: relative; display: inline-block; vertical-align: top;
      width: 140px; height: 70px; padding: 20px 17px;
      margin: 0 0 0 -70px;
      color: #fff; text-align: left;

      @media screen and ( max-width: $screen-lg ) {
        opacity: 0;
      }

      transition: opacity;
      transition-duration: 0.3s;
      transition-delay: .2s;

      transform: rotate(90deg) translateX(100%);
      transform-origin: 100% 0%;

      box-sizing: border-box;

      font-family: ntt, sans-serif;
      font-weight: bold; text-transform: uppercase;
      @include font( 16px, 17px);

    }

    .icon-littleArrow {
      position: relative; display: inline-block; text-align: center;
      vertical-align: top; border-bottom: 1px solid $brun-f;
      width: 70px; padding: 0px 0px; height: 60px;
      font-size: 11px; line-height: 63px !important; color: #fff;

      @media screen and ( max-width: $screen-lg ) {
        line-height: 200px !important; height: 200px !important;
        width: 35px;
      }

      transition: all;
      transition-duration: 0.3s;

    }

    @media screen and ( max-width: $screen-xs ) {
      position: relative !important;
      margin-top: 0px !important;
      width: 50% !important; height: 60px !important;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      left: 0px;

      .text{
        transform: rotate(0deg) translateX(0%) translateY(0%) !important;
        margin: 0 0 !important; padding: 16px 15px 14px 15px !important;
        height: 60px !important; width: calc(100% - 41px) !important;
        opacity: 1;
      }

      .icon-littleArrow {
        width: 40px !important; height: 60px !important; line-height: 63px !important;
        border-bottom: 0px none !important;
        border-top: 0px none !important;
        border-left: 1px solid $brun-f !important;
      }
    }

  }

  .prev-link {

    .text {
      transform: rotate(-90deg) translateY(-100%);
      transform-origin: 100% 0%;
    }

    .icon-littleArrow {
      -moz-transform: rotate(-180deg);  /* FF3.5/3.6 */
      -o-transform: rotate(-180deg);  /* Opera 10.5 */
      -webkit-transform: rotate(-180deg);  /* Saf3.1+ */
      transform: rotate(-180deg);  /* Newer browsers (incl IE9) */

      font-size: 11px; line-height: 60px;

      border-bottom: 0px solid $brun-f;
      border-top: 1px solid $brun-f;
    }

  }

  .next-link {
    left: auto; right: 0px; text-align: left;

    @media screen and ( max-width: $screen-lg ) {
      right: -35px;
    }

    @media screen and ( max-width: $screen-xs ) {
      right: 0px;
    }

    &:hover { left: auto !important; right: 0px !important; }

  }

  @media screen and ( max-width: $screen-xs ) {
    .prev-link {
      float: left !important;
    }

    .next-link {
      float: right !important;
      .icon-littleArrow { float: right !important; }
      .text { float: left !important; text-align: right !important; }
    }
  }

}

html.touchscreen {

  .bloc-navigation-event {
    position: relative !important;
    margin: $mobile-spacing 0 !important;

    .prev-link, .next-link {
      position: relative !important;
      margin-top: 0px !important;
      width: 50% !important; height: auto !important;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      left: 0px;

      .text{
        transform: rotate(0deg) translateX(0%) translateY(0%) !important;
        margin: 0 0 !important; padding: 16px 15px 14px 15px !important;
        height: 60px !important; width: calc(100% - 41px) !important;
        opacity: 1;
      }

      .icon-littleArrow {
        width: 40px !important; height: 60px !important; line-height: 63px !important;
        border-bottom: 0px none !important;
        border-top: 0px none !important;
        border-left: 1px solid $brun-f !important;
      }
    }

    .prev-link {
      float: left !important;
      left: 0px;
    }

    .next-link {
      right: 0px;
      float: right !important;
      .icon-littleArrow { float: right !important; }
      .text { float: left !important; text-align: right !important; }
    }

  }

  @media screen and ( max-width: 450px ) {
    .icon-littleArrow { width: 30px !important; }
    .text { padding: 20px 15px 18px 15px !important; font-size: 80% !important; width: calc(100% - 31px) !important; }
  }

}

.bloc-pagination-search {
  position: relative; text-align: center;
  overflow: hidden;
  width: 100%; border-radius: 2px;
  color: $brun-f; font-size: 16px;
  line-height: 1.25em;  min-height: 51px;

  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  padding: 15px 200px 10px 200px;
  box-sizing: border-box;

  @media screen and ( max-width: $screen-xs ) {
  padding: 10px 0px;
}

  .page-numbers {
    display: inline-block;
    padding: 0px 5px;
    font-size: 1.1em;

    @media screen and ( max-width: $screen-xs ) {
      padding: 5px 15px;
    }

    &.current {
      font-weight: bold;
    }
  }

  a {
    margin: 0px 0px;
    text-decoration: none;
    color: #000;
    border-bottom: 0px none !important;

    &.prev, &.next {
      font-size: 15px; position: absolute; top: 0; bottom: 0;
      width: 190px; box-sizing: border-box;
      padding: 5px 20px 5px 20px; margin: 0 -1px; text-align: center;
      display: inline-block; background-color: $brun;
      box-sizing: border-box; color: #fff;

      font-family: ntt, sans-serif;
      font-weight: bold; text-transform: uppercase;
      @include font( 14px, 17px);

      transition: background;
      transition-duration: 0.3s;

      span { display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

    }

    &:hover {
      &.prev, &.next { background: darken($brun,10%); }
    }

    &.prev {
      left: 0;

      @media screen and ( max-width: $screen-xs ) {
        position: relative; margin: -10px 0 10px 0;
        width: 100%; bottom: auto; top: auto; height: 50px;
      }

    }

    &.next {
      right: 0;

      @media screen and ( max-width: $screen-xs ) {
        position: relative; margin: 10px 0 -10px 0;
        width: 100%; bottom: auto; top: auto; height: 50px;
      }

    }

  }

}