
// -- Variables Couleurs

$brun: #c9b899;
$brun-f: #937c72;

$gris: #616161;
$gris-f: #191919;

$link: $brun-f;
$linkHover: darken($brun-f,10%);


$c_main: #c9b899;
$c_sub: #937c72;
$c_white: #FFFFFF;
$c_dark: #000000;
$c_txt: #616161;


/* -- Variables divers -- */
$base-font-size: 16px;
$base-line-height: 20px;

$max-width: 1280px;
$max-width-little: 1000px;
$content-spacing: 40px;
$mobile-spacing: 20px;


// -- Media queries breakpoints

// Extra small screen / phone
$screen-xs:                  650px;
$screen-xs-min:              $screen-xs;

// Small screen / tablet
$screen-sm:                  850px;
$screen-sm-min:              $screen-sm;

// Medium screen / desktop
$screen-md:                  950px;
$screen-md-min:              $screen-md;

// Large screen / wide desktop
$screen-lg:                  1100px;
$screen-lg-min:              $screen-lg;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);


// --  Grid system

$grid-columns:              24;
$grid-gutter-width:         0px;

$grid-float-breakpoint:     $screen-sm-min;
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);


// -- Container sizes

// Small screen / tablet
$container-sm:              100%;

// Medium screen / desktop
$container-md:              100%;

// Large screen / wide desktop
$container-lg:              100%;



// -- Alert

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #a91a17 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;


$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $state-danger-bg !default;
$alert-danger-text:           $state-danger-text !default;
$alert-danger-border:         $state-danger-border !default;