
  /* Breadcrumb
  ----------------------------- */

  .breadcrumb {
    position: relative; z-index: 1; display: block;
    padding: 0px $content-spacing;
    font-family: ntt, sans-serif;
    @include font(14px, 15px); color: #fff;
    width: 100%; max-width: $max-width;
    box-sizing: border-box;

    .icon-littleArrow {
      &:before {
        font-size: 11px; color: #fff;
        margin-right: 10px;
      }
    }

    a, .breadcrumb_last {
      display: inline-block; vertical-align: baseline;
      text-decoration: none !important;
      border-bottom: 1px solid transparent;
      @include font(16px); color: #fff;
      padding: 0px 2px; font-weight: normal;

      transition-property: color, border;
      transition-duration: 0.3s;

      &:hover {
        border-bottom: 1px solid $brun;
      }
    }

    .breadcrumb_last {
      font-weight: bold;
      border-bottom: 0px none !important;
    }

    @media screen and ( max-width: $screen-xs ) {
      padding: 0px $mobile-spacing;
    }

  }
