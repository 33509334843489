.bloc-textimg, .bloc-textcarte {
  width: 100%; max-width: $max-width;

  @extend %clearfix;

  .img-wrapper {
    position: relative; z-index: 1;
    width: 50%; display: inline-block;
    vertical-align: top; float: left;
    box-sizing: border-box;

    figure {
      position: relative; z-index: 2;
      margin: 0px; width: 100%;
    }

    figcaption { display: none; }

    @media screen and ( max-width: $screen-md ) {
      width: 40%;
    }

    @media screen and ( max-width: $screen-xs ) {
      width: 100%;
    }
  }

  .decal-header {
    display: block; background-color: #ffffff;
    margin: -$content-spacing 0 0 -2*$content-spacing; padding: 15px 0 15px $content-spacing;

    @media screen and ( max-width: $screen-sm ) {
      padding: 15px 0 15px 25px;
    }

    @media screen and ( max-width: $screen-xs ) {
      margin: 0; padding: 0;
    }
  }

  .container {
    position: relative; z-index: 2;
    width: 50%; display: inline-block;
    vertical-align: top; float: right;
    padding: $content-spacing 0 0 $content-spacing;
    box-sizing: border-box;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      color: #000;
    }

    @media screen and ( max-width: $screen-md ) {
      width: 60%;
    }

    @media screen and ( max-width: $screen-sm ) {
      padding: $content-spacing 0 0 $mobile-spacing;
    }

    @media screen and ( max-width: $screen-xs ) {
      width: 100%;
      padding: $mobile-spacing 0;
    }
  }

  &.right-text {
    text-align: right;

    .decal-header {
      margin: -$content-spacing -2*$content-spacing 0 0 !important; padding: 15px $content-spacing 15px 0 !important;

      @media screen and ( max-width: $screen-sm ) {
        padding: 15px 25px 15px 0px !important;
      }

      @media screen and ( max-width: $screen-xs ) {
        margin: 0 !important; padding: 0 !important;
      }
    }

    ul, ol {
      text-align: left;
    }

    p {
      text-align: right;
    }

    .img-wrapper {
      float: right;
    }

    .container {
      float: left;
      padding: $content-spacing $content-spacing 0 0;

      @media screen and ( max-width: $screen-sm ) {
        padding: $content-spacing $mobile-spacing 0 0;
      }

      @media screen and ( max-width: $screen-xs ) {
        float: right;
        padding: $mobile-spacing 0;
      }

    }

  }

  &.back-grey {

    .container {
      background-color: $gris-f;
      padding: $content-spacing $content-spacing ($content-spacing - 10px) $content-spacing;

      @media screen and ( max-width: $screen-sm ) {
        padding: $content-spacing $mobile-spacing ($content-spacing - 10px) $mobile-spacing;
      }

      @media screen and ( max-width: $screen-xs ) {
        width: 100%;
        padding: $mobile-spacing $mobile-spacing ($mobile-spacing - 10px) $mobile-spacing;
      }

      .decal-header {
        padding-top: 25px !important;
        background-color: $gris-f;
        &:after {display: none !important; }

        @media screen and ( max-width: $screen-xs ) {
          padding-top: 0px !important;
        }

      }

      h1, h2, h3, h4, h5, h6,
      .h1, .h2, .h3, .h4, .h5, .h6,
      p, ul, ol, li, li:before, p {
        color: #fff !important;
      }

      .subtitle {
        color: $brun;
      }

      .link {
        color: $brun !important;
      }

      a { color: $brun;
        &:hover {
          color: $brun;
          border-bottom: 1px solid $link;
        }
      }
    }

  }

}

body.home .bloc-textimg {

  &.back-grey {
    @extend %clearfix;
    @extend %flex-full-row;

    @media screen and ( max-width: $screen-xs ) {

      display: block !important;
      width: 100%;

      .img-wrapper {
        display: block !important;
        width: 100%;
      }
    }

    .object-fit {
      position: absolute; top: 0; left: 0;
      @media screen and ( max-width: $screen-xs ) {
        position: relative !important;
      }
    }

    .container {
      background-color: $gris-f;
      padding: $content-spacing $content-spacing;

      @media screen and ( max-width: $screen-sm ) {
        padding: $content-spacing 25px;
      }

      @media screen and ( max-width: $screen-xs ) {
        width: 100%;
        padding: $mobile-spacing $mobile-spacing;
      }

      .decal-header {
        position: relative;
        background-color: $gris-f;
        margin: 0 0 0 -5*$content-spacing; padding: 25px 0 10px $content-spacing;

        @media screen and ( max-width: $screen-sm ) {
          padding: 20px 0 10px 25px !important;
          margin: 0 0 0 -185px;
        }

        @media screen and ( max-width: $screen-xs ) {
          margin: 0 !important; padding: 0 !important;
        }

        &:after {
          position: absolute; bottom: -10px; left: 0;
          display: block !important; content: "";
          background-color: $brun;
          width: 4*$content-spacing; height: 10px;

          @media screen and ( max-width: $screen-xs ) {
            display: none !important;
          }

        }

      }

      h1, h2, h3, h4, h5, h6,
      .h1, .h2, .h3, .h4, .h5, .h6,
      p, ul, ol, li, li:before, p {
        color: #fff !important;
      }

      .subtitle {
        color: $brun;
      }

      .link {
        color: $brun !important;
      }

      a { color: $brun;
        &:hover {
          color: $brun;
          border-bottom: 1px solid $link;
        }
      }

    }

    &.right-text {
      @extend %flex-full-row-inverse;

      .decal-header {
        margin: 0 -5*$content-spacing 0 0 !important; padding: 25px $content-spacing 10px 0 !important;

        @media screen and ( max-width: $screen-sm ) {
          padding: 20px 25px 10px 0 !important;
          margin: 0 -185px 0 0 !important;
        }

        @media screen and ( max-width: $screen-xs ) {
          margin: 0 !important; padding: 0 !important;
        }

        &:after {
          position: absolute; bottom: -10px; left: auto; right: 0;
          display: block; content: "";

          @media screen and ( max-width: $screen-xs ) {
            display: none !important;
          }

        }

      }

    }
  }

}


.bloc-textcarte {
  margin: 0 auto;

  border-right: $content-spacing solid transparent;
  border-left: 0px solid transparent;

  @media screen and ( max-width: $screen-xs ) {
    border-right: $mobile-spacing solid transparent;
    border-left: $mobile-spacing solid transparent;
  }

  figure {
    background-color: transparent;
  }

  h2 {
    text-transform: uppercase;
    @include font( 30px, 18px  );
    @media screen and ( max-width: $screen-md ) { @include font( 25px, 18px ); }
    @media screen and ( max-width: $screen-xs ) { @include font( 20px, 18px ); }
  }

  .img-wrapper {
    width: calc( 50% + 20px );

    @media screen and ( max-width: $screen-md ) {
      width: calc( 40% + 20px );
    }

    @media screen and ( max-width: $screen-xs ) {
      width: 100%;
    }

  }

  .container {
    padding: $content-spacing 0 $content-spacing $content-spacing;
    width: calc( 50% - 20px );

    @media screen and ( max-width: $screen-md ) {
      width: calc( 60% - 20px );
    }

    @media screen and ( max-width: $screen-xs ) {
      width: 100%;
      padding: $mobile-spacing 0;
    }

  }

  &.right-text {
    border-left: $content-spacing solid transparent;
    border-right: 0px solid transparent;

    @media screen and ( max-width: $screen-xs ) {
      border-right: $mobile-spacing solid transparent;
      border-left: $mobile-spacing solid transparent;
    }
  }

}

html.isie.ie9 body.home .bloc-textimg {

  &.back-grey {

    .img-wrapper {
      height: 100%;
    }

    .object-fit {
      position: relative !important; top: 0; left: 0;
      width: 100%; height: 100%;
    }

  }

}