$selectric-main-color:      #dddddd !default;                                              // Color used in border
$selectric-secondary-color: $brun-f !default;                                              // Color used in button
$selectric-text-color:      $brun-f !default;                                              // Color used in label
$selectric-bg-color:        #fff !default;                                           // Background color
$selectric-btn-bg-color:    #fff !default;                                           // Button background color
$selectric-height:          46px !default;                                              // Outer height
$selectric-spacing:         10px !default;                                              // Label left padding
$selectric-border-width:    1px !default;                                               // Outer border width
$selectric-border-radius:   0px !default;                                               // Border radius
$selectric-inner-height:    $selectric-height - ($selectric-border-width * 2) !default; // Inner height
$selectric-font-size:       16px !default;                                              // Font size

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: $selectric-border-width solid $selectric-main-color;
  border-radius: $selectric-border-radius;
  background: $selectric-bg-color;
  position: relative;
  overflow: hidden;

  font-family: ntt, sans-serif;

  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: darken($brun-f, 20%);
    margin: 0 $selectric-inner-height 0 $selectric-spacing;
    font-size: $selectric-font-size;
    line-height: $selectric-inner-height + 3;
    height: $selectric-inner-height;
    user-select: none;
  }

  &:before {
    position: absolute; top: 13px; right: 15px;
    display: block; content: '\e902';
    color: $brun-f; font-size: 15px;

    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .button {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: $selectric-inner-height;
    height: $selectric-inner-height;
    line-height: $selectric-inner-height;
    background-color: $selectric-btn-bg-color;
    color: $selectric-secondary-color;
    text-align: center;
    font: 0/0 a;
    *font: 20px/#{$selectric-inner-height} Lucida Sans Unicode, Arial Unicode MS, Arial;

    &:before {
      content: " "; display: block;
      position: absolute;
      top: 50%; left: 50%;
      width: 10px; height: 2px;
      background-color: $state-danger-text;
      margin: -1px 0 0 0;

      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);

    }

    &:after {
      content: " "; display: block;
      position: absolute;
      top: 50%; left: 50%;
      width: 10px; height: 2px;
      background-color: $state-danger-text;
      margin: -1px 0 0 -6px;

      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);

    }

  }
}

.selectric-focus .selectric {
  border-color: $brun;
}

.selectric-hover .selectric {
  border-color: $brun;

  .button {
    color: $selectric-secondary-color;

    &:after {
      border-top-color: $selectric-secondary-color;
    }
  }
}

.selectric-open {
  z-index: 9999;

  .selectric {
    border-color: $brun;

    &:before {
      transform: rotate(180deg);
    }
  }

  .selectric-items {
    display: block;
  }
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%;
  }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
  background: $selectric-bg-color;
  border: 1px solid $selectric-main-color;
  z-index: -1;
  box-shadow: 0 0 10px -6px;

  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 100%;
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: $selectric-font-size;
    line-height: 20px;
    min-height: 20px;
  }

  li {
    display: block;
    padding: 10px 10px 8px 10px;
    color: darken($brun-f, 20%);
    cursor: pointer;
    border-bottom: 1px solid #efefef;

    &.selected {
      background: $brun !important;
      color: #fff !important;
    }

    &.highlighted {
      background: #efefef;
      color: $brun-f;
    }

    &:hover {
      background: #efefef;
      color: $brun-f;
    }
  }

  .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #444;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}