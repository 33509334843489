
/* ------- @font-face - NeutraText ------- */

@font-face {
  font-family: 'nttd';
  src: url('../fonts/neutratext/NeutraTextTF-Demi.eot');
  src: url('../fonts/neutratext/NeutraTextTF-Demi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/neutratext/NeutraTextTF-Demi.woff') format('woff'),
  url('../fonts/neutratext/NeutraTextTF-Demi.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ntt';
  src: url('../fonts/neutratext/NeutraTextTF-Book.eot');
  src: url('../fonts/neutratext/NeutraTextTF-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/neutratext/NeutraTextTF-Book.woff') format('woff'),
  url('../fonts/neutratext/NeutraTextTF-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ntt';
  src: url('../fonts/neutratext/NeutraTextTF-Bold.eot');
  src: url('../fonts/neutratext/NeutraTextTF-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/neutratext/NeutraTextTF-Bold.woff') format('woff'),
  url('../fonts/neutratext/NeutraTextTF-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


/* ------- @font-face - roboto ------- */

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/roboto-regular-webfont.eot');
  src: url('../fonts/roboto/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-regular-webfont.woff') format('woff'),
  url('../fonts/roboto/roboto-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/roboto-bold-webfont.eot');
  src: url('../fonts/roboto/roboto-bold-webfont?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-bold-webfont.woff') format('woff'),
  url('../fonts/roboto/roboto-bold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


/* ------- @font-face - Icomoon ------- */

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?icnzte');
  src:  url('../fonts/icomoon/icomoon.eot?icnzte#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/icomoon.ttf?icnzte') format('truetype'),
  url('../fonts/icomoon/icomoon.woff?icnzte') format('woff'),
  url('../fonts/icomoon/icomoon.svg?icnzte#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.icon-ico-twitter:before {
  content: "\e918";
}
.icon-ico-facebook:before {
  content: "\e917";
}
.icon-parking:before {
  content: "\e916";
}
.icon-handicap:before {
  content: "\e913";
}
.icon-parking-old:before {
  content: "\e914";
}
.icon-theatre-abbaye:before {
  content: "\e915";
}
.icon-u:before {
  content: "\e912";
}
.icon-carre:before {
  content: "\e90d";
}
.icon-class:before {
  content: "\e90e";
}
.icon-cocktail:before {
  content: "\e90f";
}
.icon-ovale:before {
  content: "\e910";
}
.icon-repas:before {
  content: "\e911";
}
.icon-addCalendar:before {
  content: "\e900";
}
.icon-btnArrow:before {
  content: "\e901";
}
.icon-downArrow:before {
  content: "\e902";
}
.icon-download:before {
  content: "\e903";
}
.icon-home:before {
  content: "\e904";
}
.icon-largeur:before {
  content: "\e905";
}
.icon-littleArrow:before {
  content: "\e906";
}
.icon-longueur:before {
  content: "\e907";
}
.icon-mapPin:before {
  content: "\e908";
}
.icon-scroll:before {
  content: "\e909";
}
.icon-search:before {
  content: "\e90a";
}
.icon-superficie:before {
  content: "\e90b";
}
.icon-telephone:before {
  content: "\e90c";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-vimeo:before {
  content: "\eaa0";
}